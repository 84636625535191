import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { alertNotify } from '../modules/notification'

import { useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'

const MUTATION_RENEW_REGISTER_TOKEN = gql`
mutation renewRegisterLink($email: String!){
    renewRegisterLink(input: {email: $email}){
        ok
    }
}
`

function RenewConfirmLink() {
    const { i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);

    const history = useHistory();

    const [mutateRenewRegisterToken, { loading: mutateVerifyRegisterTokenLoading }] = useMutation(MUTATION_RENEW_REGISTER_TOKEN);

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (email.length === 0) {
            alertNotify("Invalid Email", "Email is required");
            return;
        }

        mutateRenewRegisterToken({
            variables: {
                email
            }
        }).then(() => {
            history.push("/auth/register_confirm/success");
        }).catch(({ graphQLErrors }) => {
            if (graphQLErrors) {
                setErrorMessages(graphQLErrors.map((error) => error.message));
            }
        });
    }

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-12 col-md-8">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="text-left">
                                {
                                    errorMessages.length > 0 ? <div className="alert alert-warning text-center">
                                        {
                                            errorMessages.map((v, i) => {
                                                return <h5 key={i} className="mb-0">{i18n.t(`registerConfirm.error.${v}`)}</h5>
                                            })
                                        }
                                    </div> : null
                                }
                                <h4 className="text-center">Fillin your email to renew a link</h4>
                                <form className="mt-4" onSubmit={onSubmitForm}>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <label>Email</label>
                                            <input type="email" className="form-control" name="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Submit
                                        {
                                            mutateVerifyRegisterTokenLoading ? <div className="spinner-border spinner-border-sm ml-2" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : null
                                        }
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RenewConfirmLink
