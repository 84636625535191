import React, { useState, useMemo, useEffect } from 'react'
import * as jwt_decode from 'jwt-decode'
import { QUERY_FETCH_CONFERENCE } from '../../graphql/queries'
import { useQuery } from '@apollo/react-hooks'

import { roleField, munativePic } from '../../constant'
import { renderRoleName, getDelegateMeta } from '../modules/util'

import CountryFlags from '../layout/countryFlags'

import useCommitteeRoles from './committee/hooks/useCommitteeRoles'

function SingleDelegate({ delegate, revealAllCode = false }) {
    const representative = (delegate && delegate.detail && delegate.detail.representative) || {};

    const { logoSrc, countryCode } = getDelegateMeta(representative);

    const [revealLoginCode, setRevealLoginCode] = useState(false);

    useEffect(() => {
        setRevealLoginCode(revealAllCode);
    }, [revealAllCode]);

    return (
        <div className="col-12 col-md-6 col-lg-4 my-2 px-2">
            <div className="card delegate-card">
                <div className="card-body text-center">
                    <div className="row">
                        <div className="col-12 col-lg-4 align-self-center">
                            <CountryFlags src={logoSrc}
                                className="rounded" width="64"
                                country={countryCode} fallback={munativePic} />
                        </div>
                        <div className="col-12 col-lg-8 align-self-center">
                            <h4 className="card-title">{renderRoleName(delegate)}</h4>
                            {
                                revealLoginCode ?
                                    <p><b>Login Code: </b><code>{delegate.detail.loginCode}</code></p> :
                                    <button className="btn btn-sm btn-warning" onClick={() => setRevealLoginCode(true)}>Reveal Login Code</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SingleAdmin({ admin, revealAllCode = false }) {
    const [revealLoginCode, setRevealLoginCode] = useState(false);

    useEffect(() => {
        setRevealLoginCode(revealAllCode);
    }, [revealAllCode]);

    return (
        <div className="col-12 col-md-6 col-lg-4 my-2 px-2">
            <div className="card delegate-card">
                <div className="card-body text-center">
                    <div className="row">
                        <div className="col-12 col-lg-4 align-self-center">
                            <CountryFlags src={null}
                                className="rounded" width="64"
                                country={null} fallback={munativePic} />
                        </div>
                        <div className="col-12 col-lg-8 align-self-center">
                            <h4 className="card-title">{renderRoleName(admin)}</h4>
                            {
                                revealLoginCode ?
                                    <p><b>Login Code: </b><code>{admin && admin.detail && admin.detail.loginCode}</code></p> :
                                    <button className="btn btn-sm btn-warning" onClick={() => setRevealLoginCode(true)}>Reveal Login Code</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ShowCommitteeDelegates({ CMID, revealAllCode }) {
    const { delegates, admins, loading } = useCommitteeRoles(CMID);

    return <div className="row">
        {
            !loading ?
                <>
                    {
                        delegates ?
                            delegates.map((d) => <SingleDelegate delegate={d} key={d._id} revealAllCode={revealAllCode} />)
                            : null
                    }
                    {
                        admins ?
                            admins.filter((v) => {
                                return v.detail && v.detail.loginCode;
                            }).map((a) => <SingleAdmin admin={a} key={a._id} revealAllCode={revealAllCode} />)
                            : null
                    }
                </>
                : <div className="text-center">
                    <div className="spinner-border text-primary">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        }
    </div>
}

function Director_Delegates() {
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CFID = me.roleTarget;

    const { data: cfData, loading: cfLoading } = useQuery(QUERY_FETCH_CONFERENCE, {
        variables: {
            CFID: this_CFID
        },
        fetchPolicy: "network-only"
    });

    const { conference } = cfData || {};
    const { committees } = conference || {};

    const [selectedCommittee, setSelectedCommittee] = useState("");

    const [revealAllCode, setRevealAllCode] = useState(false);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 col-lg">
                    <h1>Delegates</h1>
                </div>
                <div className="col-12 col-md-6 col-lg-4 align-self-center">
                    <div className="row">
                        <div className="col-auto align-self-center pr-1">
                            <button className="btn btn-sm btn-warning" onClick={() => setRevealAllCode(!revealAllCode)}>
                                {revealAllCode ? "Hide All" : "Reveal All"}
                            </button>
                        </div>
                        <div className="col align-self-center pl-1">
                            {
                                !cfLoading ?
                                    <select className="form-control" value={selectedCommittee} onChange={(e) => setSelectedCommittee(e.target.value)}>
                                        <option value="">Select one...</option>
                                        {
                                            committees ? committees.map((committee) => {
                                                return <option value={committee._id} key={committee._id}>{committee.name}</option>
                                            }) : null
                                        }
                                    </select>
                                    :
                                    <div className="text-center">
                                        <div className="spinner-border text-primary">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {
                        selectedCommittee ? <ShowCommitteeDelegates CMID={selectedCommittee} revealAllCode={revealAllCode} /> :
                            <div className="text-center">
                                <h1><span className="badge badge-primary">Select committee</span></h1>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Director_Delegates
