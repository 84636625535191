import gql from 'graphql-tag'
import {
  FRAGMENT_NOTE_SENDER_INFO, FRAGMENT_NOTE_RECEIVER_INFO, FRAGMENT_NOTE_REVIEWER_INFO,
  FRAGMENT_ROLES_BASIC_INFO, FRAGMENT_RESO_SUBMITTERS_INFO, FRAGMENT_NOTE_BASIC_INFO,
  FRAGMENT_NOTE_TIMESTAMP_INFO, FRAGMENT_RESO_BASIC_INFO, FRAGMENT_ROLE_BASIC_INFO,
  FRAGMENT_OWNER_BASIC_INFO, FRAGMENT_COMMITTEE_BASIC_INFO, FRAGMENT_ROLLCALLS_INFO,
  FRAGMENT_USER_BASIC_INFO, FRAGMENT_CONFERENCE_BASIC_INFO, FRAGMENT_CONFERENCE_TIMESTAMP_INFO,
  FRAGMENT_ORGANIZATION_BASIC_INFO, FRAGMENT_COMMITTEE_OPTIONS_INFO, FRAGMENT_AMENDMENT_SUBMITTER_INFO,
  FRAGMENT_AMENDMENT_BASIC_INFO, FRAGMENT_RESO_SHARE_INFO, FRAGMENT_ANNOUNCEMENT_INFO,
  FRAGMENT_VOTE_TIMESTAMP_INFO, FRAGMENT_VOTE_BASIC_INFO, FRAGMENT_NOTE_BLOCK_INFO, 
  FRAGMENT_VIEWER_UNREAD_NOTES_INFO
} from './fragments'

//Query
export const QUERY_FETCH_PENDING_NOTES = gql`
  query fetchPendingNotesQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      pnotes {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            _id
            ...NoteBasicInfo
            ...NoteSenderInfo
            ...NoteReceiverInfo
            ...NoteTimeStampInfo
          }
        }
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
`

export const QUERY_FETCH_ROLE_UNREAD_NOTES = gql`
  query fetchUnreadNotesQuery {
    viewer {
      ...ViewerUnreadNotesInfo
    }
  }
  ${FRAGMENT_VIEWER_UNREAD_NOTES_INFO}
`

export const QUERY_FETCH_ROLE_NOTES = gql`
  query fetchNotesQuery($RID: ID!) {
    role(RID: $RID) {
      props {
        ...on CommitteeRoleProps {
          notes {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                _id
                ...NoteBasicInfo
                ...NoteTimeStampInfo
                ...NoteSenderInfo
                ...NoteReceiverInfo
                ...NoteReviewerInfo
                ...NoteBlockInfo
              }
            } 
          }
        }
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
  ${FRAGMENT_NOTE_BLOCK_INFO}
`

export const QUERY_FETCH_ROLE_NOTES_READ = gql`
  query fetchNotesReadQuery($RID: ID!) {
    role(RID: $RID) {
      props {
        ...on CommitteeRoleProps {
          notes(read: false) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                _id
                readTime
                ...NoteSenderInfo
                ...NoteReceiverInfo
              }
            } 
          }
        }
      }
    }
  }
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
`

export const QUERY_FETCH_ROLE_NOTES_CONVERSATION = gql`
  query fetchNotesConversationQuery($RID: ID!, $peerId: ID!, $before: String) {
    notes(RID: $RID, peerId: $peerId, last: 3, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _id
          ...NoteBasicInfo
          ...NoteTimeStampInfo
          ...NoteSenderInfo
          ...NoteReceiverInfo
          ...NoteReviewerInfo
          ...NoteBlockInfo
        }
      } 
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
  ${FRAGMENT_NOTE_BLOCK_INFO}
`

export const QUERY_FETCH_COMMITTEE_RESOLUTIONS = gql`
  query fetchCommitteeResolutionsQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      resolutions {
        _id
        ...ResoBasicInfo
        ...ResoShareInfo
        ...ResoSubmittersInfo
      }
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO}
  ${FRAGMENT_RESO_SHARE_INFO}
  ${FRAGMENT_RESO_SUBMITTERS_INFO}
`

export const QUERY_FETCH_ROLE = gql`
  query fetchRoleQuery($RID: ID!) {
    role(RID: $RID) {
      _id
      ...RoleBasicInfo
      roleTarget {
        ...on Committee {
          _id
          name
        }
        ...on Conference {
          _id
          name
        }
        ...on Organization {
          _id
          name 
        }
      }
      ...OwnerBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
  ${FRAGMENT_OWNER_BASIC_INFO}
`

export const QUERY_FETCH_ROLES = gql`
  query fetchRolesQuery($USID: ID!) {
    user(USID: $USID) {  
      _id
      ...RolesBasicInfo
    }
  }
  ${FRAGMENT_ROLES_BASIC_INFO}
`

export const QUERY_FETCH_CONFERENCE = gql`
  query fetchConferenceQuery($CFID: ID!) {
    conference(CFID: $CFID) {
      _id
      ...ConferenceBasicInfo
      ...ConferenceTimeStampInfo
      committees {
        _id
        ...CommitteeBasicInfo
      }
    }
  }
  ${FRAGMENT_CONFERENCE_BASIC_INFO}
  ${FRAGMENT_CONFERENCE_TIMESTAMP_INFO}
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE = gql`
  query fetchCommitteeQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      ...CommitteeBasicInfo
      ...CommitteeOptionsInfo
      conference {
        _id
        ...ConferenceBasicInfo
      }
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
  ${FRAGMENT_COMMITTEE_OPTIONS_INFO}
  ${FRAGMENT_CONFERENCE_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE_ROLLCALLS = gql`
  query fetchCommitteeRollCallsQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      rollCalls {
        _id
        ...RollCallsInfo
      }
    }
  }
  ${FRAGMENT_ROLLCALLS_INFO}
`

export const QUERY_FETCH_COMMITTEE_ROLES = gql`
  query fetchCommitteeRolesQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      roles {
        _id
        ...RoleBasicInfo
      }
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE_AMENDMENTS = gql`
  query fetchCommitteeAmendmentssQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      resolutions {
        _id
        ...ResoBasicInfo
        amendments {
          _id
          ...AmendmentBasicInfo
          ...AmendmentSubmitterInfo
          target {
            ... on Resolution {
              _id
              ...ResoBasicInfo
            }
            ... on Amendment {
              _id
              ...AmendmentBasicInfo
              ...AmendmentSubmitterInfo
            }
            __typename
          }
          secondaryAmendments {
            _id
          }
        }
      }
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
`

export const QUERY_FETCH_CONFERENCE_ROLES = gql`
  query fetchConferenceRolesQuery($CFID: ID!) {
    conference(CFID: $CFID) {
      _id
      committees {
        _id
        ...CommitteeBasicInfo
        roles {
          _id
          ...RoleBasicInfo
        }

      }
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
  ${FRAGMENT_ROLE_BASIC_INFO}
`

//後端補齊
// export const QUERY_FETCH_SYSTEM_ROLES = gql`
//   query fetchSystemRolesQuery {
//     roles {
//         _id
//         detail
//         status
//         role
//         roleTarget
//         roleTargetType
//         owner {
//           lastName
//           firstName
//         }
//     }
//   }
// `

export const QUERY_FETCH_SYSTEM_USERS = gql`
  query fetchSystemUsersQuery {
    users {
      _id
      ...UserBasicInfo
      pendingRegister
      organization {
        _id
        ...OrganizationBasicInfo
      }
    }
  }
  ${FRAGMENT_USER_BASIC_INFO}
  ${FRAGMENT_ORGANIZATION_BASIC_INFO}
`

//後端再加入
export const QUERY_FETCH_SYSTEM_ORGANIZATIONS = gql`
  query fetchSystemOrganizationsQuery{
    organizations {
      _id
      ...OrganizationBasicInfo
      conferences {
        _id
        ...ConferenceBasicInfo
      }
    }
  }
  ${FRAGMENT_ORGANIZATION_BASIC_INFO}
  ${FRAGMENT_CONFERENCE_BASIC_INFO}
`


export const QUERY_FETCH_RESOLUTION = gql`
  query fetchResolutionQuery($RESOID: ID!) {
    resolution(RESOID: $RESOID) {
      _id
      ...ResoBasicInfo
      ...ResoShareInfo
      ...ResoSubmittersInfo
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO}
  ${FRAGMENT_RESO_SHARE_INFO}
  ${FRAGMENT_RESO_SUBMITTERS_INFO}
`

export const QUERY_FETCH_RESOLUTION_AMENDMENTS = gql`
  query fetchResolutionAmendmentQuery($RESOID: ID!) {
    resolution(RESOID: $RESOID) {
      _id
      ...ResoBasicInfo
      ...ResoSubmittersInfo
      amendments {
        _id
        ...AmendmentBasicInfo
        ...AmendmentSubmitterInfo
        target {
          ... on Resolution {
            _id
            ...ResoBasicInfo
          }
          ... on Amendment {
            _id
            ...AmendmentBasicInfo
            ...AmendmentSubmitterInfo
          }
          __typename
        }
        secondaryAmendments {
          _id
        }
      }
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO}
  ${FRAGMENT_RESO_SUBMITTERS_INFO}
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
`

export const QUERY_FETCH_AMENDMENT = gql`
  query fetchAmendmentQuery($AID: ID!) {
    amendment(AID: $AID) {
      _id
      ...AmendmentBasicInfo
      ...AmendmentSubmitterInfo
      target{
        ...on Resolution {
          _id
          ...ResoBasicInfo
        }
        ...on Amendment {
          _id
          ...AmendmentBasicInfo
        }
      }
    }
  }
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
  ${FRAGMENT_RESO_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
`

export const QUERY_FETCH_COMMITTEE_ANNOUNCEMENTS = gql`
  query fetchCommitteeAnnouncementsQuery($CMID: ID!) {
    committee(CMID: $CMID) {
      _id
      announcements {
        _id
        ...AnnouncementInfo
      }
    }
  }
  ${FRAGMENT_ANNOUNCEMENT_INFO}
`

export const QUERY_FETCH_VOTE = gql`
  query fetchVoteQuery($VID: ID!) {
    vote(VID: $VID) {
      _id
      ...VoteTimeStampInfo
      ...VoteBasicInfo
    }
  }
  ${FRAGMENT_VOTE_TIMESTAMP_INFO}
  ${FRAGMENT_VOTE_BASIC_INFO}
`

export const QUERY_SYSTEM_PING = gql`
  query systemPingQuery{
    ping
  }
`