import React, { useDebugValue } from 'react'

import { useQuery } from 'react-apollo'
import { QUERY_FETCH_SYSTEM_ORGANIZATIONS } from '../../../graphql/queries'
import { itemStatusCode } from '../../../constant'
import itemStatusBadge from '../../layout/itemStatusBadge'

const useSystemOrg = () => {
    const { data, loading } = useQuery(QUERY_FETCH_SYSTEM_ORGANIZATIONS, {
        fetchPolicy: "network-only",
        pollInterval: 1000
    });

    const { organizations } = data || {};
    useDebugValue(organizations);

    return { organizations: (organizations ? organizations : []), loading };
}

function ManageOrg() {

    const renderOrg = ({ _id, name, status, conferences }, index) => {

        conferences = conferences.filter((v) => v.status === itemStatusCode.Active);

        return (
            <tr key={index}>
                <th scope="row">{name}</th>
                <td>{itemStatusBadge(status)}</td>
                <td>{conferences.length}</td>
            </tr>
        )
    }

    const { organizations, loading } = useSystemOrg();

    return (
        <div className="container-fluid">
            <h4>Manage Organization</h4>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Active Conferences</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !loading ? organizations.map((org, index) => {
                            return renderOrg(org, index);
                        }) :
                            <tr>
                                <td colSpan="4" className="text-center">
                                    <div className="spinner-border text-primary">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ManageOrg
