import React, { useState, useEffect, useMemo } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

import { useMutation } from '@apollo/react-hooks'
import { MUTATION_UPDATE_COMMITTEE_OPTIONS } from '../../../../graphql/mutations'

import { resolutionStatusCode, amendmentStatusCode, rightDisplayMode, ruleOfProcedure } from '../../../../constant'

import useResolutionsAndAmendments from '../hooks/useResolutionsAndAmendments'
import useCommitteeOptions from '../hooks/useCommitteeOptions'
import { useTranslation } from 'react-i18next'

import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from 'mdbreact'

import './loading.css'
import useCommitteeAmendments from '../hooks/useCommitteeAmendments'
import ResolutionAmendmentSelector from './ResolutionAmendmentSelector'
import ResolutionAmendmentPrivateIndicator from './ResolutionAmendmentPrivateIndicator'

const customDisplayTemplates = [
    "opening",
    "lobbying",
    "gsl",
    "rollcall",
]

const customMotionTemplates = [
    "mod",
    "unMod",
    "consultation",
    // "silentMod",
]

const FontSizeSelector = ({ handledisplayFontSizeOnClick, displayFontSize }) => {
    const { t } = useTranslation();

    return (
        <div>
            <label>{t('committee.chairDash.displaySetting.fontsize')}</label>
            <div className="text-center">
                <div className="btn-group">
                    <button onClick={handledisplayFontSizeOnClick} value="XL"
                        className={`btn btn-sm btn-${displayFontSize === "XL" ? "success" : "blue-grey"}`}>{t('committee.chairDash.displaySetting.superLarge')}</button>
                    <button onClick={handledisplayFontSizeOnClick} value="L"
                        className={`btn btn-sm btn-${displayFontSize === "L" ? "success" : "blue-grey"}`}>{t('committee.chairDash.displaySetting.large')}</button>
                    <button onClick={handledisplayFontSizeOnClick} value="M"
                        className={`btn btn-sm btn-${displayFontSize === "M" ? "success" : "blue-grey"}`}>{t('committee.chairDash.displaySetting.medium')}</button>
                    <button onClick={handledisplayFontSizeOnClick} value="S"
                        className={`btn btn-sm btn-${displayFontSize === "S" ? "success" : "blue-grey"}`}>{t('committee.chairDash.displaySetting.small')}</button>
                </div>
            </div>
        </div>
    )
}

export default function DisplaySetting({ committeeOptions, CMID }) {

    const { t } = useTranslation();

    const { committee } = useCommitteeOptions(CMID);

    const cmType = useMemo(() => (committee || {}).type, [committee]);

    const [centerText, setCenterText] = useState("");

    const { rightDisplaySelected, currentResolutionId, displaySettings } = committeeOptions || {};

    const { fontSize: displayFontSize, rightDisplay: rightDisplaySetting, customCenterText } = displaySettings || {};

    useEffect(() => {
        setCenterText(customCenterText);
    }, [customCenterText]);

    const [updateCommittee, { loading }] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID
        }
    })

    const handleDisplaySettingSubmit = (e) => {
        e.preventDefault();

        updateCommittee({
            variables: {
                options: {
                    displaySettings: {
                        customCenterText: centerText
                    }
                }
            }
        });
    }

    const handleDisplayFontSizeOnClick = (e) => {
        updateCommittee({
            variables: {
                options: {
                    displaySettings: {
                        fontSize: e.target.value
                    }
                }
            }
        });
    }

    const { cmAmendments } = useCommitteeAmendments(CMID);
    let { amendments: resoAmendments, resolutions, loading: useResoAndAmendmentsLoading } = useResolutionsAndAmendments(CMID, currentResolutionId);
    const amendments = useDeepCompareMemo(() =>
        JSON.parse(JSON.stringify(cmType === ruleOfProcedure.UNAUSA ? cmAmendments : resoAmendments)), [cmType, cmAmendments, resoAmendments]);

    const pendingResolutions = useDeepCompareMemo(() => resolutions.filter((v) => {
        return v.resolutionStatus === resolutionStatusCode.Pending;
    }), [resolutions]);

    const pendingAmendments = useDeepCompareMemo(() => amendments.filter((v) => {
        return v.amendmentStatus === amendmentStatusCode.Pending;
    }).sort((a, b) => {
        return a.clause > b.clause ? 1 : -1
    }), [amendments]);

    return (
        <div className={"card" + (loading ? " loading" : "")}>
            <div className="card-body">
                {
                    rightDisplaySelected === rightDisplayMode.ResolutionOrAmendments ?
                        <>
                            {
                                !useResoAndAmendmentsLoading ?
                                    <>
                                        <div className="form-group">
                                            <label>{t('committee.chairDash.displaySetting.selectResolutionAndAmendments')}</label>
                                            <p className="text-muted">
                                                {t('committee.chairDash.displaySetting.onlyShowPending')} <br />
                                                {t('committee.chairDash.displaySetting.SAIsSecondDegreeAmendment')} 
                                            </p>

                                            <ResolutionAmendmentSelector
                                                className="form-control"
                                                resolutions={pendingResolutions}
                                                amendments={pendingAmendments}
                                                cmType={cmType}
                                                value={JSON.stringify(rightDisplaySetting)}
                                                onChange={(e) => {
                                                    updateCommittee({
                                                        variables: {
                                                            options: {
                                                                displaySettings: {
                                                                    rightDisplay: JSON.parse(e.target.value)
                                                                }
                                                            }
                                                        }
                                                    })
                                                }}
                                                mapValue={(type, v) => JSON.stringify({ type, targetId: v ? v._id : undefined })}
                                            />

                                            <ResolutionAmendmentPrivateIndicator
                                                pendingResolutions={pendingResolutions}
                                                pendingAmendments={pendingAmendments}
                                                rightDisplaySetting={rightDisplaySetting}
                                            />
                                        </div>

                                        <FontSizeSelector displayFontSize={displayFontSize} handledisplayFontSizeOnClick={handleDisplayFontSizeOnClick} />
                                    </> :
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only"> {t('loading')}</span>
                                        </div>
                                    </div>
                            }
                        </> :
                        <>
                            <form onSubmit={handleDisplaySettingSubmit}>
                                <div className="form-group">
                                    <label htmlFor="centerText"> {t('committee.chairDash.displaySetting.centerText')} </label>
                                    <textarea id="centerText" className={"form-control" + (centerText !== customCenterText ? " border-danger" : "")} rows="7" value={centerText} onChange={(e) => setCenterText(e.target.value)}></textarea>
                                </div>
                                <button className="btn btn-primary btn-sm" type="submit"> {t('committee.chairDash.displaySetting.save')} </button>
                                <MDBDropdown className="d-inline">
                                    <MDBDropdownToggle color="default" size="sm">
                                        {t('committee.chairDash.displaySetting.template')} <MDBIcon icon="caret-down" />
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {
                                            customDisplayTemplates.map((v) => {
                                                return <MDBDropdownItem key={v} onClick={() => setCenterText(t(`committee.chairDash.customTextTemplates.centerText.${v}.content`))}>{t(`committee.chairDash.customTextTemplates.centerText.${v}.title`)}</MDBDropdownItem>
                                            })
                                        }
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                                <MDBDropdown className="d-inline">
                                    <MDBDropdownToggle color="info" size="sm">
                                        {t('committee.chairDash.displaySetting.motionTemplate')} <MDBIcon icon="caret-down" />
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        {
                                            customMotionTemplates.map((v) => {
                                                return <MDBDropdownItem key={v} onClick={() => setCenterText(centerText + "\n--------\n" + t(`committee.chairDash.customTextTemplates.centerText.${v}.content`))}>{t(`committee.chairDash.customTextTemplates.centerText.${v}.title`)}</MDBDropdownItem>
                                            })
                                        }
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                                <button className="btn btn-warning btn-sm" type="submit" onClick={() => setCenterText("")}>{t('committee.chairDash.displaySetting.clear')}</button>
                            </form>

                            <FontSizeSelector displayFontSize={displayFontSize} handledisplayFontSizeOnClick={handleDisplayFontSizeOnClick} />
                        </>
                }
            </div>
        </div >
    )
}
