import React, { useState, useMemo, Fragment } from 'react'
import * as jwt_decode from 'jwt-decode'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import FlipMove from 'react-flip-move'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@apollo/react-hooks'
import { MUTATION_POST_ANNOUNCEMENT, MUTATION_DELETE_ANNOUNCEMENT } from '../../../graphql/mutations'

import { roleField, roleCode } from '../../../constant'
import Linkify from '../../modules/Linkify'
import { successNotify, errorNotify, alertNotify } from '../../modules/notification'

import useAnnouncement from './hooks/useAnnouncement'

function Announcement() {
    const { t } = useTranslation();

    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;
    const showAdmin = me.role === roleCode.Chair || me.role === roleCode.Admin;

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    const [content, setContent] = useState("");

    const { announcements, loading } = useAnnouncement(this_CMID);
    const [mutatePostAnnouncement, { loading: postAnnouncementMutationLoading }] = useMutation(MUTATION_POST_ANNOUNCEMENT, {
        variables: {
            target: "committee",
            targetID: this_CMID,
        }
    });

    const handlePostNewAnnouncement = () => {
        if (content.trim().length === 0) {
            alertNotify(t('notifications.announcement.missingContent'), t('notifications.announcement.pleaseEnterContent'));
            return;
        }

        mutatePostAnnouncement({
            variables: {
                content
            }
        }).then(() => {
            successNotify(t('notifications.announcement.posted'), "", false);
            setShowModal(false);
            setContent("");
        }).catch((e) => {
            errorNotify(t('error'), t('notifications.announcement.cannotPost'), false);
            throw e;
        })
    }

    const [
        mutateDeleteAnnouncement,
        { loading: mutateDeleteLoading },
    ] = useMutation(MUTATION_DELETE_ANNOUNCEMENT);

    const handleRemoveAnnouncement = (ANID) => {
        mutateDeleteAnnouncement({
            variables: {
                ANID
            }
        }).then(() => {
            successNotify(t('notifications.announcement.removed'), "", false);
        }).catch((e) => {
            errorNotify(t('error'), t('notifications.announcement.cannotRemove'), false);
            throw e;
        });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">{t('committee.announcement.announcement')}</h1>
                            {showAdmin ?
                                <button className="btn btn-primary btn-sm" onClick={toggleModal}>{t('committee.announcement.newPost')}</button> : null
                            }
                            <hr />
                            {
                                !loading ? (
                                    announcements && announcements.length > 0 ?
                                        <>
                                            <div className="row mb-2 text-center d-none d-md-flex">
                                                <div className="col-3">
                                                    <span className="badge badge-info">{t('committee.announcement.postTime')}</span>
                                                </div>
                                                <div className={showAdmin ? "col-7" : "col-9"}>
                                                    <span className="badge badge-info">{t('committee.announcement.content')}</span>
                                                </div>
                                            </div>
                                            <FlipMove>
                                                {
                                                    announcements.map((v) => {
                                                        return (
                                                            <div key={v._id} style={{margin:"10px 0"}}>
                                                                <div className="row text-left text-md-center">
                                                                    <div className="col-10 order-2 col-md-3 order-md-1 align-self-center">
                                                                        {new Date(v.postTime ? v.postTime : "").toLocaleString()}
                                                                    </div>
                                                                    <div  className={"col-12 order-1 order-md-2 align-self-center " + (showAdmin ? "col-md-7" : "col-md-9")}  >
                                                                        <p className="text-wrap" style={{marginBottom:0}}><strong>
                                                                            {v.content ?
                                                                                <>
                                                                                    {(v.content || "").split("\n").map((line, i) => {
                                                                                        return <Fragment key={i}>
                                                                                            <Linkify>{line}</Linkify>
                                                                                            <br />
                                                                                        </Fragment>
                                                                                    })}
                                                                                </> : "n/a"}
                                                                        </strong></p>
                                                                    </div>
                                                                    {showAdmin && !mutateDeleteLoading ? <div className="col-2 order-3 col-md-2 align-self-center" onClick={() => handleRemoveAnnouncement(v._id)}>
                                                                        <span id="arrorBtn" className="p-1">
                                                                            <i className="material-icons">delete</i>
                                                                        </span>
                                                                    </div> : null}
                                                                </div>
                                                                <hr className="d-block d-md-none" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </FlipMove>
                                        </> :
                                        <div className="text-center">
                                            <h3>{t('committee.announcement.noAnnouncement')}</h3>
                                        </div>
                                ) :
                                    <div className="text-center">
                                        <div className="spinner-border text-dark">
                                            <span className="sr-only">{t('loading')}</span>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <MDBModal isOpen={showModal} toggle={toggleModal}>
                <MDBModalHeader toggle={toggleModal}>
                    {t('committee.announcement.addAnnouncement')}
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="form-group">
                        <label htmlFor="content"><strong>{t('committee.announcement.content')}</strong></label>
                        <textarea id="content" className="form-control" rows="5" onChange={(e) => setContent(e.target.value)} value={content}></textarea>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button className="btn btn-primary btn-sm" disabled={postAnnouncementMutationLoading} onClick={handlePostNewAnnouncement}>{t('committee.announcement.post')}</button>
                </MDBModalFooter>
            </MDBModal>
        </div>
    )
}

export default Announcement
