import React from 'react'
import { useTranslation } from 'react-i18next'
import { renderAmendmentDisplayText } from '../../../modules/util'
import { ruleOfProcedure } from '../../../../constant'

function ResolutionAmendmentSelector({ resolutions, amendments, value, onChange = () => { }, mapValue = (type, v) => JSON.stringify({ type, v }), cmType, children, className }) {
    const i18n = useTranslation();
    const { t } = i18n;
    return (
        <select value={value} className={className} onChange={onChange}>
            <option value={mapValue("none")}> {t('committee.chairDash.displaySetting.selectOne')} </option>
            
                {
                    resolutions.length > 0 ?
                        resolutions.map((v, i) => {
                            return (
                                <optgroup label={v.number} key={v._id}>
                                    <option key={i} value={mapValue("resolution", v)}>{v.number ? `${v.number}` : v.topic}</option>
                                    {
                                        amendments
                                        .filter(amendment => {
                                            return !(amendment.target && amendment.target.__typename === "Amendment"); 
                                        })
                                        .filter(amendment => {
                                            return amendment?.target._id === v._id
                                        })
                                        .map(v => {
                                            const secondaryAmendments = amendments.filter((amendment) => {
                                                return amendment.target && amendment.target.__typename === "Amendment" && amendment.target._id === v._id;
                                            });

                                            return (
                                                <React.Fragment key={v._id}>
                                                    <option value={mapValue("amendment", v)}>
                                                        {renderAmendmentDisplayText(v, { language: i18n.language, showDRName: cmType !== ruleOfProcedure.THIMUN })}
                                                    </option>
                                                    {
                                                         secondaryAmendments.map((sv) => {
                                                            return (
                                                                <option key={sv._id} value={mapValue("amendment", sv)}>
                                                                    {renderAmendmentDisplayText(sv, { language: i18n.language, showDRName: cmType !== ruleOfProcedure.THIMUN })}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </optgroup>
                            )
                            
                        }) :
                        <option disabled={true}> {t('committee.chairDash.vote.none')} </option>
                }
            {children}
        </select>
    )
}

export default ResolutionAmendmentSelector
