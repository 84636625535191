import gql from 'graphql-tag'

import {
  FRAGMENT_AMENDMENT_BASIC_INFO, FRAGMENT_AMENDMENT_SUBMITTER_INFO,
  FRAGMENT_RESO_BASIC_INFO, FRAGMENT_ROLE_BASIC_INFO, FRAGMENT_NOTE_BASIC_INFO,
  FRAGMENT_NOTE_BLOCK_INFO, FRAGMENT_NOTE_RECEIVER_INFO, FRAGMENT_NOTE_REVIEWER_INFO,
  FRAGMENT_NOTE_SENDER_INFO, FRAGMENT_NOTE_TIMESTAMP_INFO, FRAGMENT_VOTE_BASIC_INFO,
  FRAGMENT_VOTE_TIMESTAMP_INFO, FRAGMENT_RESO_SUBMITTERS_INFO, FRAGMENT_RESO_SHARE_INFO,
  FRAGMENT_COMMITTEE_BASIC_INFO, FRAGMENT_ROLLCALLS_INFO, FRAGMENT_COMMITTEE_OPTIONS_INFO,
  FRAGMENT_ANNOUNCEMENT_INFO
} from './fragments'

export const SUBSCRIPTION_RECEIVED_PENDING_NOTE = gql`
  subscription receivedPendingNoteSubscription($CMID: ID!) {
    pendingNoteReceived(CMID: $CMID) {
      cursor
      node {
        _id
        ...NoteBasicInfo
        ...NoteTimeStampInfo
        ...NoteSenderInfo
        ...NoteReceiverInfo
        ...NoteReviewerInfo
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
`

export const SUBSCRIPTION_RESOLVED_PENDING_NOTE = gql`
  subscription resolvedPendingNoteSubscription($CMID: ID!) {
    pendingNoteUpdated(CMID: $CMID){
      cursor
      node {
        _id
        ...NoteBasicInfo
        ...NoteTimeStampInfo
        ...NoteSenderInfo
        ...NoteReceiverInfo
        ...NoteReviewerInfo
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
`

export const SUBSCRIPTION_RECEIVED_NOTE = gql`
  subscription receivedNoteSubscription($RID: ID!) {
    noteReceived(RID: $RID){
      cursor
      node {
        _id
        ...NoteBasicInfo
        ...NoteTimeStampInfo
        ...NoteSenderInfo
        ...NoteReceiverInfo
        ...NoteReviewerInfo
        ...NoteBlockInfo
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
  ${FRAGMENT_NOTE_BLOCK_INFO}
`

export const SUBSCRIPTION_UPDATE_NOTE = gql`
  subscription updateNoteSubscription($RID: ID!, $peerId: ID) {
    noteUpdated(RID: $RID, peerId: $peerId){
      cursor
      node {
        _id
        ...NoteBasicInfo
        ...NoteTimeStampInfo
        ...NoteSenderInfo
        ...NoteReceiverInfo
        ...NoteReviewerInfo
        ...NoteBlockInfo
      }
    }
  }
  ${FRAGMENT_NOTE_BASIC_INFO}
  ${FRAGMENT_NOTE_TIMESTAMP_INFO}
  ${FRAGMENT_NOTE_SENDER_INFO}
  ${FRAGMENT_NOTE_RECEIVER_INFO}
  ${FRAGMENT_NOTE_REVIEWER_INFO}
  ${FRAGMENT_NOTE_BLOCK_INFO}
`

export const SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS = gql`
  subscription committeeOptionsUpdatedSubscription($CMID: ID!) {
    committeeUpdated(CMID: $CMID){
      _id
      ...CommitteeBasicInfo
      ...CommitteeOptionsInfo
    }
  }
  ${FRAGMENT_COMMITTEE_BASIC_INFO}
  ${FRAGMENT_COMMITTEE_OPTIONS_INFO}
`

export const SUBSCRIPTION_ROLLCALL_DELETE = gql`
  subscription rollCallDeletedSubscription($CMID: ID!){
    rollCallDeleted(CMID: $CMID) {
      _id
    }
  }
`

export const SUBSCRIPTION_ROLLCALL_UPDATE = gql`
  subscription rollCallUpdatedSubscription($CMID: ID!){
    rollCallUpdated(CMID: $CMID) {
      _id
      ...RollCallsInfo
    }
  }
  ${FRAGMENT_ROLLCALLS_INFO}
`

export const SUBSCRIPTION_COMMITTEE_ROLES_UPDATE = gql`
  subscription updateCommitteeRolesSubscription($CMID: ID!){
    committeeRolesUpdated(CMID: $CMID){
      _id
      ...RoleBasicInfo
    }
  }
  ${FRAGMENT_ROLE_BASIC_INFO}
`

export const SUBSCRIPTION_SYSTEM_NOTIFY = gql`
  subscription systemNotifySubscription{
    systemNotify {
      content
    }
  }
`

export const SUBSCRIPTION_ANNOUNCEMENT_POSTED = gql`
  subscription announcementPostedSubscription($target: String!, $targetID: ID!){
    announcementPosted(target: $target, targetID: $targetID){
      _id
      ...AnnouncementInfo
    }
  }
  ${FRAGMENT_ANNOUNCEMENT_INFO}
`

export const SUBSCRIPTION_ANNOUNCEMENT_DELETED = gql`
  subscription announcementDeletedSubscription($target: String!, $targetID: ID!){
    announcementDeleted(target: $target, targetID: $targetID){
      _id
    }
  }
`

export const SUBSCRIPTION_CREATE_RESOLUTION = gql`
  subscription createResolutionSubscription($CMID: ID!){
    resolutionCreated(CMID: $CMID){
      _id
      ...ResoBasicInfo
      ...ResoShareInfo
      ...ResoSubmittersInfo
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO},
  ${FRAGMENT_RESO_SHARE_INFO},
  ${FRAGMENT_RESO_SUBMITTERS_INFO}
`

export const SUBSCRIPTION_UPDATE_RESOLUTION = gql`
  subscription updateResolutionSubscription($CMID: ID!){
    resolutionUpdated(CMID: $CMID){
      _id
      ...ResoBasicInfo
      ...ResoShareInfo
      ...ResoSubmittersInfo
    }
  }
  ${FRAGMENT_RESO_BASIC_INFO},
  ${FRAGMENT_RESO_SHARE_INFO},
  ${FRAGMENT_RESO_SUBMITTERS_INFO}
`

export const SUBSCRIPTION_DELETE_RESOLUTION = gql`
  subscription deleteResolutionSubscription($CMID: ID!){
    resolutionDeleted(CMID: $CMID){
      _id
    }
  }
`

export const SUBSCRIPTION_CREATE_AMENDMENT = gql`
  subscription createAmendmentSubscription($RESOID: ID!){
    amendmentCreated(RESOID: $RESOID){
      _id
      ...AmendmentBasicInfo
      ...AmendmentSubmitterInfo
      target{
        ...on Resolution {
          _id
          ...ResoBasicInfo
        }
        ...on Amendment {
          _id
          ...AmendmentBasicInfo
        }
      }
      secondaryAmendments {
        _id
      }
    }
  }
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
  ${FRAGMENT_RESO_BASIC_INFO}
`

export const SUBSCRIPTION_UPDATE_AMENDMENT = gql`
  subscription updateAmendmentSubscription($RESOID: ID!){
    amendmentUpdated(RESOID: $RESOID){
      _id
      ...AmendmentBasicInfo
      ...AmendmentSubmitterInfo
      target{
        ...on Resolution {
          _id
          ...ResoBasicInfo
        }
        ...on Amendment {
          _id
          ...AmendmentBasicInfo
          ...AmendmentSubmitterInfo
        }
        __typename
      }
      secondaryAmendments {
        _id
      }
    }
  }
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
  ${FRAGMENT_RESO_BASIC_INFO}
`

export const SUBSCRIPTION_CM_CREATE_AMENDMENT = gql`
  subscription committeeCreateAmendmentSubscription($CMID: ID!){
    committeeAmendmentCreated(CMID: $CMID){
      _id
      ...AmendmentBasicInfo
      ...AmendmentSubmitterInfo
      target{
        ...on Resolution {
          _id
          ...ResoBasicInfo
        }
        ...on Amendment {
          _id
          ...AmendmentBasicInfo
        }
      }
      secondaryAmendments {
        _id
      }
    }
  }
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
  ${FRAGMENT_RESO_BASIC_INFO}
`

export const SUBSCRIPTION_CM_UPDATE_AMENDMENT = gql`
  subscription committeeUpdateAmendmentSubscription($CMID: ID!){
    committeeAmendmentUpdated(CMID: $CMID){
      _id
      ...AmendmentBasicInfo
      ...AmendmentSubmitterInfo
      target{
        ...on Resolution {
          _id
          ...ResoBasicInfo
        }
        ...on Amendment {
          _id
          ...AmendmentBasicInfo
          ...AmendmentSubmitterInfo
        }
        __typename
      }
      secondaryAmendments {
        _id
      }
    }
  }
  ${FRAGMENT_AMENDMENT_BASIC_INFO}
  ${FRAGMENT_AMENDMENT_SUBMITTER_INFO}
  ${FRAGMENT_RESO_BASIC_INFO}
`

export const SUBSCRIPTION_UPDATE_VOTE = gql`
  subscription voteUpdatedSubscription($CMID: ID!, $VID: ID){
    voteUpdated(CMID: $CMID, VID: $VID){
      _id
      ...VoteTimeStampInfo
      ...VoteBasicInfo
    }
  }
  ${FRAGMENT_VOTE_TIMESTAMP_INFO}
  ${FRAGMENT_VOTE_BASIC_INFO}
`