import React, { useMemo, useState } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import FlipMove from 'react-flip-move'

import { MUTATION_CREATE_RESOLUTION } from '../../../graphql/mutations'
import { useMutation } from 'react-apollo'

import { roleField } from '../../../constant'
import { successNotify, errorNotify } from '../../modules/notification'

import useCommitteeResolutions from './hooks/useCommitteeResolutions'
import useCommitteeOptions from './hooks/useCommitteeOptions'

import { MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact'

import { ResolutionCard } from './ChairResolution'
import { renderRoleName } from '../../modules/util'

export const CreateResolutionModal = ({ CMID, isOpen, toggle = () => { } }) => {
    const { t } = useTranslation();

    const me = jwt_decode(localStorage.getItem(roleField))

    const [topic, setTopic] = useState("");
    const [googleDriveURL, setGoogleDriveURL] = useState("");
    const [showWarn, setShowWarn] = useState(false);


    const [createResolution, { loading: createResoLoading }] = useMutation(MUTATION_CREATE_RESOLUTION, {
        variables: {
            CMID
        }
    });

    const handelSubmit = (e) => {
        e.preventDefault();
        if (!googleDriveURL.match(/https:\/\/docs.google.com\/document\/d\/([a-zA-Z0-9-_/@#$%^&*()]+)/)) {
            errorNotify(t('error'), t('committee.resolution.sentences.invalid'))
        } else {
            let stringArray = [];
            stringArray.push(me._id);

            createResolution({
                variables: {
                    submitters: stringArray,
                    topic,
                    googleDriveURL
                }
            }).then(() => {
                successNotify(t('committee.resolution.sentences.submitted'), false);
                setTopic("");
                setGoogleDriveURL("");
                toggle();
                setShowWarn(true);
            }).catch(() => {
                errorNotify(t('error'), t('committee.resolution.sentences.cannotSend'), false)
            });
        }
    }

    return (
        <>
            <MDBModal isOpen={isOpen} toggle={toggle} size="lg">
                <MDBModalHeader toggle={toggle}>{t('committee.resolution.submitReso')}</MDBModalHeader>
                <MDBModalBody>
                    <form onSubmit={handelSubmit}>
                        <div className="row pt-3">
                            <div className="col">
                                <h5>{t('committee.resolution.topic')}</h5>
                                <input className="form-control" type="text"
                                    placeholder={t('committee.resolution.topicPlaceHold')} value={topic} onChange={(e) => setTopic(e.target.value)} />
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col">
                                <h5>{t('committee.resolution.submitters')}</h5>
                                <h5><span className="badge badge-primary">{renderRoleName(me)}</span></h5>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col">
                                <h5>{t('committee.resolution.google')}</h5>
                                <input className="form-control" type="text"
                                    placeholder="https://docs.google.com/document/d/1jbZW..." value={googleDriveURL} onChange={(e) => setGoogleDriveURL(e.target.value)} />
                            </div>
                        </div>
                        <div className="row pt-3 float-right">
                            <div className="form-group col">
                                <button type="submit" className="btn btn-success" disabled={createResoLoading}>
                                    {t('committee.resolution.submit')}
                                    {
                                        createResoLoading ? <div className="spinner-border spinner-border-sm ml-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : null
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </MDBModalBody>
            </MDBModal>

            <MDBModal isOpen={showWarn}>
                <MDBModalHeader>{t('word.attention')}!</MDBModalHeader>
                <MDBModalBody>
                    <p>{t('committee.resolution.sentences.openAuthority')}</p>
                    <small className="text-muted mb-1">{t('committee.resolution.sentences.howToPublic')}</small>
                    <div className="row float-right mt-2 mr-2">
                        <button type="button" className="btn btn-success" onClick={() => { setShowWarn(false) }}>{t('word.close')}</button>
                    </div>
                </MDBModalBody>
            </MDBModal>
        </>
    )
}

const Resolution = () => {
    const { t } = useTranslation();

    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const { publicResolutions, resolutions: allResolutions, loading: resolutionsLoading } = useCommitteeResolutions(this_CMID);
    const { committeeOptions } = useCommitteeOptions(this_CMID);

    const [isCreateResoOpen, setIsCreateResoOpen] = useState(false);
    const toggleCreateReso = () => setIsCreateResoOpen(!isCreateResoOpen);

    const resolutions = useMemo(() => {
        const sorted = [...publicResolutions, ...(allResolutions.filter((v) => {
            return publicResolutions.findIndex((v2) => v2._id === v._id) === -1 && v.submitters.findIndex((v2) => v2._id === me._id) !== -1;
        }))].sort((a, b) => {
            if (a._id === committeeOptions.currentResolutionId) return -1;
            else if (b._id === committeeOptions.currentResolutionId) return 1;
            else return 0;
        });

        return sorted;
    }, [publicResolutions, committeeOptions.currentResolutionId, allResolutions, me]);

    return (
        <div className="container">
            <div className="row">
                <div className="col text-left">
                    <h2>{t('committee.resolution.resolutions')}</h2>
                </div>
                <div className="col text-right">
                    <button className="btn btn-sm btn-info" onClick={() => setIsCreateResoOpen(true)}>{t('committee.resolution.submitReso')}</button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {
                        !resolutionsLoading ?
                            resolutions && resolutions.length > 0 ?
                                <FlipMove>
                                    {
                                        resolutions.map((resolution) => (
                                            <ResolutionCard
                                                resolution={resolution}
                                                isDelegate={true}
                                                currentResolutionId={committeeOptions.currentResolutionId}
                                                key={resolution._id}
                                            />
                                        )
                                        )
                                    }
                                </FlipMove>
                                :
                                <div className="text-center">
                                    <h1><span className="badge badge-warning">{t('committee.chairResolution.noReso')}</span></h1>
                                </div>
                            :
                            <div className="text-center">
                                <div className="spinner-border text-dark">
                                    <span className="sr-only">{t('loading')}</span>
                                </div>
                            </div>
                    }
                </div>
            </div>

            <CreateResolutionModal isOpen={isCreateResoOpen} toggle={toggleCreateReso} CMID={this_CMID} />
        </div>
    )
}

export default Resolution;
