import { useEffect, useMemo } from 'react'
import update from 'immutability-helper'

import { useQuery } from '@apollo/react-hooks'

import { QUERY_FETCH_COMMITTEE_ROLLCALLS } from '../../../../graphql/queries'
import { SUBSCRIPTION_ROLLCALL_UPDATE, SUBSCRIPTION_ROLLCALL_DELETE } from '../../../../graphql/subscriptions'

const useCommitteeRollCall = (CMID) => {
    const { data, loading, subscribeToMore } = useQuery(QUERY_FETCH_COMMITTEE_ROLLCALLS, {
        variables: {
            CMID
        },
        fetchPolicy: "cache-and-network"
    });

    const { committee } = data || {};
    const { rollCalls } = useMemo(() => {
        return { ...(committee || {}) };
    }, [committee]);

    const sortedRollCalls = useMemo(() => {
        if (rollCalls) {
            const sorted = ([...rollCalls]).sort((a, b) => {
                const ta = new Date(a.time);
                const tb = new Date(b.time);
                return ta > tb ? -1 : (ta === tb ? 0 : 1);
            });

            return sorted;
        }

        return [];
    }, [rollCalls]);

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_ROLLCALL_UPDATE,
            variables: { CMID },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.rollCallUpdated) {
                    const rollCalls = (prev && prev.committee && prev.committee.rollCalls) || [];

                    const { rollCallUpdated } = data;

                    const index = rollCalls.findIndex((v) => v._id === rollCallUpdated._id);

                    let updatedQuery = {};

                    if (index === -1) {
                        updatedQuery = update(prev, {
                            committee: {
                                rollCalls: {
                                    $unshift: [
                                        rollCallUpdated
                                    ]
                                }
                            }
                        });
                    } else {
                        updatedQuery = update(prev, {
                            committee: {
                                rollCalls: {
                                    [index]: {
                                        $set: rollCallUpdated
                                    }
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }

                return prev;
            }
        });

        subscribeToMore({
            document: SUBSCRIPTION_ROLLCALL_DELETE,
            variables: { CMID },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.rollCallDeleted) {

                    const rollCalls = (prev && prev.committee && prev.committee.rollCalls) || [];

                    const { rollCallDeleted } = data;

                    const index = rollCalls.findIndex((v) => v._id === rollCallDeleted._id);

                    let updatedQuery = prev;

                    if (index !== -1) {
                        updatedQuery = update(prev, {
                            committee: {
                                rollCalls: {
                                    $splice: [
                                        [index, 1]
                                    ]
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }

                return prev;
            }
        })
    }, [subscribeToMore, CMID]);

    const lastRollCall = useMemo(() => sortedRollCalls ? sortedRollCalls[0] : null, [sortedRollCalls]);

    return { rollCalls: sortedRollCalls ? sortedRollCalls : [], lastRollCall, loading };
}

export default useCommitteeRollCall