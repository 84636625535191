import React from 'react'
import PageRouter from '../../PageRouter'

import Home from '../pages/organization/Home'
import Settings from '../pages/organization/Settings'

function Organization() {
    return (
        <div className="container-fluid">
            <PageRouter routes={routes} prefix="/organization/:orgid" />
        </div>
    )
}

export default Organization

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        name: "Home",
        hideOnNav: true
    },
    {
        path: "/settings",
        exact: true,
        component: Settings,
        name: "Settings"
    }
];

export { routes as organizationRoutes };