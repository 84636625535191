import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { renderAmendmentDisplayText, renderResolutionDisplayText, renderRoleName } from '../../../modules/util'
import { amendmentStatusCode, munativePic, resolutionStatusCode } from '../../../../constant'
import CountryFlags from '../../../layout/countryFlags'

import { useQuery } from '@apollo/react-hooks'
import { QUERY_FETCH_AMENDMENT, QUERY_FETCH_RESOLUTION } from '../../../../graphql/queries'

const stringToJSX = (str) => {
    return str.split("\n").map(function (item, i) {
        return (
            <Fragment key={i}>
                {item}
                <br />
            </Fragment>
        );
    })
}

const ResolutionAndAmendment = ({ rightDisplaySetting, displayFontSize = "S" }) => {

    const { t, i18n } = useTranslation();

    const { type, targetId } = rightDisplaySetting || {};

    const shouldSkipReso = type !== "resolution" || !targetId;
    const { data: fetchResoData, loading: fetchResoLoading } = useQuery(QUERY_FETCH_RESOLUTION, {
        variables: {
            RESOID: targetId
        },
        fetchPolicy: "network-only",
        skip: shouldSkipReso,
        pollInterval: shouldSkipReso ? 0 : 1000
    });

    const { resolution } = fetchResoData || {};

    const shouldSkipAmendment = type !== "amendment" || !targetId;
    const { data: fetchAmendmentData, loading: fetchAmendmentLoading } = useQuery(QUERY_FETCH_AMENDMENT, {
        variables: {
            AID: targetId
        },
        fetchPolicy: "network-only",
        skip: shouldSkipAmendment,
        pollInterval: shouldSkipAmendment ? 0 : 1000
    });

    const { amendment } = fetchAmendmentData || {};

    const loading = fetchAmendmentLoading || fetchResoLoading;

    const amendmentDisplayClass = {
        "XL": "display-2",
        "L": "display-3",
        "M": "display-4",
        "S": ""
    }

    return <div className="card">
        <div className="card-body text-center">
            {
                !loading ?
                    <>
                        {type === "amendment" && amendment && amendment.amendmentStatus === amendmentStatusCode.Pending ?
                            <>
                                <h1 className="display-3">{t('amendment')}</h1>

                                <h1><span className="badge badge-primary text-wrap">{renderAmendmentDisplayText(amendment, { language: i18n.language })}</span></h1>

                                <div className="row justify-content-center px-5">
                                    <div className="col-auto align-self-center">
                                        <CountryFlags
                                            country={amendment.submitter && amendment.submitter.detail && amendment.submitter.detail.representative
                                                && amendment.submitter.detail.representative.code}
                                            fallback={munativePic}
                                            className="rounded float-left"
                                            width={100}
                                            height={100}
                                            alt={renderRoleName(amendment.submitter)}
                                        />
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <h1>{renderRoleName(amendment.submitter)}</h1>
                                    </div>
                                </div>

                                <hr />

                                <h1 className={amendmentDisplayClass[displayFontSize] || ""}>
                                    {
                                        amendment.content
                                            ? stringToJSX(amendment.content)
                                            : ""
                                    }
                                </h1>
                            </> : null}
                        {type === "resolution" && resolution && resolution.resolutionStatus === resolutionStatusCode.Pending ?
                            <>
                                <h1 className="display-3">{t('resolution')}</h1>

                                <h1><span className="badge badge-primary text-wrap">{renderResolutionDisplayText(resolution)}</span></h1>

                                <div className="row justify-content-center px-5">
                                    <div className="col-auto align-self-center">
                                        <CountryFlags
                                            country={resolution.submitters && resolution.submitters[0] && resolution.submitters[0].detail
                                                && resolution.submitters[0].detail.representative && resolution.submitters[0].detail.representative.code}
                                            fallback={munativePic}
                                            className="rounded float-left"
                                            width={100}
                                            height={100}
                                            alt={renderRoleName(resolution.submitters[0])}
                                        />
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <h1>{resolution.submitters && resolution.submitters[0] ? renderRoleName(resolution.submitters[0]) : "N/A"}</h1>
                                    </div>
                                </div>

                            </> : null}
                    </> :
                    <div className="spinner-border text-primary">
                        <span className="sr-only">{t('loading')}</span>
                    </div>
            }
        </div>
    </div>
}

export default ResolutionAndAmendment;
