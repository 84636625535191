import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import ScrollToBottom, { useAtTop, useSticky, useScrollTo } from 'react-scroll-to-bottom'
import { css } from 'glamor'
import update from 'immutability-helper'

import { errorNotify, successNotify } from '../../modules/notification'
import { renderRoleName } from '../../modules/util'
import Linkify from '../../modules/Linkify'

import { useQuery } from '@apollo/react-hooks'
import { useMutation } from '@apollo/react-hooks'
import { QUERY_FETCH_ROLE_NOTES_CONVERSATION } from '../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_NOTE } from '../../../graphql/subscriptions'
import { MUTATION_SENT_NOTE, MUTATION_UPDATE_NOTE_READ } from '../../../graphql/mutations'
import { useTranslation } from 'react-i18next'

function NoteSendingCard({ currentChatRole, isVoting, me }) {
    const currentChatId = (currentChatRole && currentChatRole._id) || "";

    const { t } = useTranslation();

    const [textAreaTexts, setTextAreaTexts] = useState({});

    const SCROLL_TO_BOTTOM_CSS = css({
        height: 'calc(100% - 160px)'
    });

    const { data, loading, subscribeToMore, fetchMore } = useQuery(QUERY_FETCH_ROLE_NOTES_CONVERSATION, {
        variables: {
            RID: me._id,
            peerId: currentChatId,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const { edges, notes, hasPreviousPage } = useMemo(() => {
        const edges = (data && data.notes && data.notes.edges) || [];

        const processedEdges = edges.filter(({ cursor: a }, i) => edges.findIndex(({ cursor: b }) => a === b) === i).sort((a, b) => a.node.sendTime > b.node.sendTime ? 1 : (a.node.sendTime === b.node.sendTime ? 0 : -1));

        return {
            edges: processedEdges,
            notes: processedEdges.map(({ node }) => node),
            hasPreviousPage: data && data.notes && data.notes.pageInfo && data.notes.pageInfo.hasPreviousPage
        }
    }, [data]);

    useEffect(() => {
        return subscribeToMore({
            document: SUBSCRIPTION_UPDATE_NOTE,
            variables: {
                RID: me._id,
                peerId: currentChatId
            },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.noteUpdated) {
                    const { noteUpdated } = data;

                    const edges = (prev && prev.notes && prev.notes.edges) || [];

                    const index = edges.findIndex((v) => v.cursor === noteUpdated.cursor);

                    let updatedQuery = {};

                    if (index === -1) {
                        updatedQuery = update(prev, {
                            notes: {
                                edges: {
                                    $unshift: [
                                        noteUpdated
                                    ]
                                }
                            }
                        });
                    } else {
                        updatedQuery = update(prev, {
                            notes: {
                                edges: {
                                    [index]: {
                                        $set: noteUpdated
                                    }
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }

                return prev;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChatId]);

    const [mutateSendNote, { loading: sendNoteMutateLoading }] = useMutation(MUTATION_SENT_NOTE, {
        variables: {
            sender: me._id,
            receiver: currentChatId
        }
    });

    const [mutateNoteRead] = useMutation(MUTATION_UPDATE_NOTE_READ);

    useEffect(() => {
        if (notes) {
            notes.filter((note) => note.receiver._id === me._id && note.sender._id === currentChatId).forEach((note) => {
                if (!note.readTime) {
                    mutateNoteRead({
                        variables: { NID: note._id }
                    });
                }
            });
        }
    }, [notes, me, mutateNoteRead, currentChatId]);

    const history = useHistory();

    const onAtTop = async () => {
        if (hasPreviousPage) {
            try {
                await fetchMore({
                    query: QUERY_FETCH_ROLE_NOTES_CONVERSATION,
                    variables: {
                        RID: me._id,
                        peerId: currentChatId,
                        before: (edges && edges[0] && edges[0].cursor) || ""
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (fetchMoreResult) {
                            return {
                                notes: {
                                    ...prev.notes,
                                    edges: [...prev.notes.edges, ...fetchMoreResult.notes.edges],
                                    pageInfo: fetchMoreResult.notes.pageInfo
                                }
                            };
                        }
                        return prev;
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <div className="card h-100">
            <div className="card-body h-100">
                <h3>
                    <span className="backBtn d-md-none mr-3 d-inline"><i className="material-icons" onClick={() => { history.push("/committee/note") }}>navigate_before</i></span>
                    {currentChatRole ? renderRoleName(currentChatRole) : t('committee.noteSendCard.sentences.selectDelegate')}
                </h3>
                <hr />

                <div className={`mt-3 bg-light rounded ${SCROLL_TO_BOTTOM_CSS.toString()}`}>
                    <ScrollToBottom debounce={100} className="h-100">
                        <Conversation peerId={currentChatId} notes={notes} me={me} loading={loading} hasPreviousPage={hasPreviousPage} onAtTop={onAtTop} />
                    </ScrollToBottom>
                </div>
                {/* note sending input */}
                <hr />
                <div className="align-items-end">
                    {!!currentChatRole ?
                        <div className="input-group">
                            <textarea className="form-control" rows="1" disabled={isVoting} value={isVoting ? t('committee.noteSendCard.sentences.votingInProgress') : (textAreaTexts[currentChatRole._id] || "")} onChange={isVoting ? null : (e) => setTextAreaTexts({ ...textAreaTexts, [currentChatRole._id]: e.target.value })} />
                            <button className="btn btn-primary" disabled={sendNoteMutateLoading || isVoting} type="button" onClick={() => {
                                const content = textAreaTexts[currentChatRole._id] || "";

                                if (content.trim() === "") {
                                    errorNotify(t('error'), t('committee.noteSendCard.sentences.noEmpty'));
                                } else {
                                    mutateSendNote({
                                        variables: {
                                            content
                                        }
                                    }).then(({ data: { sendNote } }) => {
                                        const note = sendNote.note;
                                        if (!note.reviewer) {
                                            successNotify(t('committee.noteSendCard.sentences.sent'), t('committee.noteSendCard.sentences.waitApprove'), false);
                                        }
                                        setTextAreaTexts({ ...textAreaTexts, [currentChatRole._id]: "" });
                                    }).catch((err) => {
                                        console.error(err);
                                        errorNotify(t('error'), t('committee.noteSendCard.sentences.cannotSend'), false);
                                    });
                                }
                            }}>{t('committee.noteSendCard.send')}</button>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    )
}

// onAtTop will called when the conversation scrolled to the top.
const Conversation = ({ me, onAtTop = () => { }, notes = [], peerId, loading, hasPreviousPage }) => {

    const { t } = useTranslation();

    const [isAtTop] = useAtTop();
    const [isSticky] = useSticky();
    const scrollTo = useScrollTo();

    const filteredNotes = notes.filter((note) => {
        return note.sender._id === peerId || note.receiver._id === peerId;
    });

    useEffect(() => {
        if (isAtTop && !isSticky) {
            if (loading) return;
            if (hasPreviousPage) scrollTo(10, { behavior: "auto" });
            onAtTop();
        }
    }, [isAtTop, isSticky, scrollTo, onAtTop, loading, hasPreviousPage]);

    const renderMessage = ({ content, sender, blocked, sendTime, readTime, reviewer, blockedReason, _id }) => {

        const isMe = me._id === sender._id;

        return isMe || reviewer ? (
            <div className="row mb-3" key={_id}>
                <div className="col">
                    <div>
                        <h5 className="mb-3"><span className={"badge " + (isMe ? "badge-dark" : "badge-primary")}>{isMe ? t('committee.noteSendCard.me') : renderRoleName(sender)}</span></h5>
                        <div className={"rounded py-1 px-3 bg-white " + (blocked ? "border border-danger " : "") + (!reviewer ? "border border-warning " : "")}>
                            <div className="mt-3">
                                {blocked ?
                                    <>
                                        <span className="badge badge-danger">{t('committee.noteSendCard.sentences.blocked')}</span>
                                        {isMe ?
                                            <>
                                                <p className="my-1">{t('committee.noteSendCard.reason')}: {blockedReason || ""}</p>
                                            </> : <></>}

                                    </> :
                                    <p>
                                        {
                                            (content || "").split("\n").map((line, i) => {
                                                return <Fragment key={i}>
                                                    <Linkify>{line}</Linkify>
                                                    <br />
                                                </Fragment>
                                            })
                                        }
                                    </p>
                                }
                            </div>
                            <hr style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
                            {
                                readTime ?
                                    <span className="text-muted"><i>
                                        {t('committee.noteSendCard.read')}
                                    </i></span>
                                    :
                                    <span className="text-muted"><i>
                                        {t('committee.noteSendCard.unread')}
                                    </i></span>
                            }
                            <p className="text-muted">
                                {t('committee.noteSendCard.sendAt')} : {new Date(sendTime).toLocaleString() + " "}
                                {
                                    !reviewer ? <span className="badge badge-warning badge-pill ml-2">{t('committee.noteSendCard.pending')}</span> : null
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    }

    return (
        <div className="p-3">
            {
                !peerId ?
                    <div className="text-center mt-2">
                        <h4><span className="badge badge-info badge-pill text-wrap">{t('committee.noteSendCard.sentences.select')}</span></h4>
                    </div>
                    : (
                        <>
                            {
                                loading ?
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only">{t('loading')}</span>
                                        </div>
                                    </div> :
                                    null
                            }
                            {
                                filteredNotes.length > 0 ?
                                    <>
                                        {
                                            !hasPreviousPage ?
                                                <div className="text-center mt-2">
                                                    <h4><span className="badge badge-info badge-pill text-wrap">{t('committee.noteSendCard.sentences.topMessage')}</span></h4>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            filteredNotes.filter((note) => note.sender._id === peerId || note.receiver._id === peerId).map((data) => {
                                                return renderMessage(data);
                                            })
                                        }
                                    </> :
                                    !loading ? (
                                        <div className="text-center mt-2">
                                            <h4><span className="badge badge-dark badge-pill text-wrap">{t('committee.noteSendCard.sentences.sendFirstMessage')}</span></h4>
                                        </div>
                                    ) : null
                            }
                        </>
                    )
            }
        </div>
    )
}

export default NoteSendingCard
