import React, { useState, useMemo } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useHistory, useLocation } from 'react-router-dom'
import { QUERY_FETCH_CONFERENCE } from '../../graphql/queries'
import { useQuery } from '@apollo/react-hooks'
import FlipMove from 'react-flip-move'

import { roleField, CommitteeProcedures, attendStatusCode, amendmentStatusCode } from '../../constant'
import { renderCurrentCmProcedureName, renderRightDisplayModeName, renderRoleName } from '../modules/util'

import './committee/chairdash/loading.css'
import RollCallStat from './committee/rollcall/RollCallStat'
import { TallySheet } from './committee/TallySheet'
import { PendingAmendment, ProcessedAmendment } from './committee/Amendment'

import useCommitteeOptions from './committee/hooks/useCommitteeOptions'
import useCommitteeRollCall from './committee/hooks/useCommitteeRollCall'
import useCommitteeRoles from './committee/hooks/useCommitteeRoles'
import useCommitteeResolutions from './committee/hooks/useCommitteeResolutions'
import useCommitteeAmendments from './committee/hooks/useCommitteeAmendments'
import { ResolutionCard } from './committee/ChairResolution'
import { useTranslation } from 'react-i18next'

function Amendment({ CMID }) {
    const { t } = useTranslation();

    const { committee, loading: cmOptionsLoading } = useCommitteeOptions(CMID);

    const cmType = useMemo(() => (committee || {}).type, [committee]);

    const { cmAmendments } = useCommitteeAmendments(CMID);

    const [showFailedAmendments, setShowFailedAmendments] = useState(false);

    const loading = cmOptionsLoading;

    return (
        <div className="container">
            {
                !loading ?
                    <>
                        <div className="row">
                            <div className="col-6">
                                <h2 className="mb-3"><strong>{t('committee.chairAmendment.pending')}</strong></h2>
                                <div className="card-columns amendment-columns">
                                    {
                                        cmAmendments ?
                                            cmAmendments
                                                .filter((v) => v.amendmentStatus === amendmentStatusCode.Pending)
                                                .map((amendment) => <PendingAmendment
                                                    key={amendment._id}
                                                    amendment={amendment}
                                                    isChair={false}
                                                    cmType={cmType}
                                                    isDelegate={false}
                                                />)
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row justify-content-around">
                                    <div className="col-12 col-sm-auto order-2 order-sm-1">
                                        <h2 className="mb-3"><strong>{t('committee.chairAmendment.processed')}</strong></h2>
                                    </div>
                                    <div className="col text-right order-1 order-sm-2">
                                        <button className={`btn text-nowarp btn-sm btn-${showFailedAmendments ? "warning" : "dark"}`}
                                            onClick={() => setShowFailedAmendments(!showFailedAmendments)}
                                        >
                                            {showFailedAmendments ? t('committee.chairAmendment.hideFailed') : t('committee.chairAmendment.showAll')}
                                        </button>
                                    </div>
                                </div>
                                <div className="card-columns amendment-columns">
                                    {
                                        cmAmendments ?
                                            cmAmendments
                                                .filter((v) => {
                                                    if (v.amendmentStatus === amendmentStatusCode.Pending) {
                                                        return false;
                                                    }

                                                    if (!showFailedAmendments) {
                                                        if (v.amendmentStatus === amendmentStatusCode.Passed && v.target.__typename === "Amendment") {
                                                            return v.target.amendmentStatus !== amendmentStatusCode.NotPassed;
                                                        } else if (v.amendmentStatus !== amendmentStatusCode.Passed) {
                                                            return false;
                                                        }
                                                    }

                                                    return true;
                                                })
                                                .map((amendment) => <ProcessedAmendment key={amendment._id} amendment={amendment} />)
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="text-center mt-10">
                        <div className="spinner-border text-dark">
                            <span className="sr-only">{t('loading')}</span>
                        </div>
                    </div>
            }
        </div >
    )
}

const Resolution = ({ CMID }) => {
    const { t } = useTranslation();

    const { resolutions: sourceReso, loading: resolutionsLoading } = useCommitteeResolutions(CMID);
    const { committeeOptions } = useCommitteeOptions(CMID);

    const resolutions = useMemo(() => {
        const sorted = [...sourceReso].sort((a, b) => {
            if (a._id === committeeOptions.currentResolutionId) return -1;
            else if (b._id === committeeOptions.currentResolutionId) return 1;
            else return 0;
        });

        return sorted;
    }, [sourceReso, committeeOptions.currentResolutionId]);

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    {
                        !resolutionsLoading ?
                            resolutions && resolutions.length > 0 ?
                                <FlipMove>
                                    {
                                        resolutions.map((resolution) => (
                                            <ResolutionCard
                                                resolution={resolution}
                                                isDelegate={true}
                                                currentResolutionId={committeeOptions.currentResolutionId}
                                                key={resolution._id}
                                            />
                                        )
                                        )
                                    }
                                </FlipMove>
                                :
                                <div className="text-center">
                                    <h1><span className="badge badge-warning">{t('committee.chairResolution.noReso')}</span></h1>
                                </div>
                            :
                            <div className="text-center">
                                <div className="spinner-border text-dark">
                                    <span className="sr-only">{t('loading')}</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

function InfoCard({ conference, loading }) {

    const { name, startTime, endTime } = conference || {};

    return (
        <div className={"card my-2 " + (loading ? "loading" : "")}>
            <div className="card-body">
                <h4 className="card-title">{name}</h4>
                <h5>Start time: <span className="badge badge-dark">{new Date(startTime).toLocaleString()}</span></h5>
                <h5>End time: <span className="badge badge-dark">{new Date(endTime).toLocaleString()}</span></h5>
            </div>
        </div>
    )
}

function CommitteeList({ committees, loading, currentCommittee, displayContent }) {
    const history = useHistory();

    const selectOnClick = (display) => {
        history.push(`/director/conf?committee=${currentCommittee}&display=${display}`);
    }

    return (
        <div className={"card my-2 " + (loading ? "loading" : "")}>
            <div className="card-body">
                <h4 className="card-title my-2">Content Select</h4>
                <h6 className="badge-group">
                    <span className={`badge badge-${displayContent === "overview" ? "primary" : "dark"}`} onClick={() => selectOnClick("overview")}>
                        Overview
                    </span>
                    <span className={`badge badge-${displayContent === "tally" ? "primary" : "dark"}`} onClick={() => selectOnClick("tally")}>
                        Tally
                    </span>
                    <span className={`badge badge-${displayContent === "resolution" ? "primary" : "dark"}`} onClick={() => selectOnClick("resolution")}>
                        Resolution
                    </span>
                    <span className={`badge badge-${displayContent === "amendment" ? "primary" : "dark"}`} onClick={() => selectOnClick("amendment")}>
                        Amendment
                    </span>
                </h6>
                <h4 className="card-title my-2">Committee List</h4>
                <ul className="list-group" style={{
                    height: 'calc(100vh - 450px)',
                    overflow: 'auto'
                }}>
                    {
                        committees ? committees.map((data, index) => {
                            const { _id, name, type } = data;
                            return (
                                <li className={"list-group-item list-group-item-action " + (currentCommittee === _id ? "active" : "")}
                                    key={index}
                                    onClick={() => history.push("/director/conf?committee=" + _id + (displayContent ? `&display=${displayContent}` : ""))} >
                                    {name}{" "}<span className="badge badge-info">{type}</span>
                                </li>
                            );
                        }) : null
                    }
                </ul>
            </div>
        </div >
    )
}

function CommitteeMainCard({ CMID }) {
    const { committeeOptions, loading: cmOptionsLoading } = useCommitteeOptions(CMID);

    const { lastRollCall, loading: rollCallLoading } = useCommitteeRollCall(CMID);

    const { delegates, chairs, admins, loading: rolesLoading } = useCommitteeRoles(CMID);

    const { currentProcedure: cmProcedure, customTitle, rightDisplaySelected } = committeeOptions || {};

    let currentProcedure = "";

    if (cmProcedure === CommitteeProcedures.CustomDisp) {
        currentProcedure = customTitle || "";
    } else {
        currentProcedure = renderCurrentCmProcedureName(cmProcedure);
    }

    const rightDisplayRender = renderRightDisplayModeName(rightDisplaySelected);

    const { attendee } = lastRollCall || {};

    const loading = rollCallLoading || cmOptionsLoading || rolesLoading;

    return (<div className="card my-2">
        <div className="card-body">
            {
                !loading ?
                    <>
                        <div className="row">
                            <div className="col">
                                <h4>
                                    <span className="badge badge-primary">{currentProcedure || "n/a"}</span> / <span className="badge badge-primary">{rightDisplayRender || "n/a"}</span>
                                </h4>
                            </div>
                            <div className="col text-right">
                                <a className="btn btn-primary btn-sm" href={`/director/audience?committee=${CMID}`} rel="noreferrer" target="_blank"> {"Open Aud Display"} </a>
                            </div>
                        </div>
                        <hr />
                        <h3 className="text-center">Last Roll Call</h3>
                        {
                            lastRollCall ?
                                <RollCallStat lastRollCall={lastRollCall} /> :
                                <h4 className="text-center"><span className="badge badge-warning">No Last Roll Call</span></h4>
                        }
                        <hr />
                        <h3 className="text-center">Delegates</h3>
                        <div className="row justify-content-center">
                            {
                                delegates ? delegates.map((v, i) => {
                                    const colors = {
                                        [attendStatusCode.Absent]: "warning",
                                        [attendStatusCode.Present]: "info",
                                        [attendStatusCode.PresentAndVoting]: "success"
                                    }

                                    const attendeeCode = attendee ? attendee[v._id] : -1;

                                    return (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-2 align-self-center" key={i}>
                                            <h6 className="text-center">
                                                <span className={"badge text-wrap badge-" + (colors[attendeeCode] || "dark")}>{renderRoleName(v)}</span>
                                            </h6>
                                        </div>
                                    )

                                }) : null
                            }
                        </div>
                        <h3 className="text-center">Chairs / Admins</h3>
                        <div className="row justify-content-center">
                            {
                                chairs ? chairs.map((v, i) => {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-2 align-self-center" key={i}>
                                            <h6 className="text-center">
                                                <span className="badge text-wrap badge-primary"> {renderRoleName(v)}</span>
                                            </h6>
                                        </div>
                                    )

                                }) : null
                            }
                            {
                                admins ? admins.map((v, i) => {
                                    return (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-2 align-self-center" key={i}>
                                            <h6 className="text-center">
                                                <span className="badge text-wrap badge-danger">{renderRoleName(v)}</span>
                                            </h6>
                                        </div>
                                    )

                                }) : null
                            }
                        </div>
                    </> :
                    <div className="text-center">
                        <div className="spinner-border text-primary">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            }
        </div>
    </div >
    )
}

function useURLQuery() {
    return new URLSearchParams(useLocation().search);
}

function Director() {
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CFID = me.roleTarget;

    const { data: cfData, loading: cfLoading } = useQuery(QUERY_FETCH_CONFERENCE, {
        variables: {
            CFID: this_CFID
        },
        fetchPolicy: "network-only"
    });

    const query = useURLQuery();
    const committeeId = query.get("committee");
    const displayContent = !query.get("display") || query.get("display") === "null" ? "overview" : query.get("display");

    const { conference } = cfData || {};
    const { committees } = conference || {};


    return (
        <div className="container-fluid">
            <div className="mx-5 mt-1">
                <div className="row">
                    <div className="col-md-4 col-lg-3">
                        <InfoCard conference={conference} loading={cfLoading} />
                        <CommitteeList committees={committees} loading={cfLoading} currentCommittee={committeeId} displayContent={displayContent} />
                    </div>
                    <div className="col-md-8 col-lg-9">
                        {
                            committeeId && committeeId !== "null" ?
                                <>
                                    {
                                        displayContent === "tally" ? <TallySheet CMID={committeeId} /> : null
                                    }
                                    {
                                        displayContent === "overview" ? <CommitteeMainCard CMID={committeeId} /> : null
                                    }
                                    {
                                        displayContent === "resolution" ? <Resolution CMID={committeeId} /> : null
                                    }
                                    {
                                        displayContent === "amendment" ? <Amendment CMID={committeeId} /> : null
                                    }
                                </> :
                                <h3 className="text-center"><span className="badge badge-primary">Select a committee</span></h3>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Director
