import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import NotFound from './component/layout/errPages/NotFound'
import * as jwt_decode from 'jwt-decode';
import { roleField, roleCode, tokenField } from './constant'

class PageRouter extends Component {
    constructor() {
        super();

        let me, user;

        if (localStorage.getItem(roleField)) {
            try {
                me = jwt_decode(localStorage.getItem(roleField));
            } catch (error) {
                console.log(error);
            }
        } else if (localStorage.getItem(tokenField)) {
            try {
                user = jwt_decode(localStorage.getItem(tokenField));
            } catch (error) {
                console.log(error);
            }
        }

        this.state = {
            me,
            user
        }
    }

    checkPasswordNeedChange() {
        if (this.state.user && this.state.user.passwordNeedChange && this.props.location.pathname !== "/auth/updatePassword") {
            this.props.history.push("/auth/updatePassword");
        }
    }

    componentDidMount() {
        this.checkPasswordNeedChange();
    }

    componentDidUpdate() {
        this.checkPasswordNeedChange();
    }

    render() {
        const { routes, prefix } = this.props;

        const { me, user } = this.state;

        return (
            <Switch>
                {routes.map((route, i) => {
                    const { path, exact, routes, permission, redirect, denyPermission } = route;

                    return (
                        <Route
                            key={i}
                            path={Array.isArray(path) ? path.map((v) => prefix ? (prefix + v) : v) : prefix ? (prefix + path) : path}
                            exact={exact}
                            render={(routeProps) => {

                                const deny = () => {
                                    if (redirect) {
                                        if ((typeof redirect) === 'object') {
                                            const target = redirect[role] ? redirect[role] : (redirect.default ? redirect.default : "/auth/signin");
                                            return (<Redirect to={target} />);
                                        }
                                        else return (<Redirect to={redirect} />);
                                    } else {
                                        return (<Redirect to="/auth/signin" />);
                                    }
                                }

                                const role = me ? me.role : (user ? roleCode.NoRole : roleCode.NoAuth);
                                if (denyPermission && denyPermission.includes(role)) {
                                    return deny();
                                }

                                if (permission) {
                                    if (permission.includes(role)) {
                                        return (<route.component routes={routes} {...routeProps} />);
                                    } else {
                                        return deny();
                                    }
                                } else {
                                    return (<route.component routes={routes} {...routeProps} />);
                                }
                            }}
                        />
                    );
                })}
                <Route component={NotFound}></Route>
            </Switch>
        )
    }
}

export default withRouter(PageRouter);