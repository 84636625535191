import { useEffect } from 'react'

import { useQuery } from 'react-apollo'
import { QUERY_FETCH_COMMITTEE } from '../../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS } from '../../../../graphql/subscriptions'

function useCommitteeOptions(CMID) {
    const { data, loading, subscribeToMore } =
        useQuery(QUERY_FETCH_COMMITTEE, {
            variables: {
                CMID
            },
            fetchPolicy: "cache-and-network"
        });

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS,
            variables: {
                CMID
            },
            updateQuery: (prev, { subscriptionData: { data } }) => {
                if (data && data.updateCommitteeOptions) {
                    if (data.updateCommitteeOptions !== prev) {
                        return {
                            ...prev,
                            committee: {
                                ...prev.committee,
                                ...data.updateCommitteeOptions
                            }
                        };
                    }
                }
                return prev;
            }
        })
    }, [CMID, subscribeToMore]);

    const { committee } = data || {};
    const committeeOptions = (!loading && committee ? committee.options : {}) || {};

    return { committeeOptions, loading, committee };
}

export default useCommitteeOptions
