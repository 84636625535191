import React, { useEffect, useState } from 'react'

function Slider({ children = [], slideInterval = 1000 }) {
    if (!Array.isArray(children)) children = [children]

    children = children.filter((v) => !!v)

    const [currIndex, setCurrIndex] = useState(0);


    useEffect(() => {
        let id = setInterval(() => {
            setCurrIndex((index) => {
                let nextIndex = index + 1;
                if (nextIndex >= children.length) {
                    nextIndex = 0;
                }

                return nextIndex;
            });
        }, slideInterval);
        return () => clearInterval(id);
    }, [slideInterval, children.length]);

    useEffect(() => {
        if (children.length <= currIndex) {
            setCurrIndex(0);
        }
    }, [children, currIndex]);

    return (
        <>
            {children[currIndex] ? children[currIndex] : null}
        </>
    )
}

export default Slider
