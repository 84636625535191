import { useEffect, useMemo } from 'react'

import useCommitteeResolutions from './useCommitteeResolutions'

import { useQuery } from 'react-apollo'
import { QUERY_FETCH_RESOLUTION_AMENDMENTS } from '../../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_AMENDMENT, SUBSCRIPTION_CREATE_AMENDMENT } from '../../../../graphql/subscriptions'


const useResolutionsAndAmendments = (CMID, currentRESOLUTION, skipResolutions) => {
    const { resolutions, loading: cmResoLoading } = useCommitteeResolutions(CMID, skipResolutions);

    const { data: resoData, loading: resoLoading, subscribeToMore: resoSub } = useQuery(QUERY_FETCH_RESOLUTION_AMENDMENTS, {
        variables: {
            RESOID: currentRESOLUTION
        },
        fetchPolicy: "cache-and-network",
        skip: !currentRESOLUTION
    });

    useEffect(() => {
        const handleUpdateQuery = (prev, amendment) => {
            let { amendments } = prev.resolution;

            let index = amendments.findIndex((v) => {
                return v._id === amendment._id;
            });

            if (index === -1) {
                amendments.push(amendment);
            } else {
                amendments[index] = {
                    ...amendments[index],
                    ...amendment
                };
            }

            return {
                ...prev,
                resolution: {
                    ...prev.resolution,
                    amendments
                }
            };
        }

        if (currentRESOLUTION) {
            resoSub({
                document: SUBSCRIPTION_CREATE_AMENDMENT,
                variables: {
                    RESOID: currentRESOLUTION
                },
                updateQuery: (prev, { subscriptionData: { data } }) => {
                    if (data && data.amendmentCreated) {
                        const { amendmentCreated } = data;

                        return handleUpdateQuery(prev, amendmentCreated);
                    }
                    return prev;
                }
            });

            resoSub({
                document: SUBSCRIPTION_UPDATE_AMENDMENT,
                variables: {
                    RESOID: currentRESOLUTION
                },
                updateQuery: (prev, { subscriptionData: { data } }) => {
                    if (data && data.amendmentUpdated) {
                        const { amendmentUpdated } = data;

                        return handleUpdateQuery(prev, amendmentUpdated);
                    }
                    return prev;
                }
            });
        }
    }, [currentRESOLUTION, resoSub]);

    const { resolution } = resoData || {};
    const { amendments, topic } = useMemo(() => {
        const reso = resolution || {};
        return {
            amendments: reso.amendments || [],
            topic: reso.topic
        }
    }, [resolution]);

    return { resolutions: resolutions || [], resolution, topic, amendments, loading: cmResoLoading || resoLoading }
}

export default useResolutionsAndAmendments