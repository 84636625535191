import React, { useState, useEffect, useMemo, Fragment } from "react"
import { useTranslation } from "react-i18next"
import * as jwt_decode from "jwt-decode"
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from "mdbreact"

import {
  MUTATION_CREATE_AMENDMENT,
  MUTATION_CREATE_SECONDARY_AMENDMENT,
  MUTATION_EDIT_AMENDMENT,
  MUTATION_UPDATE_AMENDMENT_STATUS,
  MUTATION_RETRIEVE_AMENDMENT,
  MUTATION_REJECT_AMENDMENT,
} from "../../../graphql/mutations"
import { useMutation } from "@apollo/react-hooks"

import { errorNotify, successNotify } from "../../modules/notification"
import {
  roleField,
  amendmentStatusCode,
  roleCode,
  ruleOfProcedure,
  resolutionStatusCode,
} from "../../../constant"
import {
  renderResolutionDisplayText,
  renderAmendmentDisplayText,
  renderAmendmentDisplayType,
  renderRoleName,
} from "../../modules/util"

import useCommitteeOptions from "./hooks/useCommitteeOptions"
import useResolutionsAndAmendments from "./hooks/useResolutionsAndAmendments"
import useCommitteeResolutions from "./hooks/useCommitteeResolutions"
import useCommitteeAmendments from "./hooks/useCommitteeAmendments"
import useAmendmentSortMode from "./hooks/useAmendmentSortMode"

function AmendmentClauseSelector({
  currentClause,
  clause = -1,
  subClause = -1,
  subSubClause = -1,
  onChange = () => { },
}) {
  const { t } = useTranslation()

  const generateOptions = (type) => {
    let table = []
    let subClause = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"]
    let subSubClause = [
      "i",
      "ii",
      "iii",
      "iv",
      "v",
      "vi",
      "vii",
      "viii",
      "ix",
      "x",
      "xi",
      "xii",
      "xiii",
      "xiv",
      "xv",
    ]

    if (type === "clause") {
      for (let i = 0; i < 50; i++) {
        table.push(
          <option id={"clause" + i} key={i} value={i}>
            {i + 1}
          </option>
        )
      }
    } else {
      for (let i = 0; i < 15; i++) {
        if (type === "sub-clause") {
          table.push(
            <option id={"subClause" + i} key={i} value={i}>
              {subClause[i]}
            </option>
          )
        } else if (type === "sub-sub-clause") {
          table.push(
            <option id={"subSubClause" + i} key={i} value={i}>
              {subSubClause[i]}
            </option>
          )
        }
      }
    }
    return table
  }

  return (
    <div className="form-row">
      <div className="form-group col">
        <label>{t("committee.amendment.clause")}</label>
        <select
          value={clause}
          id="inputState"
          className="form-control"
          disabled={currentClause && currentClause.onUse && currentClause.clause ? true : false}
          onChange={(e) => onChange(e.target.value, subClause, subSubClause)}
        >
          <option value="-1">-</option>
          {generateOptions("clause")}
        </select>
      </div>
      <div className="form-group col">
        <label>{t("committee.amendment.subClause")}</label>
        <select
          value={subClause}
          id="inputState"
          className="form-control"
          onChange={(e) => onChange(clause, e.target.value, subSubClause)}
        >
          <option value="-1">-</option>
          {generateOptions("sub-clause")}
        </select>
      </div>
      <div className="form-group col">
        <label>{t("committee.amendment.subSubClause")}</label>
        <select
          value={subSubClause}
          id="inputState"
          className="form-control"
          onChange={(e) => onChange(clause, subClause, e.target.value)}
        >
          <option value="-1">-</option>
          {generateOptions("sub-sub-clause")}
        </select>
      </div>
    </div>
  )
}

function AmendmentTypeSelector({ className = "", value, onChange = () => { } }) {
  const { t } = useTranslation()

  const getBtnGroupClassName = (isActivate) => {
    return `btn btn-${isActivate ? "success" : "blue-grey"}`
  }

  return (
    <div className={`form-group ${className}`}>
      <label className="d-block">{t("committee.amendment.type")}</label>
      <div className="btn-group btn-group-sm btn-group-toggle">
        <label className={getBtnGroupClassName(value === "Add")}>
          <input type="radio" autoComplete="off" onClick={() => onChange("Add")} />{" "}
          {t("committee.amendment.add")}
        </label>
        <label className={getBtnGroupClassName(value === "Modify")}>
          <input type="radio" autoComplete="off" onClick={() => onChange("Modify")} />{" "}
          {t("committee.amendment.modify")}
        </label>
        <label className={getBtnGroupClassName(value === "Strike")}>
          <input type="radio" autoComplete="off" onClick={() => onChange("Strike")} />{" "}
          {t("committee.amendment.strike")}
        </label>
      </div>
    </div>
  )
}

const generateResolutions = (resolutions) => {
  return resolutions
    .filter((resolution) => resolution.resolutionStatus === resolutionStatusCode.Pending)
    .map((resolution, i) => (
      <option id={"reso" + resolution.topic} key={resolution._id} value={resolution._id}>
        {renderResolutionDisplayText(resolution)}
      </option>
    ))
}

function SubmitAmendmentForm(props) {
  const { secondaryTarget: propSecondaryTarget, currentClause } = props

  const { t, i18n } = useTranslation()
  const [secondaryTarget, setSecondaryTarget] = useState(null)
  const [type, setType] = useState("")
  const [RESOID, setRESOID] = useState("")
  const [clause, setClause] = useState("-1")
  const [subClause, setSubClause] = useState("-1")
  const [subSubClause, setSubSubClause] = useState("-1")
  const [content, setContent] = useState("")

  const { publicResolutions } = useCommitteeResolutions(props.CMID)

  useEffect(() => {
    if (propSecondaryTarget) {
      setSecondaryTarget(propSecondaryTarget)
    }
  }, [propSecondaryTarget])

  useEffect(() => {
    if (currentClause && currentClause.onUse) {
      setClause(currentClause.clause)
    }
  }, [currentClause])

  const onError = (error) => {
    errorNotify(t("error"), t("committee.amendment.sentences.cannotSendAmendment"), false)
  }

  const onCompleted = (data) => {
    successNotify(
      t("committee.amendment.sentences.amendmentSent"),
      t("committee.amendment.sentences.doubleCheck"),
      false
    )
  }

  const [createAmendment] = useMutation(MUTATION_CREATE_AMENDMENT, { onError, onCompleted })
  const [createSecondarymendment] = useMutation(MUTATION_CREATE_SECONDARY_AMENDMENT, {
    onError,
    onCompleted,
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (
          (props.RESOID || RESOID || secondaryTarget) &&
          props.RID &&
          type &&
          (content || type === "Strike")
        ) {
          if (secondaryTarget) {
            createSecondarymendment({
              variables: {
                AID: secondaryTarget._id,
                submitter: props.RID,
                content,
                type,
              },
            })
          } else {
            createAmendment({
              variables: {
                RESOID: props.cmType === ruleOfProcedure.UNAUSA ? RESOID : props.RESOID,
                submitter: props.RID,
                clause: parseInt(clause),
                subClause: parseInt(subClause),
                subSubClause: parseInt(subSubClause),
                content,
                type,
              },
            })
          }

          setType("")
          setRESOID("")
          setClause("-1")
          setSubClause("-1")
          setSubSubClause("-1")
          setContent("")
            ; (props.onSubmit || (() => { }))()
        } else {
          errorNotify(t("error"), t("committee.amendment.sentences.fillAllRequiredField"), false)
        }
      }}
    >
      {secondaryTarget ? (
        <div className="form-row">
          <div className="col">
            <h5>
              {" "}
              {t("committee.amendment.secondaryAmendmentOf")}{" "}
              <span className="badge badge-primary">
                {renderAmendmentDisplayText(secondaryTarget, { language: i18n.language })}
              </span>
            </h5>
          </div>
        </div>
      ) : null}
      <div className="form-row">
        {props.cmType === ruleOfProcedure.UNAUSA && !secondaryTarget ? (
          <div className="form-group col">
            <label className="d-block">{t("committee.amendment.resolution")}</label>
            <select
              value={RESOID}
              id="inputState"
              className="form-control"
              onChange={(e) => setRESOID(e.target.value)}
            >
              <option value="-1">-</option>
              {generateResolutions(publicResolutions)}
            </select>
          </div>
        ) : (
          <></>
        )}
        <AmendmentTypeSelector className="col" value={type} onChange={(v) => setType(v)} />
      </div>

      {!secondaryTarget ? (
        <AmendmentClauseSelector
          currentClause={currentClause}
          clause={clause}
          subClause={subClause}
          subSubClause={subSubClause}
          onChange={(clause, subClause, subSubClause) => {
            setClause(clause)
            setSubClause(subClause)
            setSubSubClause(subSubClause)
          }}
        />
      ) : null}

      <div className="form-row">
        <div className="form-group col">
          <label>{t("committee.amendment.content")}</label>
          <textarea
            value={content}
            className="form-control"
            rows="3"
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
      </div>

      <div className="form-row pt-3 float-right">
        <button type="submit" className="btn btn-md btn-success">
          {t("committee.amendment.submit")}
        </button>
      </div>
    </form>
  )
}

function EditAmendmentPart({ amendment, onClose = () => { } }) {
  const { t } = useTranslation()

  const [bufferContent, setBufferContent] = useState("")
  const [editAmendment, { loading }] = useMutation(MUTATION_EDIT_AMENDMENT)

  useEffect(() => {
    if (amendment) {
      setBufferContent(amendment.content)
    }
  }, [amendment])

  return (
    <>
      <div className="form-group">
        <label htmlFor="centerText">{t("committee.chairAmendment.editAmendment")}</label>
        <textarea
          id="centerText"
          className="form-control"
          value={bufferContent}
          rows="2"
          onChange={(e) => setBufferContent(e.target.value)}
          placeholder={t("committee.chairAmendment.sentences.reformatMsg")}
        ></textarea>
      </div>
      <button
        className="btn btn-success btn-sm"
        disabled={loading}
        onClick={() => {
          if (!bufferContent || bufferContent.length === 0) {
            errorNotify(
              t("error"),
              t("notifications.chairAmendment.error.youCannotSubmitBlank"),
              false
            )
          } else {
            editAmendment({
              variables: {
                AID: amendment._id,
                content: bufferContent,
              },
              context: {
                debounceKey: `editAm${amendment._id}`,
              },
            }).then(() => {
              successNotify(t("notifications.chairAmendment.success.editAmendmentSuccess"))
              onClose()
            })
          }
        }}
      >
        {t("committee.chairAmendment.save")}
      </button>
      <button className="btn btn-dark btn-sm" onClick={onClose}>
        {t("committee.chairAmendment.close")}
      </button>
    </>
  )
}

export const PendingAmendment = ({
  amendment,
  isDelegate = false,
  isChair = true,
  isCurrentReso,
  ownAmentment = false,
  canRetrieve = false,
  canReject = false,
  onSubmitSecondary = () => { },
  onAmendmentRetrieve = () => { },
  onAmendmentReject = () => { },
  cmType,
}) => {
  const { t, i18n } = useTranslation()

  const isSecondaryAmendment =
    amendment && amendment.target && amendment.target.__typename === "Amendment"

  const [amendmentEnableEdit, setAmendmentEnableEdit] = useState(false)
  const [editAmendment, { loading: editMutationLoading }] = useMutation(MUTATION_EDIT_AMENDMENT)
  const [updateAmendment, { loading: updateStatusMutationLoading }] = useMutation(
    MUTATION_UPDATE_AMENDMENT_STATUS
  )

  const hasSecondaryAmendments = ((amendment && amendment.secondaryAmendments) || []).length > 0

  const handleAmendmentPassOrFail = (id, pass) => {
    updateAmendment({
      variables: {
        AID: id,
        status: pass ? amendmentStatusCode.Passed : amendmentStatusCode.NotPassed,
      },
      context: {
        debounceKey: `updateAmStatus${id}`,
      },
    }).then(() => {
      successNotify(
        `${t("amendment")} ${pass ? t("committee.chairAmendment.passed") : t("committee.chairAmendment.failed")
        }`
      )
    })
  }

  const handleAmendmentPublicOrPrivate = (id, makePublic) => {
    editAmendment({
      variables: {
        AID: id,
        isPublic: !!makePublic,
      },
      content: {
        debounceKey: `editAm${id}`,
      },
    }).then(() => {
      successNotify(
        makePublic
          ? t("notifications.chairAmendment.amendmentIsPublic")
          : t("notifications.chairAmendment.amendmentIsPrivate")
      )
    })
  }

  const colors = {
    Add: "primary",
    Strike: "warning",
    Delete: "danger",
  }

  const mutationLoading = updateStatusMutationLoading || editMutationLoading;

  return (
    <div className={"card border-" + (colors[amendment.type] || "")}>
      <div className="card-body">
        <div className="row mb-2 justify-content-between">
          <div className="col-auto align-content-center">
            <h4 className="card-title mb-0">
              <span className={"badge badge-" + (colors[amendment.type] || "dark")}>
                {renderAmendmentDisplayType(amendment, i18n.language)}
              </span>
            </h4>
          </div>

          {isChair && (isCurrentReso || cmType !== ruleOfProcedure.THIMUN) ? (
            <div style={{display:"flex", alignItems:"center"}}>
                <span style={{display: "auto"}}>
                  <span className="icon-btn">
                      <span
                          hidden={mutationLoading || !isCurrentReso}
                          className="material-icons" 
                          style={{cursor: "pointer"}}
                          onClick={() => handleAmendmentPassOrFail(amendment._id, true)}
                          title={t('committee.chairAmendment.passAmendment')}>
                              check_circle_outline
                      </span>
                  </span> 
                  <span className="icon-btn">
                      <span
                          hidden={mutationLoading || !isCurrentReso}
                          className="material-icons" 
                          style={{cursor: "pointer"}}
                          onClick={() => handleAmendmentPassOrFail(amendment._id, false)}
                          title={t('committee.chairAmendment.failAmendment')}>
                          highlight_off
                      </span>
                  </span>
                </span>
              <MDBDropdown dropright>
                <MDBDropdownToggle tag="div">
                  <span
                    className="material-icons unselectable"
                    style={{ cursor: "pointer", verticalAlign: "bottom" }}
                  >
                    more_vert
                  </span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem onClick={() => setAmendmentEnableEdit(true)}>
                    <span className="material-icons">create</span>
                    <span style={{ verticalAlign: "top", fontSize: "1.1rem" }}>
                      {t('committee.chairAmendment.edit')}
                    </span>
                  </MDBDropdownItem>
                  <MDBDropdownItem onClick={onAmendmentReject} disabled={!canReject}>
                    <span className="material-icons">backspace</span>
                    <span style={{ verticalAlign: "top", fontSize: "1.1rem" }}>
                      {t('committee.chairAmendment.reject')}
                    </span>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          ) : null}
          {isChair ? null :
            <div className="d-flex">
              <div className="col align-content-center">
                <div className="d-inline">
                  <span
                    className="badge badge-info align-bottom"
                    hidden={!(ownAmentment && amendment.isPublic)}
                  >
                    Ready
                  </span>
                </div>
                <h4
                  className="mb-0"
                  onClick={() => { onAmendmentRetrieve(amendment) }}
                  style={{ cursor: "pointer", color: "black" }}
                  title="Retrieve Amendment"
                  hidden={!canRetrieve}
                >
                  <span className="material-icons">delete_sweep</span>
                </h4>
              </div>
            </div>
          }
        </div>
        <h6 className="card-title">
          {isSecondaryAmendment ? (
            <>
              {t("committee.amendment.secondaryAmendmentOf")}{" "}
              <span className="badge badge-primary">
                {renderAmendmentDisplayText(amendment.target, {
                  language: i18n.language,
                  displayCountryName: true,
                })}
              </span>
            </>
          ) : (
            renderAmendmentDisplayText(amendment, { language: i18n.language })
          )}
        </h6>
        <p className="card-text mb-1">
          <b>{t("committee.chairAmendment.submitter")}</b>: {renderRoleName(amendment.submitter)}
        </p>
        <p className="card-text mb-1">
          <b>{t("committee.chairAmendment.content")}</b>: <br />
          {(amendment.content || "").split("\n").map((v, i) => {
            return (
              <Fragment key={i}>
                {v}
                <br />
              </Fragment>
            )
          })}
        </p>
        {cmType === ruleOfProcedure.UNAUSA ? (
          <p className="card-text mb-1">
            <b>{t("committee.amendment.resolution")}</b>:{" "}
            {amendment && amendment.reso ? renderResolutionDisplayText(amendment.reso) : "N/A"}
          </p>
        ) : (
          <></>
        )}

        {isChair ? (
          amendmentEnableEdit ? (
            // edit amendment
            <EditAmendmentPart
              amendment={amendment}
              onClose={() => setAmendmentEnableEdit(false)}
            />
          ) : (
            <div className="d-flex justify-content-around flex-wrap">
              <div className="btn-group">
                <button
                  className={`btn btn-sm btn-${amendment.isPublic ? "blue-grey" : "warning"}`}
                  disabled={mutationLoading || (!isCurrentReso && cmType === ruleOfProcedure.THIMUN) || (hasSecondaryAmendments && amendment.isPublic)}
                  onClick={() => { handleAmendmentPublicOrPrivate(amendment._id, false) }}
                >
                  {t('committee.chairAmendment.makePrivate')}
                </button>
                <button
                  className={`btn btn-sm btn-${amendment.isPublic ? "primary" : "blue-grey"}`}
                  disabled={mutationLoading || (!isCurrentReso && cmType === ruleOfProcedure.THIMUN) || (hasSecondaryAmendments && amendment.isPublic)}
                  onClick={() => { handleAmendmentPublicOrPrivate(amendment._id, true) }}
                >
                  {t('committee.chairAmendment.makePublic')}
                </button>
              </div>
            </div>
          )
        ) : null}

        {isDelegate ? (
          <>
            {!isSecondaryAmendment && cmType === ruleOfProcedure.THIMUN ? (
              <button
                className="btn btn-sm btn-info"
                onClick={() => {
                  onSubmitSecondary(amendment)
                }}
              >
                {t("committee.amendment.submitSecondaryAmendment")}
              </button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}

export const ProcessedAmendment = ({ amendment }) => {
  const { t, i18n } = useTranslation()

  const colors = {
    [amendmentStatusCode.Passed]: "success",
    [amendmentStatusCode.NotPassed]: "danger",
    [amendmentStatusCode.Skipped]: "dark",
    [amendmentStatusCode.Retrieved]: "grey",
    [amendmentStatusCode.Rejected]: "danger",
  }

  const isSecondaryAmendment =
    amendment && amendment.target && amendment.target.__typename === "Amendment"

  return (
    <div className={"card border-" + (colors[amendment.amendmentStatus] || "")}>
      <div className="card-body">
        <h4 className="card-title">
          <span className={"badge badge-" + (colors[amendment.amendmentStatus] || "dark")}>
            {t(`committee.chairAmendment.${(amendment.amendmentStatus || "").toLowerCase()}`)}
          </span>
        </h4>
        <h6 className="card-title">
          {isSecondaryAmendment ? (
            <>
              {t("committee.amendment.secondaryAmendmentOf")}{" "}
              <span className="badge badge-primary">
                {renderAmendmentDisplayText(amendment.target, {
                  language: i18n.language,
                  displayCountryName: true,
                })}
              </span>
            </>
          ) : (
            renderAmendmentDisplayText(amendment, { language: i18n.language })
          )}
        </h6>
        <p className="card-text">
          <b>{t("committee.chairAmendment.submitter")}</b>: {renderRoleName(amendment.submitter)}
        </p>
        <p className="card-text">
          <b>{t("committee.chairAmendment.content")}</b>: <br />
          {(amendment.content || "").split("\n").map((v, i) => {
            return (
              <Fragment key={i}>
                {v}
                <br />
              </Fragment>
            )
          })}
        </p>

        {amendment.rejectReason ? (
          <div className="alert alert-warning">
            <b>Reject reason</b>: <br />
            {(amendment.rejectReason || "").split("\n").map((v, i) => {
              return (
                <Fragment key={i}>
                  {v}
                  <br />
                </Fragment>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function Amendment() {
  const { t } = useTranslation()

  const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), [])
  const this_CMID = me.roleTarget

  const isChair = me.role === roleCode.Chair

  const { committee, committeeOptions, loading: cmOptionsLoading } = useCommitteeOptions(this_CMID)

  const cmType = useMemo(() => (committee || {}).type, [committee])
  const {
    currentResolutionId,
    currentClause = {
      onUse: false,
      clause: "1",
    },
    isSC,
  } = committeeOptions

  const [selectedResoId, setselectedResoId] = useState(null)
  const isSelectIsCurrentReso = selectedResoId === currentResolutionId

  useEffect(() => {
    setselectedResoId(currentResolutionId)
  }, [currentResolutionId])

  const {
    resolution,
    amendments,
    loading: resolutionAmendmentsLoading,
    resolutions,
  } = useResolutionsAndAmendments(this_CMID, selectedResoId, false)
  const { cmAmendments } = useCommitteeAmendments(this_CMID)

  //Temporarily use only UNUSA and THIMUN ROP
  const displayAmendments = useMemo(() => {
    return (cmType === ruleOfProcedure.UNAUSA
      ? cmAmendments.filter((amendment) => !selectedResoId || (amendment.target.__typename === "Resolution" && amendment.target._id === selectedResoId))
      : amendments) || []
  }, [cmType, cmAmendments, amendments, selectedResoId])

  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const toggleSubmitModal = () => setOpenSubmitModal(!openSubmitModal)
  const [secondaryTarget, setSecondaryTarget] = useState(null)

  // retrieve amendment
  const [openRetrieveModal, setOpenRetrieveModal] = useState(false)
  const toggleRetrieveModal = () => setOpenRetrieveModal(!openRetrieveModal)
  const [retrieveTargetId, setRetrieveTargetId] = useState(null)
  const [mutateRetrieveAmendment, { loading: mutateRetrieveAmendmentLoading }] = useMutation(
    MUTATION_RETRIEVE_AMENDMENT
  )
  useEffect(() => {
    if (retrieveTargetId) {
      const retrieveTarget = displayAmendments.find(({ _id }) => _id === retrieveTargetId)

      if (
        retrieveTarget &&
        (retrieveTarget.isPublic ||
          (retrieveTarget.secondaryAmendments && retrieveTarget.secondaryAmendments.length > 0))
      ) {
        setRetrieveTargetId(null)
        setOpenRetrieveModal(false)
      }
    }
  }, [retrieveTargetId, displayAmendments])

  // reject amendment
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const toggleRejectModal = () => {
    setOpenRejectModal(!openRejectModal)
    setRejectTargetId(null)
    setRejectReason("")
  }
  const [rejectTargetId, setRejectTargetId] = useState(null)
  const [rejectReason, setRejectReason] = useState("")
  const [mutateRejectAmendment, { loading: mutateRejectAmendmentLoading }] = useMutation(
    MUTATION_REJECT_AMENDMENT
  )

  const [sortMode, setSortMode] = useAmendmentSortMode()

  useEffect(() => {
    if (rejectTargetId) {
      const rejectTarget = displayAmendments.find(({ _id }) => _id === rejectTargetId)

      if (
        rejectTarget &&
        (rejectTarget.isPublic ||
          (rejectTarget.secondaryAmendments && rejectTarget.secondaryAmendments.length > 0))
      ) {
        setRetrieveTargetId(null)
        setOpenRetrieveModal(false)
      }
    }
  }, [rejectTargetId, displayAmendments])

  useEffect(() => {
    if (!resolution && cmType === ruleOfProcedure.THIMUN) {
      setOpenSubmitModal(false)
    }
  }, [resolution, cmType])

  const [showFailedAmendments, setShowFailedAmendments] = useState(false)

  const mobileViewOpt = {
    Pending: 1,
    Processed: 2,
  }
  const [mobileViewSelected, setMobileViewSelected] = useState(mobileViewOpt.Pending)

  const loading = cmOptionsLoading || resolutionAmendmentsLoading

  const retrieveAmendmentModal = () => {
    const retrieveTarget = displayAmendments.find(({ _id }) => _id === retrieveTargetId)
    return retrieveTarget ? (
      <>
        <MDBModal size="md" isOpen={openRetrieveModal} toggle={toggleRetrieveModal}>
          <MDBModalHeader toggle={toggleRetrieveModal}>
            {t("committee.amendment.confirmRetrieveAmendment")}
          </MDBModalHeader>
          <MDBModalBody>
            <h5>
              {retrieveTarget.target ? (
                <span className="badge badge-primary">
                  {retrieveTarget.target.__typename === "Resolution"
                    ? renderResolutionDisplayText(retrieveTarget.target)
                    : renderAmendmentDisplayText(retrieveTarget.target)}
                </span>
              ) : null}{" "}
              {renderAmendmentDisplayText(retrieveTarget)}
            </h5>
            <p className="card-text mb-1">
              {(retrieveTarget.content || "").split("\n").map((v, i) => {
                return (
                  <Fragment key={i}>
                    {v}
                    <br />
                  </Fragment>
                )
              })}
            </p>
          </MDBModalBody>
          <MDBModalFooter>
            <fieldset disabled={mutateRetrieveAmendmentLoading}>
              <button
                className="btn btn-danger"
                onClick={() => {
                  mutateRetrieveAmendment({
                    variables: {
                      AID: retrieveTargetId,
                    },
                  }).then(() => {
                    setRetrieveTargetId(null)
                    setOpenRetrieveModal(false)
                    successNotify("Amendment retrieved")
                  })
                }}
              >
                {t("committee.amendment.retrieve")}
              </button>
              <button
                className="btn btn-dark"
                onClick={() => {
                  setRetrieveTargetId(null)
                  setOpenRetrieveModal(false)
                }}
              >
                {t("committee.amendment.cancel")}
              </button>
            </fieldset>
          </MDBModalFooter>
        </MDBModal>
      </>
    ) : null
  }

  const rejectAmendmentModal = () => {
    const rejectTarget = displayAmendments.find(({ _id }) => _id === rejectTargetId)
    return (
      <MDBModal size="md" isOpen={openRejectModal && rejectTarget} toggle={toggleRejectModal}>
        <MDBModalHeader toggle={toggleRejectModal}>Confirm Reject Amendment</MDBModalHeader>
        <MDBModalBody>
          {rejectTarget ? (
            <>
              <h5>
                {rejectTarget.target ? (
                  <span className="badge badge-primary">
                    {rejectTarget.target.__typename === "Resolution"
                      ? renderResolutionDisplayText(rejectTarget.target)
                      : renderAmendmentDisplayText(rejectTarget.target)}
                  </span>
                ) : null}{" "}
                {renderAmendmentDisplayText(rejectTarget)}
              </h5>
              <p className="card-text mb-1">
                {(rejectTarget.content || "").split("\n").map((v, i) => {
                  return (
                    <Fragment key={i}>
                      {v}
                      <br />
                    </Fragment>
                  )
                })}
              </p>

              <h5>
                <b>Reason</b>
              </h5>
              <textarea
                className="form-control"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
              ></textarea>
            </>
          ) : null}
        </MDBModalBody>
        <MDBModalFooter>
          <fieldset disabled={mutateRejectAmendmentLoading}>
            <button
              className="btn btn-danger"
              onClick={() => {
                mutateRejectAmendment({
                  variables: {
                    AID: rejectTargetId,
                    reason: rejectReason,
                  },
                }).then(() => {
                  setRejectTargetId(null)
                  setOpenRejectModal(false)
                  setRejectReason("")
                  successNotify("Amendment rejected")
                })
              }}
            >
              Reject
            </button>
            <button className="btn btn-dark" onClick={toggleRejectModal}>
              {t("committee.amendment.cancel")}
            </button>
          </fieldset>
        </MDBModalFooter>
      </MDBModal>
    )
  }

  const amendmentSortFunc = (a, b) => {
    if (sortMode === "time") {
      return 0;
    }

    const process = (v) => {
      const clause = {
        clause: v.clause,
        subClause: v.subClause,
        subSubClause: v.subSubClause,
        isSecondary: false
      };

      if (v.target && v.target.__typename === "Amendment") {
        clause.isSecondary = true;
        clause.clause = v.target.clause;
        clause.subClause = v.target.subClause;
        clause.subSubClause = v.target.subSubClause;
      }

      return clause;
    }

    const aClause = process(a);
    const bClause = process(b);

    

    return aClause.clause - bClause.clause ||
      aClause.subClause - bClause.subClause ||
      aClause.subSubClause - bClause.subSubClause ||
      aClause.isSecondary - bClause.isSecondary
  }

  return (
    <div className="container">
      <div className="row py-4">
        <div className="col-auto col-md-6">
          {!loading && cmType === ruleOfProcedure.THIMUN ? (
            <h1 className="mb-0" style={{ maxWidth: "100%" }}>
              {currentClause && currentClause.onUse && currentClause.clause && isSC ? (
                <span className="badge badge-primary">
                  Clause {parseInt(currentClause.clause) + 1}
                </span>
              ) : resolution ? (
                <>
                  {resolution.number ? "" : `${t("resolution")}: `}
                  <span className="badge badge-primary text-truncate" style={{ maxWidth: "100%" }}>
                    {renderResolutionDisplayText(resolution)}
                  </span>
                </>
              ) : (
                <span className="badge badge-warning">
                  {t("committee.amendment.noCurrentReso")}
                </span>
              )}
            </h1>
          ) : null}
        </div>

        <div className="col col-md-6 text-right">
          {!isChair ? (
            <button
              className="btn btn-sm btn-info"
              disabled={!resolution && cmType === ruleOfProcedure.THIMUN}
              onClick={() => {
                setOpenSubmitModal(true)
                setSecondaryTarget(null)
              }}
            >
              {t("committee.amendment.submitAmendment")}
            </button>
          ) : null}

          {isChair ? (
            <select
              className="form-control"
              value={selectedResoId || "none"}
              onChange={(v) => setselectedResoId(v.target.value === "none" ? null : v.target.value)}
            >
              <option value="none" disabled={!!currentResolutionId}>
                {cmType === ruleOfProcedure.THIMUN ? "None" : "All"}
              </option>
              {resolutions
                ? resolutions.map((v) => {
                  return (
                    <option key={v._id} value={v._id}>
                      {renderResolutionDisplayText(v)}
                      {currentResolutionId === v._id ? " (current)" : ""}
                    </option>
                  )
                })
                : null}
            </select>
          ) : null}

          <select
            className="form-control mt-2"
            value={sortMode}
            onChange={(v) => setSortMode(v.target.value)}
          >
            <option value="time">Sort by Time</option>
            <option value="clause">Sort by Clause Number</option>
          </select>
        </div>
      </div>

      {!loading ? (
        <Fragment>
          {/* mobile amendment select */}
          <div className="row mb-2 mx-1 d-md-none">
            <div className="col-6 px-1">
              <button
                onClick={() => setMobileViewSelected(mobileViewOpt.Pending)}
                className={`btn btn-block btn-sm btn-${mobileViewSelected === mobileViewOpt.Pending ? "primary" : "blue-grey"
                  }`}
              >
                {t("committee.amendment.pending")}
              </button>
            </div>
            <div className="col-6 px-1">
              <button
                onClick={() => setMobileViewSelected(mobileViewOpt.Processed)}
                className={`btn btn-block btn-sm btn-${mobileViewSelected === mobileViewOpt.Processed ? "primary" : "blue-grey"
                  }`}
              >
                {t("committee.amendment.processed")}
              </button>
            </div>
          </div>

          <div className="row">
            <div
              className={
                "col-12 col-md-6 d-md-block" +
                (mobileViewSelected !== mobileViewOpt.Pending ? " d-none" : "")
              }
            >
              <h2 className="mb-3">
                <strong>{t("committee.chairAmendment.pending")}</strong>
              </h2>
              <div className="card-columns amendment-columns">
                {displayAmendments
                  ? displayAmendments
                    .filter((v) => v.amendmentStatus === amendmentStatusCode.Pending && (v.isPublic || isChair || v.submitter._id === me._id))
                    .sort(amendmentSortFunc)
                    .map((amendment) => (
                      <PendingAmendment
                        key={amendment._id}
                        amendment={amendment}
                        cmType={cmType}
                        isDelegate={!isChair}
                        isChair={isChair}
                        isCurrentReso={isSelectIsCurrentReso}
                        ownAmentment={amendment.submitter._id === me._id}
                        canRetrieve={
                          amendment.submitter._id === me._id &&
                          !amendment.isPublic &&
                          !(
                            amendment.secondaryAmendments &&
                            amendment.secondaryAmendments.length > 0
                          )
                        }
                        canReject={
                          !amendment.isPublic &&
                          !(
                            amendment.secondaryAmendments &&
                            amendment.secondaryAmendments.length > 0
                          )
                        }
                        onSubmitSecondary={(amendment) => {
                          setSecondaryTarget(amendment)
                          setOpenSubmitModal(true)
                        }}
                        onAmendmentRetrieve={(amendment) => {
                          setRetrieveTargetId(amendment._id)
                          setOpenRetrieveModal(true)
                        }}
                        onAmendmentReject={() => {
                          setRejectTargetId(amendment._id)
                          setOpenRejectModal(true)
                          setRejectReason("")
                        }}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div
              className={
                "col-12 col-md-6 d-md-block" +
                (mobileViewSelected !== mobileViewOpt.Processed ? " d-none" : "")
              }
            >
              <div className="row justify-content-around">
                <div className="col-12 col-sm-auto order-2 order-sm-1">
                  <h2 className="mb-3">
                    <strong>{t("committee.chairAmendment.processed")}</strong>
                  </h2>
                </div>
                <div className="col text-right order-1 order-sm-2">
                  <button
                    className={`btn text-nowarp btn-sm btn-${showFailedAmendments ? "warning" : "dark"
                      }`}
                    onClick={() => setShowFailedAmendments(!showFailedAmendments)}
                  >
                    {showFailedAmendments
                      ? t("committee.chairAmendment.hideFailed")
                      : t("committee.chairAmendment.showAll")}
                  </button>
                </div>
              </div>
              <div className="card-columns amendment-columns">
                {displayAmendments
                  ? displayAmendments
                    .filter((v) => {
                      if (v.amendmentStatus === amendmentStatusCode.Pending) {
                        return false
                      }

                      if (!isChair && !v.isPublic && v.submitter._id !== me._id) {
                        return false;
                      }

                      if (!showFailedAmendments) {
                        if (
                          v.amendmentStatus === amendmentStatusCode.Passed &&
                          v.target.__typename === "Amendment"
                        ) {
                          return v.target.amendmentStatus !== amendmentStatusCode.NotPassed
                        } else if (v.amendmentStatus !== amendmentStatusCode.Passed) {
                          return false
                        }
                      }

                      return true
                    })
                    .sort(amendmentSortFunc)
                    .map((amendment) => (
                      <ProcessedAmendment
                        key={amendment._id}
                        amendment={amendment}
                        meId={me._id}
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="text-center mt-10">
          <div className="spinner-border text-dark">
            <span className="sr-only">{t("loading")}</span>
          </div>
        </div>
      )}

      <MDBModal size="lg" isOpen={openSubmitModal} toggle={toggleSubmitModal}>
        <MDBModalHeader toggle={toggleSubmitModal}>
          {t("committee.amendment.submitAmendment")}
        </MDBModalHeader>
        <MDBModalBody>
          <SubmitAmendmentForm
            RESOID={(resolution || {})._id}
            CMID={this_CMID}
            RID={me._id}
            cmType={cmType}
            onSubmit={() => {
              toggleSubmitModal()
              setSecondaryTarget(null)
            }}
            secondaryTarget={secondaryTarget}
            currentClause={isSC ? currentClause : null}
          />
        </MDBModalBody>
      </MDBModal>

      {retrieveAmendmentModal()}
      {rejectAmendmentModal()}
    </div>
  )
}

export default Amendment
