import React, { useMemo, useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import * as jwt_decode from 'jwt-decode'

import { useQuery, useMutation } from 'react-apollo'
import { QUERY_FETCH_COMMITTEE_ROLES } from '../../../graphql/queries'
import { MUTATION_CREATE_ROLLCALL, MUTATION_DELETE_ROLLCALL } from '../../../graphql/mutations'

import { roleField, roleCode } from '../../../constant'
import { successNotify } from '../../modules/notification'
import { sortCommitteeRoles } from '../../modules/util'
import EditRollcall from './rollcall/EditRollcall'
import RollCallStat from './rollcall/RollCallStat'
import RollCallDetail from './rollcall/RollCallDetail'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'

import useCommitteeRollCall from './hooks/useCommitteeRollCall'
import { useTranslation } from 'react-i18next'

const prePath = "/committee/attendance";

function RollCall() {
    const { t } = useTranslation();
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const history = useHistory();

    const { data: fetchRoleData, loading: fetchRoleLoading } = useQuery(QUERY_FETCH_COMMITTEE_ROLES, {
        variables: {
            CMID: this_CMID
        },
        fetchPolicy: "network-only"
    });

    const committeeRoles = (fetchRoleData && fetchRoleData.committee && fetchRoleData.committee.roles ? fetchRoleData.committee.roles : []).sort(sortCommitteeRoles).filter((v) => v.role === roleCode.Delegate);

    // rollcall
    const { rollCalls, lastRollCall, loading: committeeRollCallLoading } = useCommitteeRollCall(this_CMID);

    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        if (lastRollCall && firstLoad) {
            setSelectedRollCallId(lastRollCall._id);
            setFirstLoad(false);
        }
    }, [lastRollCall, firstLoad]);

    const [selectedRollCallId, setSelectedRollCallId] = useState(null);
    const selectedRollCall = rollCalls ? rollCalls.find((v) => v._id === selectedRollCallId) : null;

    // useEffect(() => {
    //     if (!selectedRollCall) setSelectedRollCallId(null);
    // }, [selectedRollCall]);

    const [mutateCreateRollCall, { loading: mutateCreateRollCallLoading }] = useMutation(MUTATION_CREATE_ROLLCALL, {
        variables: {
            CMID: this_CMID,
            attendee: {}
        },
        context: {
            debounceKey: `createRollCall`,
            debounceTimeout: 500
        }
    });

    const [mutateDeleteRollCall, { loading: mutateDeleteRollCallLoading }] = useMutation(MUTATION_DELETE_ROLLCALL);

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const toggle = () => setShowDeleteConfirm(!showDeleteConfirm);

    const deleteRollCall = (id) => {
        mutateDeleteRollCall({
            variables: {
                RCID: id
            },
            context: {
                debounceKey: `deleteRollCall_${id}`,
                debounceTimeout: 500
            }
        }).then(() => {
            successNotify(t('committee.rollCall.deleted'));
            setShowDeleteConfirm(false);
        });
    }

    return (
        <div className="container-fluid">
            <div className="mx-5">
                <Switch>
                    <Route path={prePath} exact={true}>
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-3">
                                <div className="card">
                                    <div className="card-body" style={{ height: "calc(100vh - 175px)", overflowY: "auto" }}>
                                        <h5 className="card-title">{t('committee.rollCall.list')}</h5>
                                        <hr />
                                        <ul className="list-group">
                                            {
                                                rollCalls.map((data, index) => {
                                                    const { _id } = data;
                                                    return (
                                                        <li className={"list-group-item list-group-item-action " + (selectedRollCallId === _id ? "active" : "")}
                                                            key={index}
                                                            onClick={
                                                                () => {
                                                                    setSelectedRollCallId(_id);
                                                                }
                                                            }
                                                        >
                                                            <strong>#{rollCalls.length - index}</strong> - {new Date(data.time).toLocaleString()}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="text-right">
                                    <button className="btn btn-sm btn-primary" disabled={mutateCreateRollCallLoading} onClick={() => {
                                        mutateCreateRollCall({
                                            variables: {
                                                time: new Date()
                                            }
                                        }).then(({ data }) => {
                                            const id = data.createRollCall.rollCall._id;
                                            setSelectedRollCallId(id);
                                            history.push(prePath + "/edit/" + id);
                                        })
                                    }}>{t('committee.rollCall.new')}</button>

                                    {
                                        selectedRollCall ?
                                            <>
                                                <button className="btn btn-sm btn-warning" onClick={() => {
                                                    history.push(prePath + "/edit/" + selectedRollCall._id);
                                                }}>{t('committee.rollCall.edit')}</button>

                                                <button className="btn btn-sm btn-dark" onClick={() => {
                                                    history.push(prePath + "/detail/" + selectedRollCall._id);
                                                }}>{t('committee.rollCall.detail')}</button>

                                                <button className="btn btn-sm btn-danger" onClick={toggle}>{t('committee.rollCall.delete')}</button>
                                            </> : null
                                    }

                                </div>

                                {committeeRollCallLoading ?
                                    <div className="mt-4 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">{t('loading')}</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="mt-4">
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <div className="card">
                                                    <div className="card-body">

                                                        {selectedRollCall ?
                                                            <>
                                                                <h3 className="card-title">{t('committee.rollCall.stat')} #{rollCalls.length - rollCalls.findIndex((e) => {
                                                                    return e && selectedRollCall && e._id === selectedRollCall._id;
                                                                })}</h3>
                                                                <hr />
                                                                <RollCallStat lastRollCall={selectedRollCall} />
                                                            </> :
                                                            <>
                                                                <h4>{t('committee.rollCall.noData')}</h4>
                                                                <p>Select one on the left or create one.</p>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <MDBModal isOpen={showDeleteConfirm} toggle={toggle}>
                            <MDBModalHeader toggle={toggle}>
                                {t('committee.rollCall.confirm')} #{rollCalls.length - rollCalls.findIndex((e) => {
                                    return e && selectedRollCall && e._id === selectedRollCall._id;
                                })}
                            </MDBModalHeader>
                            <MDBModalBody>
                                {selectedRollCall ?
                                    <RollCallStat lastRollCall={selectedRollCall} /> :
                                    null
                                }
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button className="btn btn-danger" disabled={mutateDeleteRollCallLoading} onClick={() => deleteRollCall(selectedRollCall._id)}>{t('committee.rollCall.delete')}</button>
                                <button className="btn btn-dark" onClick={toggle}>{t('committee.rollCall.cancel')}</button>
                            </MDBModalFooter>
                        </MDBModal>
                    </Route>

                    <Route path={prePath + "/edit/:id"}>
                        <EditRollcall committeeRoles={committeeRoles} committeeRolesLoading={fetchRoleLoading} rollCallDocs={rollCalls} />
                    </Route>

                    <Route path={prePath + "/detail/:id"}>
                        <RollCallDetail committeeRoles={committeeRoles} rollCallDocs={rollCalls} />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default RollCall
