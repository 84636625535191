import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import * as jwt_decode from "jwt-decode"
import { QUERY_FETCH_ROLES } from '../../graphql/queries'
import { MUTATION_ROLE_LOGIN } from '../../graphql/mutations'
import { tokenField, roleField, itemStatusCode } from '../../constant'
import { errorNotify } from '../modules/notification'
import { renderRoleName } from '../modules/util'

class RoleSelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            loading: true,
            isLoginCode: false
        }
    }

    componentDidMount() {
        let usid;

        if (localStorage.getItem(tokenField)) {
            try {
                usid = jwt_decode(localStorage.getItem(tokenField))._id;
            } catch (error) {
                console.error(error);
            }
        }

        if (usid) {
            if (usid !== "303030303030303031303030") {
                this.props.client.query({
                    query: QUERY_FETCH_ROLES,
                    variables: { USID: usid },
                    fetchPolicy: "network-only"
                }).then(({ data }) => {
                    if (data && data.user && data.user.roles !== this.state.roles) {
                        const roles = data.user.roles.filter((v) => v.status === itemStatusCode.Active);

                        this.setState({
                            roles,
                            loading: false
                        });
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    isLoginCode: true
                });
            }
        }
        else {
            errorNotify("Unauthorized", "You need to login first!!");
        }
    }

    roleSelected(_id) {
        this.props.client.mutate({
            mutation: MUTATION_ROLE_LOGIN,
            variables: { RID: _id }
        }).then((data) => {
            localStorage.setItem(roleField, data.data.roleLogin.token);
            window.location.href = "/";
        })
    }

    renderRole(me) {
        const { role, status, _id, roleTarget } = me;
        const roleCardColor = {
            "Delegate": "info",
            "Admin": "danger",
            "SystemAdmin": "warning",
            "Chair": "primary",
            "Director": "primary",
        }

        const roleName = renderRoleName(me);

        // console.log(me, roleTarget);

        return (
            <div className={"card border-" + roleCardColor[role]} >
                <div className="card-body">
                    <h5 className="card-title">{roleName}</h5>
                    <p className="card-text">
                        <small className="text-muted">Id: {_id} </small>
                    </p>
                    <p className="card-text">{roleName}</p>
                    {roleTarget && roleTarget.__typename === "Committee" ? <p className="card-text">
                        {roleTarget.name}{roleTarget.conference ? ` - ${roleTarget.conference.name}` : ''}
                    </p> : null}
                    {roleTarget && roleTarget.__typename === "Conference" ? <p className="card-text">Conference: {roleTarget.name}</p> : null}
                    <button className={"btn btn-block btn-" + roleCardColor[role]} disabled={status === 0} onClick={() => { this.roleSelected(_id) }}>
                        {status === 0 ? "Role is inactive" : "Login As " + roleName}
                    </button>
                </div>
            </div>
        )
    }

    render() {
        let formatedRoles = [];
        let adminRoles = [];
        let delegateRoles = [];
        let systemAdminRoles = [];
        let chairRoles = [];
        let directorRoles = [];

        systemAdminRoles = this.state.roles
            .filter((role) => {
                return role.role === "SystemAdmin"
            })

        adminRoles = this.state.roles
            .filter((role) => {
                return role.role === "Admin"
            })

        delegateRoles = this.state.roles
            .filter((role) => {
                return role.role === "Delegate"
            })
            .sort((a, b) => {
                return a.detail.representative > b.detail.representative ? 1 : -1;
            })

        chairRoles = this.state.roles
            .filter((role) => {
                return role.role === "Chair"
            })

        directorRoles = this.state.roles
            .filter((role) => {
                return role.role === "Director"
            })

        delegateRoles.concat(adminRoles).concat(systemAdminRoles).concat(chairRoles).concat(directorRoles).forEach((value, i) => {
            // 3 roles in a row
            if (i % 3 === 0) {
                formatedRoles.push([]);
            }

            formatedRoles[Math.floor(i / 3)].push(value);
        });

        return (
            <div className="container">
                {
                    formatedRoles.length > 0 ?
                        formatedRoles.map((value, index) => {
                            return (
                                <div className="row mb-lg-3" key={index}>
                                    {
                                        value.map((value, index) => {
                                            return (
                                                <div className="col col-lg-4 mb-2" key={index}>
                                                    {this.renderRole(value)}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }) :
                        <div className="text-center">
                            <div className="mt-5">
                                {
                                    this.state.loading ?
                                        <div className="spinner-grow " role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.isLoginCode ?
                                            <>
                                                <h1><span className="badge badge-warning">Can't select role</span></h1>
                                                <h5 className="mt-5">
                                                    <span className="alert alert-info">You are login via a login code. Only user login with email can select a role.</span>
                                                </h5>
                                            </> :
                                            <h1><span className="badge badge-warning">No available roles</span></h1>
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default withApollo(withRouter(RoleSelect));