import React, { Component } from 'react'
import Slider from '../modules/Slider'

export class footStatusBar extends Component {
    render() {
        const { className, striped, animated } = this.props.progressProp;

        const { timerInUse, haveSpeaker, loading } = this.props;

        return (
            <div className="fixed-bottom bg-white mt-3 align-middle" id="footer">
                <div className="row d-none d-md-flex committee-foot">
                    {
                        !loading ?
                            <>
                                <div className="col">
                                    <h3>{this.props.leftText}</h3>
                                </div>
                                <div className="col text-center">
                                    <h2><strong>{this.props.title}</strong></h2>
                                </div>
                                <div className="col text-right align-text-bottom">
                                    <h5>
                                        {this.props.timer}
                                    </h5>
                                </div>
                            </> : null
                    }
                </div>

                <div className={"row d-md-none committee-foot justify-content-between" + (loading ? " bg-loading" : "")}>
                    {
                        !loading ? <>
                            {
                                haveSpeaker ?
                                    <>
                                        <div className="col text-center">
                                            <h3>{this.props.leftText}</h3>
                                        </div>
                                        <div className="col text-center">
                                            <h3>{this.props.timer}</h3>
                                        </div>
                                    </>
                                    :
                                    <Slider slideInterval={2000}>
                                        {
                                            this.props.leftText ?
                                                <div className="col text-center">
                                                    <h3>{this.props.leftText}</h3>
                                                </div> :
                                                null
                                        }
                                        <div className="col text-center">
                                            <h3><strong>{this.props.title}</strong></h3>
                                        </div>
                                    </Slider>
                            }
                        </> : null
                    }
                </div>
                {
                    timerInUse && !haveSpeaker ?
                        <>
                            <div className={"row d-md-none" + (loading ? " bg-loading" : "")}>
                                <div className="col-12 text-center">
                                    {!loading ? <h3>{this.props.timer}</h3> : null}
                                </div>
                            </div>
                        </>
                        : null
                }
                <div className="progress" style={{ width: "100vw", borderRadius: "0px" }}>
                    <div className={"progress-bar " + (striped ? "progress-bar-striped " : "") + (animated ? "progress-bar-animated " : "") + className} role="progressbar" style={{ width: (this.props.progress ? this.props.progress : "0") + "%" }}></div>
                </div>
            </div>
        )
    }
}

export default footStatusBar
