import { useEffect } from 'react'
import update from 'immutability-helper'

import { useQuery } from '@apollo/react-hooks'
import { QUERY_FETCH_COMMITTEE_ANNOUNCEMENTS } from '../../../../graphql/queries'
import { SUBSCRIPTION_ANNOUNCEMENT_POSTED, SUBSCRIPTION_ANNOUNCEMENT_DELETED } from '../../../../graphql/subscriptions'

const useAnnouncement = (CMID) => {
    const { data, loading, subscribeToMore } = useQuery(QUERY_FETCH_COMMITTEE_ANNOUNCEMENTS, {
        variables: {
            CMID
        },
        fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_ANNOUNCEMENT_POSTED,
            variables: { target: "committee", targetID: CMID },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.announcementPosted) {
                    const { announcementPosted } = data;

                    const { committee } = prev || {};
                    const announcements = committee.announcements ? [...committee.announcements] : [];

                    announcements.push(announcementPosted);

                    return {
                        committee: {
                            ...committee,
                            announcements
                        }
                    }
                }

                return prev;
            }
        });

        subscribeToMore({
            document: SUBSCRIPTION_ANNOUNCEMENT_DELETED,
            variables: { target: "committee", targetID: CMID },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.announcementDeleted) {
                    const { announcementDeleted } = data;

                    const { committee } = prev || {};
                    const announcements = committee.announcements ? [...committee.announcements] : [];

                    const index = announcements.findIndex((v) => announcementDeleted._id === v._id);

                    let updatedQuery = prev;
                    if (index !== -1) {
                        updatedQuery = update(prev, {
                            committee: {
                                announcements: {
                                    $splice: [[index, 1]]
                                }
                            }
                        })
                    }

                    return updatedQuery;
                }

                return prev;
            }
        })
    }, [CMID, subscribeToMore]);

    const announcements = (data && data.committee && data.committee.announcements) || [];

    return { announcements, loading };
}

export default useAnnouncement