import React from "react"
import { NavLink, Link } from "react-router-dom"
import * as jwt_decode from "jwt-decode"
import {
    roleField,
    tokenField,
    apolloCacheField,
    i18nextLngField,
    roleCode,
} from "../../../constant"
import { renderRoleName } from "../../modules/util"
import {
    MDBCollapse,
    MDBNavbarNav,
    MDBNavItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from "mdbreact"
import { useTranslation } from "react-i18next"
import useViewer from "../../pages/committee/hooks/useViewer"



const Links = (props) => {
    const { links, navOnlyLinks } = props
    const { i18n, t } = useTranslation()

    const { unreadNotes } = useViewer();

    let allLinks = []
    allLinks = [...allLinks, ...links]

    navOnlyLinks.forEach((element) => {
        const { prefix, routes } = element
        routes.forEach((r) => {
            const { path, link } = r

            const route = {
                ...r,
                path: Array.isArray(path)
                    ? path.map((v) => (prefix ? prefix + v : v))
                    : prefix
                        ? prefix + path
                        : path,
            }

            if (link) {
                route.link = prefix ? prefix + link : link
            }

            allLinks.push(route)
        })
    })


    let me, user

    if (localStorage.getItem(roleField)) {
        try {
            me = jwt_decode(localStorage.getItem(roleField))
            if (me && new Date(me.exp).getTime() - new Date().getTime() / 1000 < 0) {
                localStorage.removeItem(roleField)
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }

    if (localStorage.getItem(tokenField)) {
        try {
            user = jwt_decode(localStorage.getItem(tokenField))
            if (
                user &&
                new Date(user.exp).getTime() - new Date().getTime() / 1000 < 0
            ) {
                localStorage.removeItem(tokenField)
                localStorage.removeItem(roleField)
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const userLoggedIn = !!me || !!user

    const linkList = allLinks.map((route, i) => {
        const {
            path,
            link,
            name,
            permission,
            hideOnNav,
            denyPermission,
            exact,
            viewBadge,
        } = route

        const linkPath = link ? link : Array.isArray(path) ? path[0] : path

        const displayName = name
            ? typeof name === "string"
                ? name
                : name[i18n.language] || name["en"] || "n/a"
            : "n/a"

        const renderLink = (openInNew) => {
            const showUnreadBadge = viewBadge && unreadNotes && unreadNotes.length > 0;

            return (
                <MDBNavItem key={i} className="nav-item-padding">
                    {openInNew ? (
                        <a
                            className={`nav-link ${showUnreadBadge ? "pr-1" : ""}`}
                            href={linkPath}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {displayName}
                        </a>
                    ) : (
                            <NavLink
                                className={`nav-link ${showUnreadBadge ? "pr-1" : ""}`}
                                style={{ display: "inline" }}
                                activeClassName="nav-link font-weight-bolder"
                                to={linkPath}
                                exact={exact}
                            >
                                {displayName}
                            </NavLink>
                        )}
                    {
                        showUnreadBadge ? (
                            <span className="badge badge-pill badge-primary">{(unreadNotes && unreadNotes.length) || 0}</span>
                        ) : null
                    }
                </MDBNavItem>
            )
        }

        // Do route permission check here
        const role = me ? me.role : user ? roleCode.NoRole : roleCode.NoAuth

        if (
            !hideOnNav &&
            (!permission || permission.includes(role)) &&
            (!denyPermission || !denyPermission.includes(role))
        ) {
            return renderLink(route.openInNew)
        } else {
            return null
        }
    })

    const getName = () => {
        return me
            ? renderRoleName(me)
            : user
                ? user.firstName
                    ? user.firstName + (user.lastName ? " " + user.lastName : "")
                    : user._id
                : ""
    }

    const signOut = () => {
        localStorage.removeItem(roleField)
        localStorage.removeItem(tokenField)
        localStorage.removeItem(apolloCacheField)
        localStorage.removeItem(i18nextLngField)

        window.location.href = "/auth/signin"
    }

    const handleChangeLanguage = (language) => {
        i18n.changeLanguage(language)
        window.localStorage.setItem("i18nextLng", language)
    }

    const hideLangSelector = window.location.pathname.search("/committee") === 0

    return (
        <MDBCollapse isOpen={props.open} navbar>
            <MDBNavbarNav left>{linkList}</MDBNavbarNav>

            <MDBNavbarNav right>
                <div id="userBar">
                    <span
                        className={
                            "navbar-text badge mr-md-2 badge-" + (props.statusColor || "dark")
                        }
                        style={{
                            fontSize: "90%",
                            animation: "blinker 1s ease-out infinite",
                        }}
                    >
                        {props.statusText}
                    </span>

                    {!hideLangSelector ? (
                        <div className="d-inline-block mr-2">
                            <MDBDropdown size="sm">
                                <MDBDropdownToggle caret color="dark">
                                    {t("currentLanguage")}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic>
                                    <MDBDropdownItem onClick={() => handleChangeLanguage("en")}>
                                        English
                  </MDBDropdownItem>
                                    {/* <MDBDropdownItem
                    onClick={() => handleChangeLanguage("en_TWMAS")}
                  >
                    English(TWMAS)
                  </MDBDropdownItem> */}
                                    <MDBDropdownItem onClick={() => handleChangeLanguage("zh")}>
                                        繁體中文
                  </MDBDropdownItem>
                                    {/* <MDBDropdownItem
                    onClick={() => handleChangeLanguage("zh_TransMUN")}
                  >
                    繁體中文(立法院)
                  </MDBDropdownItem> */}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                    ) : null}

                    {userLoggedIn ? (
                        <span className="navbar-text text-dark mr-md-2" id="navUser">
                            <NavLink to="/auth/roles">
                                <span
                                    className="badge badge-pill badge-primary close-nav"
                                    style={{ fontSize: "90%" }}
                                >
                                    {getName()}
                                </span>
                            </NavLink>
                        </span>
                    ) : null}
                </div>
                {userLoggedIn ? (
                    <button className="btn btn-outline-dark" onClick={signOut}>
                        {" "}
                        {t("navBar.signOut")}{" "}
                    </button>
                ) : (
                        <Link className="btn btn-outline-dark" to="/auth/signin">
                            {" "}
                            {t("navBar.signIn")}{" "}
                        </Link>
                    )}
            </MDBNavbarNav>
        </MDBCollapse>
    )
}

export default Links
