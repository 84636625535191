import React from 'react'
import flags from './flags_data.json'

const data = {};

flags.forEach(element => {
    data[element.code] = 'https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg' + element.image;
});

export default function countryFlags(props) {
    let fillteredProp = {
        ...props
    }

    delete fillteredProp.country;
    delete fillteredProp.fallback;
    delete fillteredProp.src;

    return (
        <img
            src={props.src || (data[props.country] || props.fallback)}
            alt={props.alt || props.country}
            {...fillteredProp}
        />
    )
}
