import React, { useDebugValue } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'

import { QUERY_FETCH_AMENDMENT, QUERY_FETCH_RESOLUTION } from '../../../../graphql/queries'
import { MUTATION_UPDATE_COMMITTEE_OPTIONS, MUTATION_END_VOTE, MUTATION_EDIT_AMENDMENT, MUTATION_EDIT_RESOLUTION } from '../../../../graphql/mutations'

import { amendmentStatusCode, resolutionStatusCode, attendStatusCode } from '../../../../constant'
import { errorNotify, successNotify } from '../../../modules/notification'
import { useTranslation } from 'react-i18next'

const useGetLinked = (link) => {

    let queryOpt = {
        id: "",
        skipResolution: true,
        skipAmendment: true,
        type: "none"
    }

    if (link) {
        if (link.type === "resolution") {
            queryOpt.skipResolution = false;
        } else if (link.type === "amendment") {
            queryOpt.skipAmendment = false;
        }

        queryOpt.id = link.targetId;
        queryOpt.type = link.type;
    }

    const { data: resoData, loading: resoLoading } = useQuery(QUERY_FETCH_RESOLUTION, {
        variables: {
            RESOID: queryOpt.id
        },
        fetchPolicy: "network-only",
        skip: queryOpt.skipResolution,
        pollInterval: queryOpt.skipResolution ? 0 : 1000
    });

    const { data: amData, loading: amLoading } = useQuery(QUERY_FETCH_AMENDMENT, {
        variables: {
            AID: queryOpt.id
        },
        fetchPolicy: "network-only",
        skip: queryOpt.skipAmendment,
        pollInterval: queryOpt.skipAmendment ? 0 : 1000
    });

    const [editReso] = useMutation(MUTATION_EDIT_RESOLUTION, {
        variables: { RESOID: queryOpt.id },
        context: {
            debounceKey: `editReso_${queryOpt.id}`,
            debounceTimeout: 500
        }
    });

    const [editAm] = useMutation(MUTATION_EDIT_AMENDMENT, {
        variables: { AID: queryOpt.id },
        context: {
            debounceKey: `editAm_${queryOpt.id}`,
            debounceTimeout: 500
        }
    });

    useDebugValue(queryOpt);

    const setPass = (pass) => {
        if (queryOpt.type === "resolution") {
            return editReso({
                variables: {
                    status: pass ? resolutionStatusCode.Passed : resolutionStatusCode.NotPassed
                }
            })
        } else if (queryOpt.type === "amendment") {
            return editAm({
                variables: {
                    status: pass ? amendmentStatusCode.Passed : amendmentStatusCode.NotPassed
                }
            })
        }

        return Promise.reject();
    }

    const { resolution } = resoData || {};
    const { amendment } = amData || {};

    return { type: queryOpt.type, v: queryOpt.skipResolution === false ? resolution : (queryOpt.skipAmendment === false ? amendment : null), loading: resoLoading || amLoading, setPass };
}

function EndVoteModal({ show, toggle = () => { }, CMID, currentVote, voteStat, onEndVote = () => { }, lastRollCall }) {

    const { t } = useTranslation()
    // end vote
    const [endVoteMutate, { loading: endVoteMutateLoading }] = useMutation(MUTATION_END_VOTE, {
        variables: {
            VID: currentVote ? currentVote._id : ""
        },
        context: {
            debounceKey: `endVote_${currentVote ? currentVote._id : ""}`,
            debounceTimeout: 500
        }
    });

    // edit committee
    const [updateCommittee] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID
        }
    })

    const setCurrentVote = (voteId) => {
        return updateCommittee({
            variables: {
                options: {
                    vote: {
                        currentVoteId: voteId
                    }
                }
            }
        });
    }

    const { topic, link } = currentVote || {};

    const { type: linkedType, loading: linkedLoading, v: linked, setPass: setLinkedPass } = useGetLinked(link);

    const renderPassingMajority = () => {
        if (!lastRollCall || !lastRollCall.attendee) {
            return <span className="badge badge-dark">No last rollcall</span>;
        } else {
            let presentCount = 0;

            const keys = Object.keys(lastRollCall.attendee);
            for (let idx = 0; idx < keys.length; idx++) {
                const element = lastRollCall.attendee[keys[idx]];
                if (element === attendStatusCode.Present || element === attendStatusCode.PresentAndVoting) {
                    presentCount++;
                }
            }

            const passSimple = voteStat.voteFor > voteStat.voteAgainst;
            const passTwoThird = voteStat.voteFor * 3 >= presentCount * 2; // don't use divide prevent floating point problem

            const maj = () => <span className="badge badge-primary"> {t('committee.chairDash.vote.simpleMajority')} </span>
            const twoThird = () => <span className="badge badge-primary"> {t('committee.chairDash.vote.twoThirdMajority')} </span>

            if (passSimple && passTwoThird) {
                return <> {maj()} {twoThird()} </>;
            } else if (passSimple) {
                return maj();
            } else if (passTwoThird) {
                return twoThird();
            } else {
                return <span className="badge badge-dark"> {t('committee.chairDash.vote.none')} </span>;
            }
        }
    }

    const handleLinkedEndVote = (pass) => {
        endVoteMutate()
            .then(() => setLinkedPass(pass))
            .then(() => setCurrentVote(null))
            .then(() => {
                successNotify(t('notifications.vote.success.voteEndedAndThe') + ` ${linkedType === "resolution" ? t('resolution') : t('amendment')} ${pass ? t('committee.chairDash.vote.passed') : t('committee.chairDash.vote.failed')}.`);
                toggle();
                onEndVote();
            })
            .catch((err) => {
                errorNotify(t('notifications.vote.error.whileEndingTheVote'), (typeof err) !== "object" ? err : JSON.stringify(err));
            });
    }

    return (
        <div>
            <MDBModal size="lg" isOpen={show} toggle={toggle}>
                <MDBModalHeader toggle={toggle}>
                    {t('committee.chairDash.vote.endVote')}
                </MDBModalHeader>
                <MDBModalBody>
                    <p> {t('committee.chairDash.vote.sentences.confirmEndVoteModalMsg')} </p>
                    <div className="row">
                        <div className="col"><h5> {t('committee.chairDash.vote.topic')} </h5></div>
                        <div className="col"><h5><span className="badge badge-pill badge-info">{topic}</span></h5></div>
                    </div>

                    {
                        voteStat ?
                            <>
                                <div className="row">
                                    <div className="col"><h5> {t('committee.chairDash.vote.for')} </h5></div>
                                    <div className="col"><h5><span className="badge badge-success">
                                        {voteStat.voteFor}
                                    </span></h5></div>
                                </div>

                                <div className="row">
                                    <div className="col"><h5> {t('committee.chairDash.vote.against')} </h5></div>
                                    <div className="col"><h5><span className="badge badge-danger">
                                        {voteStat.voteAgainst}
                                    </span></h5></div>
                                </div>

                                <div className="row">
                                    <div className="col"><h5> {t('committee.chairDash.vote.abstain')} </h5></div>
                                    <div className="col"><h5><span className="badge badge-warning">
                                        {voteStat.voteAbstain}
                                    </span></h5></div>
                                </div>

                                <hr />
                                <h5> {t('committee.chairDash.vote.sentences.thisVoteIsPassing')} </h5>
                                <h5>{renderPassingMajority()}</h5>
                            </> : null
                    }

                    <hr />

                    {linkedType === "resolution" || linkedType === "amendment" ?
                        !linkedLoading && linked ?
                            <>
                                <h5> {t('committee.chairDash.vote.sentences.thisVoteIsLinkedWithA')} {linkedType === "resolution" ? t('resolution') : t('amendment')}</h5>
                                <h5>
                                    {linkedType === "resolution" ?
                                        <>{t('resolution')}: <span className="badge badge-pill badge-info">{linked.topic}</span></>
                                        :
                                        <>{t('amendment')}: <span className="badge badge-pill badge-info">{linked.content}</span></>
                                    }
                                </h5>
                            </>
                            : null
                        : null}

                </MDBModalBody>
                <MDBModalFooter>
                    {linkedType === "resolution" || linkedType === "amendment" ?
                        <>
                            <button className="btn btn-success" disabled={endVoteMutateLoading} onClick={() => handleLinkedEndVote(true)}> {t('committee.chairDash.vote.sentences.endVoteAndPassThe')}{linkedType === "resolution" ? t('resolution') : t('amendment')}</button>
                            <button className="btn btn-danger" disabled={endVoteMutateLoading} onClick={() => handleLinkedEndVote(false)}> {t('committee.chairDash.vote.sentences.endVoteAndFailThe')}{linkedType === "resolution" ? t('resolution') : t('amendment')}</button>
                        </>
                        : null}
                    < button className="btn btn-warning" disabled={endVoteMutateLoading} onClick={() => {
                        endVoteMutate()
                            .then(() => setCurrentVote(null))
                            .then(() => {
                                successNotify(t('notifications.vote.success.voteEnded'));
                                toggle();
                                onEndVote();
                            })
                            .catch((err) => {
                                errorNotify(t('notifications.vote.error.whileEndingTheVote'), (typeof err) !== "object" ? err : JSON.stringify(err));
                            });
                    }}> {t('committee.chairDash.vote.endVote')} </button>
                </MDBModalFooter>
            </MDBModal>
        </div >
    )
}

export default EndVoteModal
