import React, { Component, Fragment } from 'react'

export class Linkify extends Component {
    constructor(props) {
        super(props);

        const LinkifyIt = require('linkify-it');

        this.linkify = new LinkifyIt();
    }

    render() {
        const { children } = this.props;

        const match = this.linkify.match(children);
        let lastI = 0;

        return match ? match.map((v, i) => {
            const appendString = children.substring(lastI, v.index);
            lastI = v.lastIndex;
            return <Fragment key={i}>
                {appendString}
                <a href={v.url} target="_blank" rel="noopener noreferrer">{v.text}</a>
            </Fragment>
        }) : children
    }
}

export default Linkify
