import { useEffect } from 'react'

import { useQuery } from 'react-apollo'
import useCommitteeOptions from './useCommitteeOptions'
import { QUERY_FETCH_VOTE } from '../../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_VOTE } from '../../../../graphql/subscriptions'

import { voteStatusCode } from '../../../../constant'

const useCommitteeVote = (CMID) => {
    const { committeeOptions, loading: cmLoading } = useCommitteeOptions(CMID);

    const { currentVoteId } = (committeeOptions && committeeOptions.vote) || {};

    const { data: currentVoteData, loading: currentVoteLoading, subscribeToMore } = useQuery(QUERY_FETCH_VOTE, {
        variables: {
            VID: currentVoteId
        },
        fetchPolicy: "network-only",
        skip: !currentVoteId || cmLoading
    })

    useEffect(() => {
        if (currentVoteId) {
            subscribeToMore({
                document: SUBSCRIPTION_UPDATE_VOTE,
                variables: {
                    CMID,
                    VID: currentVoteId
                },
                updateQuery(prev, { subscriptionData: { data } }) {
                    if (data && data.voteUpdated) {
                        const { voteUpdated } = data;
                        return {
                            ...prev,
                            vote: voteUpdated
                        };
                    }

                    return prev;
                }
            })
        }
    }, [currentVoteId, CMID, subscribeToMore]);

    const { vote: currentVote } = currentVoteData || {};

    const { result } = currentVote || {};

    let voteStat = {
        voteFor: 0,
        voteAgainst: 0,
        voteAbstain: 0
    }

    if (result) {
        let keys = Object.keys(result);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            const v = result[k];

            if (v === voteStatusCode.VoteFor) voteStat.voteFor++;
            else if (v === voteStatusCode.VoteAgainst) voteStat.voteAgainst++;
            else if (v === voteStatusCode.VoteAbstain) voteStat.voteAbstain++;
        }
    }


    return { currentVote, loading: currentVoteLoading || cmLoading, voteStat, currentVoteId };
}

export default useCommitteeVote