import React, { useState } from 'react'

import RollCallStat from '../rollcall/RollCallStat'
import EditRollcall from '../rollcall/EditRollcall'

import { useMutation } from '@apollo/react-hooks'
import { MUTATION_CREATE_ROLLCALL } from '../../../../graphql/mutations'

import useCommitteeRollCall from '../hooks/useCommitteeRollCall'

import './loading.css'

import { useTranslation } from 'react-i18next'

function RollCall({ CMID, lastRollCallUnfinished, committeeRoles, fetchRoleLoading }) {
    const { t } = useTranslation();
    const { lastRollCall, rollCalls, loading } = useCommitteeRollCall(CMID);

    const [editLast, setEditLast] = useState(false);

    const [mutateCreateRollCall, { loading: mutateCreateRollCallLoading }] = useMutation(MUTATION_CREATE_ROLLCALL, {
        variables: {
            CMID,
            attendee: {}
        },
        context: {
            debounceKey: `createRollCall`,
            debounceTimeout: 500
        }
    });

    return (
        <>
            {!(lastRollCallUnfinished || editLast) ?
                // show rollcall info
                <div className={"card" + (loading ? " loading" : "")}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 align-self-center"><h4 className="card-title mb-0">{t('committee.chairDash.rollCall.lastRollCall')}</h4></div>
                            <div className="col-6 text-right">
                                <button className="btn btn-sm btn-primary" disabled={mutateCreateRollCallLoading} onClick={() => {
                                    mutateCreateRollCall({
                                        variables: {
                                            time: new Date()
                                        }
                                    });
                                }}>{t('committee.chairDash.rollCall.new')}</button>
                                <button className="btn btn-sm btn-dark" onClick={() => setEditLast(true)}>{t('committee.chairDash.rollCall.edit')}</button>
                            </div>
                        </div>
                        {lastRollCall ? <RollCallStat lastRollCall={lastRollCall} /> :
                            !loading ? <div className="text-center">
                                <h1><span className="badge badge-warning">{t('committee.chairDash.rollCall.noLastRollCall')}</span></h1>
                            </div> : null
                        }
                    </div>
                </div> :
                // show rollcall edit panel
                <div className={"card" + (loading ? " loading" : "")} style={{ backgroundColor: "whitesmoke" }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 align-self-center"><h4 className="card-title mb-0">{t('committee.chairDash.rollCall.editRollCall')}</h4></div>
                            <div className="col-6 text-right">
                                {!lastRollCallUnfinished ?
                                    <button className="btn btn-sm btn-dark" onClick={() => setEditLast(false)}>{t('committee.chairDash.rollCall.exitEdit')}</button>
                                    : null
                                }
                            </div>
                        </div>
                        {
                            lastRollCall ?
                                <EditRollcall committeeRoles={committeeRoles} committeeRolesLoading={fetchRoleLoading}
                                    rollCallDocs={rollCalls} editId={lastRollCall._id} cardMode={true} />
                                : null
                        }

                    </div>
                </div>
            }
        </>
    )
}

export default RollCall
