import { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useCountdown, useTime } from "react-time-sync"

import useSyncedTime from './useSyncedTime'
import { timeTools } from '../../../modules/util'
import { QUERY_FETCH_COMMITTEE } from '../../../../graphql/queries'
import { MUTATION_UPDATE_COMMITTEE_OPTIONS } from '../../../../graphql/mutations'
import { SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS } from '../../../../graphql/subscriptions'

function useCommitteeTimer(CMID) {

    const syncedTime = useSyncedTime();

    const [updateCommittee, { loading }] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID
        }
    })

    const start = () => {
        let newTimer = {
            ...timer,
            pause: false,
        };

        if (!timer.startTime) {
            newTimer = {
                ...newTimer,
                startTime: syncedTime.getCurrentTime().getTime()
            };
        }

        return saveTimer(newTimer);
    }

    const stop = () => {
        let t = timer.timeLimit - (syncedTime.getCurrentTime() - new Date(timer.startTime));
        let newTimer = {
            ...timer,
            pause: true,
            timeLimit: t < 0 ? 0 : t,
            startTime: null
        };

        return saveTimer(newTimer);
    }

    const toggleStart = () => {
        if (timer.startTime && timer.pause === false) {
            stop();
        } else {
            start();
        }
    }

    const reset = (origTimeLimit) => {
        let newTimer = {
            ...timer,
            pause: false,
            timeLimit: origTimeLimit,
            origTimeLimit,
            startTime: null
        };

        return saveTimer(newTimer);
    }

    const setTimeLimit = ({ h, m, s }) => {
        const currentTimeLinit = msToHMSTime(timer.timeLimit);

        const newTimeLimitHMS = {
            ...currentTimeLinit,
            h,
            m,
            s
        };

        const newTimeLimit = (newTimeLimitHMS.h * 60 * 60 + newTimeLimitHMS.m * 60 + newTimeLimitHMS.s) * 1000;

        let newTimer = {
            ...timer,
            pause: false,
            startTime: null,
            timeLimit: newTimeLimit,
            origTimeLimit: newTimeLimit
        };

        return saveTimer(newTimer);
    }

    const setTimeLimitInMS = (ms) => setTimeLimit(msToHMSTime(ms));

    const { data, subscribeToMore } = useQuery(QUERY_FETCH_COMMITTEE, {
        variables: {
            CMID
        },
        fetchPolicy: "network-only"
    });

    const committee = data ? data.committee : null;

    const { options: cmOptions } = committee || {};
    const timer = cmOptions && cmOptions.timer ? cmOptions.timer : {};

    const msToHMSTime = (timeInMs) => {
        let HSMTime = {
            h: 0,
            m: 0,
            s: 0
        }


        if (timeInMs) {
            let t = timeTools.msToTimeObj(timeInMs);
            HSMTime.h = t.day * 24 + t.hour;
            HSMTime.m = t.min;
            HSMTime.s = t.sec;
        }

        return HSMTime;
    }

    const saveTimer = (timer) => {
        return updateCommittee({
            variables: {
                options: {
                    timer
                }
            }
        })
    }

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS,
            variables: { CMID },
            updateQuery: (prev, { subscriptionData: { data } }) => {
                if (data && data.committeeUpdated) {
                    if (data.committeeUpdated !== prev) {
                        return {
                            ...prev,
                            committee: {
                                ...prev.committee,
                                ...data.committeeUpdated
                            }
                        };
                    }
                }
                return prev;
            }
        })
    }, [subscribeToMore, CMID]);

    const timeLimit = timer ? timer.timeLimit : null;
    // 0: not started, 1: paused, 2: started
    const timerState = (timer && timer.pause ? 1 : (timer && timer.startTime ? 2 : 0));


    const timeLeft = useCountdown({ until: timerState === 2 ? timer.startTime + timer.timeLimit : 0 }) - (syncedTime.getCurrentTime() - new Date(Date.now())) / 1000;
    useTime();

    const remainTime = (() => {
        if (timerState === 2) {
            return timeLeft * 1000;
        } else {
            return timeLimit;
        }
    })();

    return { start, stop, reset, toggleStart, loading, setTimeLimit, setTimeLimitInMS, remainTime, timeLimit: timer ? timer.origTimeLimit : 0, msToHMSTime, timerState };
}

export default useCommitteeTimer;