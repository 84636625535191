import React, { Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { attendStatusCode } from '../../../../constant'
import { renderRoleName } from '../../../modules/util'

export function RollCallDetail({ committeeRoles, rollCallDocs }) {

    const { t } = useTranslation();
    const { id } = useParams();
    const renderSingleRole = (role, attendStatus) => {
        let color;
        if (attendStatus === attendStatusCode.Absent) {
            color = "warning";
        }
        else if (attendStatus === attendStatusCode.Present) {
            color = "info";
        } else if (attendStatus === attendStatusCode.PresentAndVoting) {
            color = "success";
        } else {
            color = "dark";
        }

        let attendStatusText = "";

        if (attendStatus === attendStatusCode.Absent) {
            attendStatusText = t('rollCall.absent');
        } else if (attendStatus === attendStatusCode.Present) {
            attendStatusText = t('rollCall.present');
        } else if (attendStatus === attendStatusCode.PresentAndVoting) {
            attendStatusText = t('rollCall.presentAndVoting');
        }

        return (
            <div className={"rounded border bg-white border-" + color}>
                <div className="row p-2 px-4">
                    <div className="col-6" style={{ alignSelf: "center" }}>
                        <h3>
                            {renderRoleName(role)}
                        </h3>
                    </div>
                    <div className="col-6 text-right" style={{ alignSelf: "center" }}>
                        <h2><span className={"ml-1 badge badge-" + color}>
                            {attendStatusText}
                        </span></h2>
                    </div>
                </div>
            </div>
        )
    }

    let rollCallDoc = null;

    if (!rollCallDoc && rollCallDocs && id) {
        rollCallDoc = rollCallDocs.find((e) => e._id === id);
    }


    return (
        <div className="row justify-content-center my-3">
            <div className="col col-md-10 col-lg-6">
                <Link className="btn btn-dark btn-sm mb-3" to="../">{t('committee.rollCallDetail.goBack')}</Link>

                {rollCallDoc ? <Fragment>
                    <h5>
                        {t('committee.rollCallDetail.time')}<span className="badge badge-primary">{new Date(rollCallDoc.time).toLocaleString()}</span>
                    </h5>
                    <h5>
                        {t('committee.rollCallDetail.committee')}<span className="badge badge-secondary">{rollCallDoc.committee ? rollCallDoc.committee.name : "n/a"}</span>
                    </h5>
                </Fragment> : null}

                {rollCallDoc && committeeRoles ?
                    committeeRoles.map((val, i) => {
                        const attendeeVal = rollCallDoc.attendee && rollCallDoc.attendee[val._id] !== undefined ? rollCallDoc.attendee[val._id] : null;

                        return (
                            <div className="my-3" key={i}>
                                {renderSingleRole(val, attendeeVal)}
                            </div>
                        )
                    }) :
                    <div className="text-center mt-5">
                        {
                            committeeRoles && committeeRoles.length > 0 && rollCallDocs && rollCallDocs.length > 0 ?
                                <h3>{t('committee.rollCallDetail.noRecord')}</h3> :
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">{t('loading')}</span>
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default withApollo(RollCallDetail);
