import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import Observable from 'zen-observable';

// css files
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import './index.css'

import * as Sentry from '@sentry/react'
import { tokenField, roleField } from './constant'
import { errorNotify } from './component/modules/notification'

// apollo and graphql
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import * as ApolloLink from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { persistCache } from 'apollo-cache-persist'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { RetryLink } from 'apollo-link-retry'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { WebSocketLink } from 'apollo-link-ws'
import DebounceLink from 'apollo-link-debounce'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'

// i18next
import i18next from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import common_en_US from './translations/en_US/common.json'
import common_zh_TW from './translations/zh_TW/common.json'

import App from './App'

import introspectionQueryResultData from './fragmentTypes.json'
import { QUERY_SYSTEM_PING } from './graphql/queries'
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: "https://45b720a432054e48b0b05b0c35c0ad6f@o263407.ingest.sentry.io/1837739",
    debug: process.env.NODE_ENV === 'development',
    environment: (process.env.NODE_ENV === 'development' ? "development" : "build"),
    release: (process.env.NODE_ENV === 'development' ? null : process.env.REACT_APP_GITHUB_SHA),
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ["asia.api.munative.com"],
        }),
    ],
});

const retry = new RetryLink({ attempts: { max: Infinity } })

let wsStatus = 0;
let pingStatus = 0

export function getSocketStatus() {
    return wsStatus;
}

export function getPingStatus() {
    return pingStatus;
}

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        lng: window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') : 'en',
        debug: true,
        interpolation: { escapeValue: false },
        fallbackLng: 'en',
        defaultNS: 'common',
        resources: {
            en: {
                common: common_en_US
            },
            zh: {
                common: common_zh_TW
            }
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

const DEFAULT_DEBOUNCE_TIMEOUT = 100;

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_URL,
    options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
            authorization: localStorage.getItem(tokenField) ? localStorage.getItem(tokenField) : "",
            role: localStorage.getItem(roleField) ? localStorage.getItem(roleField) : ""
        })
    }
})

wsLink.subscriptionClient.on("connecting", () => {
    wsStatus = -1;
});

wsLink.subscriptionClient.on("reconnecting", () => {
    wsStatus = -2;
});

wsLink.subscriptionClient.on("connected", () => {
    wsStatus = 1;
});

wsLink.subscriptionClient.on("reconnected", () => {
    wsStatus = 1;
});

wsLink.subscriptionClient.on("disconnected", () => {
    wsStatus = -3;
});

const httpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_API_URL
})

const authLink = setContext((_, { headers, isPingQuery }) => {
    if (isPingQuery) {  // don't use token when pinging server
        return {};
    }

    const token = localStorage.getItem(tokenField) ? localStorage.getItem(tokenField) : "";
    const rToken = localStorage.getItem(roleField) ? localStorage.getItem(roleField) : "";
    return {
        headers: {
            ...headers,
            authorization: token,
            role: rToken
        }
    }
})

let lastFocusTime = performance.now();

window.addEventListener('focus', () => lastFocusTime = performance.now());
window.addEventListener('blur', () => lastFocusTime = null);

const metricsLink = new ApolloLink.from([
    (operation, forward) => {

        const { operationName } = operation;
        const startTime = performance.now();
        const observable = forward(operation);

        const transaction = operationName !== "systemPingQuery" ? Sentry.startTransaction({
            name: `graphql-${operationName}`,
            op: "http",
        }) : null;

        // Return a new observable so no other links can call .subscribe on the
        // the one that we were passsed.
        return new Observable(observer => {
            observable.subscribe({
                complete: () => {
                    if (transaction) {
                        transaction.finish();
                    }

                    const elapsed = performance.now() - startTime;
                    if (operationName === "systemPingQuery" && lastFocusTime && startTime > lastFocusTime) {
                        pingStatus = elapsed;
                        if (pingStatus > 500 && !operation.getContext().isMetricsRetry) {
                            client.query({
                                query: QUERY_SYSTEM_PING, fetchPolicy: "network-only", context: {
                                    isMetricsRetry: true
                                }
                            });
                        }
                    }
                    observer.complete();
                },
                next: observer.next.bind(observer),
                error: error => {
                    // ...
                    observer.error(error);
                }
            })
        })
    }])

const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
        if (operation.getContext().bypOnError) return;
        if (graphQLErrors)
            graphQLErrors.forEach((err) => {
                if (err.extensions && (err.extensions.code === "UNAUTHENTICATED" || err.extensions.code === "FORBIDDEN" || err.extensions.code === "BAD_USER_INPUT")) {
                    // errorNotify(err.extensions.code, `Message: ${err.message}\nPath: ${JSON.stringify(err.path)}`);
                    console.log(err.extensions.code, err);
                } else {
                    const error = new Error(JSON.stringify(err));
                    error.name = "GraphQLError";
                    errorNotify(error.name, error.message);
                    console.error(error);
                    Sentry.captureException(error);
                }
            });
        if (networkError) {
            if (networkError instanceof Error) {
                if (networkError.statusCode !== 400) {
                    errorNotify("Network error", networkError);
                }
                console.log(`[Network error]: ${networkError}`);
            }
            else {
                console.log(`[Network error]: ${JSON.stringify(networkError)}`);
            }
        }
    }),
    ApolloLink.split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        retry.concat(authLink.concat(metricsLink.concat(ApolloLink.from([new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT), httpLink]))))
    ),
]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const cache = new InMemoryCache({
    fragmentMatcher
});

persistCache({
    cache,
    storage: localStorage
})

if (localStorage['apollo-cache-persist']) {
    let cacheData = JSON.parse(localStorage['apollo-cache-persist'])
    cache.restore(cacheData)
}

const client = new ApolloClient({
    cache,
    link
});

const app = (
    <I18nextProvider i18n={i18next}>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </I18nextProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
