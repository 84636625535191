import React, { useState, useMemo, useEffect, forwardRef } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'
import FlipMove from 'react-flip-move'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { QUERY_FETCH_PENDING_NOTES } from '../../../graphql/queries'
import { MUTATION_REVIEW_NOTE } from '../../../graphql/mutations'
import { SUBSCRIPTION_RECEIVED_PENDING_NOTE, SUBSCRIPTION_RESOLVED_PENDING_NOTE } from '../../../graphql/subscriptions'
import { roleField } from '../../../constant'
import { renderRoleName } from '../../modules/util'

import useCommitteeOptions from './hooks/useCommitteeOptions'

const PendingNote = forwardRef(({ note, reviewNoteLoading, onApproveClick = () => { }, onRejectCick = () => { } }, ref) => {
    const { t } = useTranslation();

    return (
        <div className="card" ref={ref}>
            <div className="card-body">
                <h5 className="card-title">{t('committee.noteApprove.sender')}: <span className="badge badge-info">{renderRoleName(note.sender)}</span></h5>
                <h5 className="card-title">{t('committee.noteApprove.receiver')}: <span className="badge badge-info">{renderRoleName(note.receiver)}</span></h5>
                <hr />
                <h5>{t('committee.noteApprove.message')}:</h5>
                <p>
                    {note && note.content}
                </p>

                <div className="d-inline">
                    <button
                        className="btn btn-sm btn-success"
                        disabled={reviewNoteLoading}
                        onClick={onApproveClick}
                    >{t('committee.noteApprove.approve')}</button>
                    <button
                        className="btn btn-sm btn-danger"
                        disabled={reviewNoteLoading}
                        onClick={onRejectCick}
                    >{t('committee.noteApprove.reject')}</button>
                </div>
                <p className="d-inline text-muted align-middle">{note ? new Date(note.sendTime).toLocaleTimeString() : "n/a"}</p>
            </div>
        </div >
    )
})

const NoteRejectModal = ({ isOpen, note, reviewNoteLoading, markNoteRejected = () => Promise.resolve(), toggle = () => { } }) => {
    const { t } = useTranslation();

    const [rejectReason, setRejectReason] = useState("");

    return <>
        {note ?
            <MDBModal toggle={toggle} isOpen={isOpen}>
                <MDBModalHeader toggle={toggle}>{t('committee.noteApprove.rejectNote')}</MDBModalHeader>
                <MDBModalBody>
                    <h5 className="card-title"><b>{t('committee.noteApprove.sender')}:</b> <span className="badge badge-info">{renderRoleName(note.sender || {})}</span></h5>
                    <h5 className="card-title"><b>{t('committee.noteApprove.receiver')}:</b> <span className="badge badge-info">{renderRoleName(note.receiver || {})}</span></h5>
                    <h5><b>{t('committee.noteApprove.message')}:</b></h5>
                    <div className="alert alert-primary" role="alert">
                        {note.content}
                    </div>
                    <hr />
                    <div className="form-group">
                        <h5>{t('committee.noteApprove.rejectReason')}</h5>
                        <textarea className="form-control" value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button
                        className="btn btn-danger"
                        disabled={reviewNoteLoading}
                        onClick={() => {
                            markNoteRejected(note, rejectReason).then(() => {
                                setRejectReason("");
                            });
                        }}
                    >{t('committee.noteApprove.reject')}</button>
                    <button
                        className="btn btn-dark"
                        disabled={reviewNoteLoading}
                        onClick={() => {
                            setRejectReason("");
                            toggle();
                        }}
                    >{t('committee.noteApprove.close')}</button>
                </MDBModalFooter>
            </MDBModal> : null
        }
    </>
}

const NoteApprove = () => {
    const { t } = useTranslation();

    const me = jwt_decode(localStorage.getItem(roleField));

    const { committeeOptions, loading: cmLoading } = useCommitteeOptions(me.roleTarget);

    const { forceNoteReview } = committeeOptions;

    const { loading: pNoteLoading, data, subscribeToMore } = useQuery(QUERY_FETCH_PENDING_NOTES, {
        variables: {
            CMID: me.roleTarget
        },
        fetchPolicy: "network-only"
    });

    const loading = cmLoading || pNoteLoading;

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_RECEIVED_PENDING_NOTE,
            variables: {
                CMID: me.roleTarget
            },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.pendingNoteReceived) {
                    const edges = prev.committee && prev.committee.pnotes && prev.committee.pnotes.edges ? prev.committee.pnotes.edges : [];

                    const { pendingNoteReceived } = data;

                    const index = edges.findIndex((v) => v.cursor === pendingNoteReceived.cursor);

                    let updatedQuery = {};

                    if (index === -1) {
                        updatedQuery = update(prev, {
                            committee: {
                                pnotes: {
                                    edges: {
                                        $unshift: [
                                            pendingNoteReceived
                                        ]
                                    }
                                }
                            }
                        });
                    } else {
                        updatedQuery = update(prev, {
                            committee: {
                                pnotes: {
                                    edges: {
                                        [index]: {
                                            $set: pendingNoteReceived
                                        }
                                    }
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }

                return prev;
            }
        });

        subscribeToMore({
            document: SUBSCRIPTION_RESOLVED_PENDING_NOTE,
            variables: {
                CMID: me.roleTarget
            },
            updateQuery(prev, { subscriptionData: { data } }) {
                if (data && data.pendingNoteUpdated) {
                    const edges = prev.committee && prev.committee.pnotes && prev.committee.pnotes.edges ? prev.committee.pnotes.edges : [];

                    const { pendingNoteUpdated } = data;

                    let updatedQuery = prev;

                    const index = edges.findIndex((v) => v.cursor === pendingNoteUpdated.cursor);

                    if (pendingNoteUpdated.node.reviewer) {
                        if (index !== -1) {
                            updatedQuery = update(prev, {
                                committee: {
                                    pnotes: {
                                        edges: {
                                            $splice: [
                                                [index, 1]
                                            ]
                                        }
                                    }
                                }
                            });
                        }
                    } else {
                        updatedQuery = update(prev, {
                            committee: {
                                pnotes: {
                                    edges: {
                                        [index]: {
                                            $set: pendingNoteUpdated
                                        }
                                    }
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }

                return prev;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pnotes = useMemo(() => {
        return (data && data.committee && data.committee.pnotes && data.committee.pnotes.edges ? data.committee.pnotes.edges : []).map(({ node }) => node);
    }, [data]);

    const [openRejectModal, setOpenRejectModal] = useState(false);
    const toggleRejectModal = () => {
        setOpenRejectModal(!openRejectModal);
        setRejectModalTargetId(null);
    };

    const [rejectModalTargetId, setRejectModalTargetId] = useState(null);
    const rejectModalTarget = useMemo(() => {
        return pnotes.find((v) => v._id === rejectModalTargetId);
    }, [rejectModalTargetId, pnotes]);

    const [reviewNote, { loading: reviewNoteLoading }] = useMutation(MUTATION_REVIEW_NOTE);

    const markNoteApproved = (note) => {
        return reviewNote({
            variables: { NID: note._id, reviewer: me._id, blocked: false },
            context: {
                debounceKey: `reviewNote_${note._id}`,
                debounceTimeout: 500
            }
        });
    }

    const markNoteRejected = (note, reason) => {
        return reviewNote({
            variables: { NID: note._id, reviewer: me._id, blocked: true, blockedReason: reason },
            context: {
                debounceKey: `reviewNote_${note._id}`,
                debounceTimeout: 500
            }
        }).then(() => {
            setOpenRejectModal(false);
            setRejectModalTargetId(null);
        });
    }

    return (
        <div className="container">
            {!loading ?
                forceNoteReview ?
                    (
                        pnotes.length > 0 ?
                            <div className="card-columns">
                                <FlipMove>
                                    {pnotes.map((note) =>
                                        <PendingNote
                                            key={note._id}
                                            note={note}
                                            reviewNoteLoading={reviewNoteLoading}
                                            onApproveClick={() => markNoteApproved(note)}
                                            onRejectCick={() => {
                                                setOpenRejectModal(true);
                                                setRejectModalTargetId(note._id);
                                            }}
                                        />)}
                                </FlipMove>
                            </div> :
                            <div className="text-center">
                                <h1><span className="badge badge-info">{t('committee.noteApprove.noPending')}</span></h1>
                            </div>
                    ) :
                    (
                        <div className="text-center mt-5">
                            <h1>{t('committee.noteApprove.noteApproveUnavailable')}</h1>
                        </div>
                    )
                :
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">{t('loading')}</span>
                    </div>
                </div>
            }

            <NoteRejectModal
                isOpen={openRejectModal && rejectModalTarget}
                toggle={toggleRejectModal}
                note={rejectModalTarget}
                reviewNoteLoading={reviewNoteLoading}
                markNoteRejected={markNoteRejected}
            />
        </div>
    )
}

export default NoteApprove;
