import React, { useState, useEffect, useMemo } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

import { roleField, CommitteeProcedures, rightDisplayMode } from '../../../../constant'
import { alertNotify } from '../../../modules/notification'

import LeftControlPanel from './LeftControlPanel'
import DisplaySetting from './DisplaySetting'

import RollCall from './RollCall'
import SpeakersList from './SpeakersList'
import Timer from './Timer'
import Vote from './Vote'

import useCommitteeTimer from '../hooks/useCommitteeTimer'
import useCommitteeOptions from '../hooks/useCommitteeOptions'
import useCommitteeRollCall from '../hooks/useCommitteeRollCall'
import useCommitteeRoles from '../hooks/useCommitteeRoles'
import useCommitteeVote from '../hooks/useCommitteeVote'

function Index() {
    const { t } = useTranslation();

    const [me] = useState(() => jwt_decode(localStorage.getItem(roleField)));
    const this_cmid = me.roleTarget;

    const { committeeOptions: cmOptions, loading: committeeLoading, committee } = useCommitteeOptions(this_cmid);

    const cmTimer = useCommitteeTimer(this_cmid);

    const { lastRollCall } = useCommitteeRollCall(this_cmid);

    const { delegates, loading: cmRolesLoading } = useCommitteeRoles(this_cmid);

    const { currentVote } = useCommitteeVote(this_cmid);

    const lastRollCallUnfinished = useMemo(() => {
        if (delegates && lastRollCall) {
            if (!lastRollCall.attendee || Object.keys(lastRollCall.attendee).length < delegates.length) {
                return true;
            }
        }

        return false;
    }, [delegates, lastRollCall])

    const { currentProcedure, rightDisplaySelected } = cmOptions;

    const lastRollCallUnfinishedShouldNotify = lastRollCallUnfinished && currentProcedure !== undefined && currentProcedure !== CommitteeProcedures.RollCall;

    useEffect(() => {
        if (lastRollCallUnfinishedShouldNotify) {
            alertNotify(t('notifications.rollCall.incomplete'), t('notifications.rollCall.goFix'));
        }
        // eslint-disable-next-line
    }, [lastRollCallUnfinishedShouldNotify]);

    return (
        <div className="container-fluid">
            <div className="mx-5 mt-1">
                {
                    !committeeLoading
                        ?
                        <div className="row">
                            <div className="col-4">
                                <div className="card-columns" style={{ columnCount: 1 }}>
                                    <LeftControlPanel lastRollCallUnfinished={lastRollCallUnfinished} currentVote={!!currentVote} currentProcedure={currentProcedure} rightDisplay={cmOptions.rightDisplay} CMID={this_cmid} committeeOptions={cmOptions} isTHIMUN={committee && committee.type === "THIMUN"} />

                                    {rightDisplaySelected === rightDisplayMode.CustomText || rightDisplaySelected === rightDisplayMode.ResolutionOrAmendments ?
                                        <DisplaySetting CMID={this_cmid} committeeOptions={cmOptions} /> : null
                                    }

                                    {
                                        rightDisplaySelected === rightDisplayMode.Timer ?
                                            <Timer isSpeakersListMode={currentProcedure === CommitteeProcedures.SpeakersList} committeeTimer={cmTimer} committeeOptions={cmOptions} CMID={this_cmid} />
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="card-columns" style={{ columnCount: currentProcedure === CommitteeProcedures.RollCall ? 1 : 2 }}>
                                    {
                                        currentProcedure === CommitteeProcedures.SpeakersList ?
                                            <SpeakersList isSpeakersListMode={currentProcedure === CommitteeProcedures.SpeakersList} CMID={this_cmid} committeeOptions={cmOptions} isTHIMUN={committee.type === "THIMUN"} />
                                            : null
                                    }

                                    {
                                        currentProcedure === CommitteeProcedures.Vote ?
                                            <Vote committeeOptions={cmOptions} CMID={this_cmid} />
                                            : null
                                    }

                                    {
                                        currentProcedure === CommitteeProcedures.RollCall ?
                                            <RollCall committeeOptions={cmOptions} CMID={this_cmid}
                                                committeeRoles={delegates} fetchRoleLoading={cmRolesLoading}
                                                lastRollCallUnfinished={lastRollCallUnfinished} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="text-center">
                            <div className="spinner-grow text-primary" style={{ width: "3rem", height: "3rem" }}>
                                <span className="sr-only">{t('loading')}</span>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Index
