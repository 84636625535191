import React, { useMemo, Fragment, useEffect, useState, forwardRef } from 'react'
import * as jwt_decode from 'jwt-decode'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import FlipMove from 'react-flip-move'

import { useMutation } from 'react-apollo'
import { MUTATION_UPDATE_COMMITTEE_OPTIONS, MUTATION_EDIT_RESOLUTION, MUTATION_DELETE_RESOLUTION } from '../../../graphql/mutations'

import { roleField, resolutionStatusCode, ruleOfProcedure } from '../../../constant'
import { renderRoleName, renderResolutionDisplayText } from '../../modules/util'
import { errorNotify, successNotify, infoNotify } from '../../modules/notification'
import { CreateResolutionModal } from './Resolution'

import useCommitteeResolutions from './hooks/useCommitteeResolutions'
import useCommitteeOptions from './hooks/useCommitteeOptions'
import { useTranslation } from 'react-i18next'

const ResolutionCard = forwardRef(({ cmType, resolution, currentResolutionId,
    handlePublicResolution = () => { }, handleSetCurrentResolution = () => { },
    handleDeleteResolution = () => { }, isDelegate = false, resolutions = [] }, ref) => {

    const { t } = useTranslation();

    const isCurrent = resolution._id === currentResolutionId;

    const submitters = resolution.submitters || [];

    const [number, setNumber] = useState("DR");

    useEffect(() => {
        if (resolution.number) setNumber(resolution.number);
    }, [resolution]);

    const [mutateEditResolution, { loading }] = useMutation(MUTATION_EDIT_RESOLUTION);

    const { resolutionStatus: status } = resolution;

    const isResoNumberDiff = (resolution.number || "") !== number;
    const isResoNumberRepeat = resolutions.findIndex((v) => v.number === number && v._id !== resolution._id) !== -1;

    const [editResolutionLink, setEditResolutionLink] = useState("");
    const [enableEditReso, setEnableEditReso] = useState(false);
    useEffect(() => {
        if (resolution && resolution.googleDriveURL) {
            setEditResolutionLink(resolution.googleDriveURL);
        }
    }, [resolution]);

    return (
        <div className="row py-2" key={resolution._id} ref={ref}>
            <div className="col">
                <div className={"card " + (status === resolutionStatusCode.Passed ? "border-success" : status === resolutionStatusCode.NotPassed ? "border-danger" : "")}>
                    <div className="card-body">
                        <div className="row justify-content-between mb-2">
                            <div className="col">
                                <div className="row">
                                    <div className="col-auto align-self-center pr-1">
                                        {
                                            !isDelegate ? 
                                            <select value={number.substring(0, 2)} id="inputState" className="form-control" onChange={(e) => setNumber(e.target.value + number.substring(2))}>
                                                <option value="DR">DR</option>
                                                {
                                                    cmType === ruleOfProcedure.UNAUSA ?
                                                        <>
                                                        <option value="WP">WP</option>
                                                        <option value="DA">DA</option>
                                                        </> :
                                                        null
                                                }
                                            </select> : null
                                        }
                                    </div>

                                    {
                                        !isDelegate ?
                                            <>
                                                <div className="col-2 col-lg-1 align-self-center pl-1 pr-0">
                                                    <input
                                                        className={"form-control " + (isResoNumberDiff ? isResoNumberRepeat ? "border-danger" : "border-warning" : "")}
                                                        value={number.substring(2, number.length) || ""}
                                                        onChange={(e) => setNumber(number.substring(0, 2) + e.target.value)}
                                                        style={isResoNumberDiff && !isResoNumberRepeat ?
                                                            { animation: "blinker-modify 2s ease-out infinite" }
                                                            : null
                                                        }
                                                    />
                                                </div>
                                                <div className="col-auto align-self-center">
                                                    {
                                                        loading ?
                                                            <div className="text-center">
                                                                <div className="spinner-border text-warning">
                                                                    <span className="sr-only">{t('loading')}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <span className={isResoNumberDiff && !isResoNumberRepeat ? "text-warning" : "disabled text-muted"} style={{ cursor: "pointer" }} onClick={() => {
                                                                mutateEditResolution({
                                                                    variables: {
                                                                        RESOID: resolution._id,
                                                                        number
                                                                    }
                                                                });
                                                            }}>{t('committee.chairResolution.save')}</span>
                                                    }
                                                </div>
                                            </> : <h3 className="mb-0">{resolution.number}</h3>
                                    }
                                </div>
                            </div>
                            <div className="col-auto align-self-center pl-0">
                                <h3>
                                    {isCurrent ? <span className="badge badge-primary">{t('committee.chairResolution.current')}</span> : null}
                                    {status !== resolutionStatusCode.Pending ?
                                        <>
                                            {" "}
                                            {status === resolutionStatusCode.Passed ? <span className="badge badge-success">{t('committee.chairResolution.passed')}</span> : <span className="badge badge-danger">{t('committee.chairResolution.failed')}</span>}
                                        </> : null}
                                </h3>
                            </div>
                            {
                                !isDelegate ? <div className="col-auto align-self-center">
                                    {status === resolutionStatusCode.Pending ? <>
                                        <span className="icon-btn">
                                            <span className="material-icons" style={{ cursor: "pointer" }} title="Pass DR" onClick={() => mutateEditResolution({
                                                variables: {
                                                    RESOID: resolution._id,
                                                    status: resolutionStatusCode.Passed
                                                }
                                            })}>check_circle_outline</span>
                                        </span>
                                        <span className="icon-btn">
                                            <span className="material-icons" style={{ cursor: "pointer" }} title="Fail DR" onClick={() => mutateEditResolution({
                                                variables: {
                                                    RESOID: resolution._id,
                                                    status: resolutionStatusCode.NotPassed
                                                }
                                            })}>highlight_off</span>
                                        </span>
                                    </> : null}
                                    <span className={"icon-btn" + (isCurrent ? " disabled" : "")}>
                                        <span className="material-icons" style={{ cursor: "pointer" }} onClick={() => handleDeleteResolution(resolution)}>delete</span>
                                    </span>
                                </div> : null
                            }
                        </div>

                        <h5>{t('committee.chairResolution.topic')}<span className="font-weight-light">{resolution.topic}</span></h5>
                        <h5>{t('committee.chairResolution.submitters')}<span className="font-weight-light">{submitters.map((v) => <Fragment key={v._id}><span>{renderRoleName(v)}</span>{" "}</Fragment>)}</span></h5>

                        {
                            enableEditReso ?
                                <div className="mt-3">
                                    <div className="form-group">
                                        <label>{t('committee.chairResolution.editResoLink')}</label>
                                        <input className="form-control" onChange={(e) => setEditResolutionLink(e.target.value)} value={editResolutionLink} />
                                    </div>
                                    <button className="btn btn-sm btn-primary" onClick={() => {
                                        mutateEditResolution({
                                            variables: {
                                                RESOID: resolution._id,
                                                googleDriveURL: editResolutionLink
                                            }
                                        }).then(() => {
                                            setEnableEditReso(false);
                                        });
                                    }}>Save</button>
                                    <button className="btn btn-sm btn-blue-grey" onClick={() => setEnableEditReso(false)}>Close</button>
                                </div>
                                :
                                <>
                                    <a href={resolution.googleDriveURL} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">{t('committee.chairResolution.openReso')}</a>
                                    {
                                        !isDelegate ? <>
                                            <button className="btn btn-sm btn-warning" disabled={status !== resolutionStatusCode.Pending} onClick={() => setEnableEditReso(true)}>{t('committee.chairResolution.editReso')}</button>
                                            {
                                                cmType === ruleOfProcedure.THIMUN ?
                                                    <button className={"btn btn-sm btn-success" + (isCurrent ? " disabled" : "")} disabled={status !== resolutionStatusCode.Pending} onClick={() => { handleSetCurrentResolution(resolution) }}>{t('committee.chairResolution.currReso')}</button>
                                                    :
                                                    <></>
                                            }

                                            {resolution.isPublic ?
                                                <button className="btn btn-sm btn-danger" disabled={isCurrent} onClick={() => { handlePublicResolution(resolution) }}>{t('committee.chairResolution.makePrivate')}</button> :
                                                <button className="btn btn-sm btn-info" onClick={() => { handlePublicResolution(resolution) }}>{t('committee.chairResolution.makePublic')}</button>
                                            }
                                        </> : null
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
});

export { ResolutionCard };

const ChairResolution = () => {
    const { t } = useTranslation();
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const { resolutions, loading: resolutionsLoading } = useCommitteeResolutions(this_CMID);
    const { committee, committeeOptions } = useCommitteeOptions(this_CMID);

    const cmType = useMemo(() => (committee || {}).type, [committee]);

    const sortedResolutions = useMemo(() => [...resolutions].sort((a, b) => {
        const codeMap = {
            [resolutionStatusCode.Passed]: 2,
            [resolutionStatusCode.NotPassed]: 2,
            [resolutionStatusCode.Pending]: 1
        }

        return (codeMap[a.resolutionStatus] || 0) - (codeMap[b.resolutionStatus] || 0);
    }), [resolutions]);

    const [mutateEditCommittee] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID: this_CMID
        }
    });

    const [mutateEditResolution] = useMutation(MUTATION_EDIT_RESOLUTION);
    const [mutateDeleteResoution, { loading: deleteResoLoading }] = useMutation(MUTATION_DELETE_RESOLUTION);


    const handlePublicResolution = (resolution, value) => {
        return mutateEditResolution({
            variables: {
                RESOID: resolution._id,
                isPublic: value || !resolution.isPublic
            }
        })
    }

    const deleteResolution = (resolution) => {
        mutateDeleteResoution({
            variables: {
                RESOID: resolution._id
            }
        }).then(() => {
            infoNotify(t('committee.chairResolution.resolutionDeleted'));
            setdeleteTarget(null);
            setOpenDeleteModal(false);
        });
    }

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteTarget, setdeleteTarget] = useState(null);
    const [isCreateResoOpen, setIsCreateResoOpen] = useState(false);

    const toggleCreateReso = () => setIsCreateResoOpen(!isCreateResoOpen);

    const toggleDeleteModal = () => setOpenDeleteModal(!setOpenDeleteModal);

    const handleDeleteResolution = (resolution) => {
        setOpenDeleteModal(true);
        setdeleteTarget(resolution);
    }

    const handleClearCurrentResolution = () => {
        mutateEditCommittee({
            variables: {
                options: {
                    currentResolutionId: ""
                }
            }
        }).then(() => {
            successNotify(t('committee.chairResolution.sentences.clearCurrReso'));
        }).catch((e) => {
            errorNotify(t('error'), t('committee.chairResolution.sentences.cannotSave'), false);
            throw e;
        });
    }

    const handleSetCurrentResolution = (resolution) => {
        (() => {
            if (!resolution.isPublic) return handlePublicResolution(resolution);
            return Promise.resolve();
        })()
            .then(() => mutateEditCommittee({
                variables: {
                    options: {
                        currentResolutionId: resolution._id
                    }
                }
            }))
            .then(() => {
                successNotify(t('committee.chairResolution.sentences.setCurrSuccess'), t('committee.chairResolution.sentences.setCurrTo') + "<strong>" + renderResolutionDisplayText(resolution) + "</strong>", true);
            }).catch((e) => {
                errorNotify(t('error'), t('committee.chairResolution.sentences.cannotSave'), false);
                throw e;
            });
    }

    return (
        <div className="container-fluid">
            <div className="overflow">
                <div className="container">
                    <div className="row">
                        {
                            cmType === ruleOfProcedure.THIMUN ?
                                <div className="col">
                                    <button className="btn btn-sm btn-warning" disabled={!(committeeOptions && committeeOptions.currentResolutionId)}
                                        onClick={handleClearCurrentResolution}> {t('committee.chairResolution.clearCurrReso')} </button>
                                </div> :
                                <>
                                </>
                        }

                        <div className="col text-right">
                            <button className="btn btn-sm btn-info" onClick={() => setIsCreateResoOpen(true)}>{t('committee.resolution.submitReso')}</button>
                        </div>
                    </div>
                    <div className="my-1 child-no-margin">
                        <p className="card-text"><b>{t('committee.chairResolution.openReso')}</b>: {t('committee.chairResolution.sentences.google')}</p>
                        <p className="card-text"><b>{t('committee.chairResolution.editReso')}</b>: {t('committee.chairResolution.sentences.editLinkReso')}</p>
                        {
                            cmType === ruleOfProcedure.THIMUN ?
                                <p className="card-text"><b>{t('committee.chairResolution.currReso')}</b>: {t('committee.chairResolution.sentences.setCurrReso')}</p>
                                :
                                <></>
                        }
                        <p className="card-text"><b>{t('committee.chairResolution.makePublic')}</b>: {t('committee.chairResolution.sentences.makePublic')}</p>
                        <p className="card-text"><b>{t('committee.chairResolution.makePrivate')}</b>: {t('committee.chairResolution.sentences.makePrivate')}</p>

                    </div>
                    {
                        !resolutionsLoading ?
                            sortedResolutions && sortedResolutions.length > 0 ?
                                <FlipMove>
                                    {
                                        sortedResolutions.map((resolution) => {
                                            return <ResolutionCard
                                                key={resolution._id}
                                                cmType={cmType}
                                                resolution={resolution}
                                                currentResolutionId={committeeOptions.currentResolutionId}
                                                handleSetCurrentResolution={handleSetCurrentResolution}
                                                handlePublicResolution={handlePublicResolution}
                                                handleDeleteResolution={handleDeleteResolution}
                                                resolutions={sortedResolutions}
                                            />
                                        })
                                    }
                                </FlipMove>
                                :
                                <div className="text-center">
                                    <h1><span className="badge badge-warning">{t('committee.chairResolution.noReso')}</span></h1>
                                </div>
                            :
                            <div className="text-center">
                                <div className="spinner-border text-dark">
                                    <span className="sr-only">{t('loading')}</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <MDBModal isOpen={openDeleteModal} toggle={toggleDeleteModal}>
                <MDBModalHeader toggle={toggleDeleteModal}>
                    {t('committee.chairResolution.confirmDelete')} {deleteTarget ? (deleteTarget.number ? "" : t('committee.chairResolution.resolution')) + renderResolutionDisplayText(deleteTarget) : null}
                </MDBModalHeader>
                <MDBModalBody>
                    <h5>
                        <b>{t('committee.chairResolution.topic')}</b> {(deleteTarget || {}).topic}
                    </h5>
                    <h5>
                        <b>{t('committee.chairResolution.submitters')}</b> {deleteTarget && deleteTarget.submitters ? deleteTarget.submitters.map((v) => <Fragment key={v._id}><span>{renderRoleName(v)}</span>{" "}</Fragment>) : null}
                    </h5>
                </MDBModalBody>
                <MDBModalFooter>
                    <button className="btn btn-danger" disabled={deleteResoLoading} onClick={() => deleteResolution(deleteTarget)}>{t('committee.chairResolution.delete')}</button>
                    <button className="btn btn-dark" onClick={() => setOpenDeleteModal(false)}>{t('committee.chairResolution.cancel')}</button>
                </MDBModalFooter>
            </MDBModal>

            <CreateResolutionModal isOpen={isCreateResoOpen} toggle={toggleCreateReso} fromChair CMID={this_CMID} />
        </div >
    )
}

export default ChairResolution