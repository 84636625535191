import React, { Component } from 'react'
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Dashboard from './systemAdmin/Dashboard'
import ManageUser from './systemAdmin/ManageUser'
// import ManageRole from './systemAdmin/ManageRole'
import ManageOrg from './systemAdmin/ManageOrg'

export class Admin extends Component {
    getClassName(path) {
        return "nav-link" + (this.props.location.pathname === path ? " active" : "");
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-2 col-md-3">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">Admin Panel</h5>
                                <hr />
                                <ul className="nav flex-column mt-3 nav-pills" style={{ overflowY: "auto", height: "calc(100vh - 210px)" }}>
                                    <li className="nav-item">
                                        <Link className={this.getClassName("/admin/dash")} to="/admin/dash">Dashboard</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={this.getClassName("/admin/user")} to="/admin/user">User</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={this.getClassName("/admin/role")} to="/admin/role">Role</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={this.getClassName("/admin/org")} to="/admin/org">Organization</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <Switch>
                                    <Redirect from="/admin" exact to="/admin/dash" />
                                    <Route path="/admin/dash">
                                        <Dashboard />
                                    </Route>
                                    <Route path="/admin/user">
                                        <ManageUser />
                                    </Route>
                                    <Route path="/admin/role">
                                        {/* <ManageRole /> */}
                                    </Route>
                                    <Route path="/admin/org">
                                        <ManageOrg />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Admin)
