import React, { useDebugValue, useState } from 'react'

import { useQuery } from 'react-apollo'

import { QUERY_FETCH_SYSTEM_USERS } from '../../../graphql/queries'
import itemStatusBadge from '../../layout/itemStatusBadge'

const useSystemUsers = () => {
    const { data, loading } = useQuery(QUERY_FETCH_SYSTEM_USERS, {
        fetchPolicy: "network-only"
    });

    const { users } = data || {};

    useDebugValue(users);

    return { users: (users ? users : []), loading };
}

function ManageUser() {
    const { users, loading } = useSystemUsers();

    const filterTypes = {
        All: "all",
        PendingReg: "Pending Reg"
    }
    const [statusFilter, setstatusFilter] = useState(filterTypes.All);

    const renderUser = ({ _id, firstName, lastName, email, organization, status, pendingRegister }, index) => {

        const { name: orgName } = organization || {};

        return (
            <tr key={index}>
                <th scope="row">{firstName + " " + lastName}</th>
                <td>{itemStatusBadge(status)} {pendingRegister ? <span className="badge badge-warning">Reg Pending</span> : null}</td>
                <td>{email}</td>
                <td>{orgName || "n/a"}</td>
            </tr>
        )
    }

    return (
        <div className="container-fluid">
        <h4>Manage User</h4>
            <div className="row justify-content-end">
                <div className="col-2">
                    <select className="form-control form-control-lg" onChange={(v) => setstatusFilter(v.target.value)}>
                        {Object.keys(filterTypes).map((key) => {
                            return <option key={key} value={filterTypes[key]}>{filterTypes[key]}</option>
                        })}
                    </select>
                </div>
            </div>

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name</th>{/* Combine firstname and lastname */}
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col">Organization</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !loading ? users.filter((v) => {
                            if (statusFilter === filterTypes.All) return true;
                            else if (statusFilter === filterTypes.PendingReg) return v.pendingRegister;
                            return true;
                        }).map((user, index) => {
                            return renderUser(user, index);
                        })
                            :
                            <tr>
                                <td colSpan="4" className="text-center">
                                    <div className="spinner-border text-primary">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ManageUser
