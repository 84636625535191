import React, { useMemo, Fragment } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

import { roleField, CommitteeProcedures, rightDisplayMode, munativePic } from '../../../constant'
import { timeTools } from '../../modules/util'

import useCommitteeOptions from './hooks/useCommitteeOptions'
import useCommitteeRoles from './hooks/useCommitteeRoles'
import useCommitteeRollCall from './hooks/useCommitteeRollCall'
import useSyncedTime from './hooks/useSyncedTime'

import SpeakersList from './display/SpeakersList'
import Vote from './display/Vote'
import RollCall from './display/RollCall'
import ResolutionAndAmendment from './display/ResolutionAndAmendment'
import useCommitteeTimer from './hooks/useCommitteeTimer'
import { useLocation } from 'react-router-dom'

function TimerDisplay({ timerText }) {
    return (
        <div className="card">
            <div className="card-body text-center">
                <h1 className="display-4">{timerText}</h1>
            </div>
        </div>
    )
}

function CustomText({ customCenterText, displayFontSize }) {
    const displayClass = {
        "XL": "display-2",
        "L": "display-3",
        "M": "display-4",
        "S": ""
    }

    return (
        <div className="card">
            <div className="card-body text-center">
                <h1 className={displayClass[displayFontSize] || ""}>
                    {customCenterText
                        ? customCenterText.split("\n").map(function (item, i) {
                            return (
                                <Fragment key={i}>
                                    {item}
                                    <br />
                                </Fragment>
                            );
                        })
                        : ""}
                </h1>
            </div>
        </div>
    )
}

export function AudDisplay({ CMID: this_CMID }) {
    const syncedTime = useSyncedTime();
    const { t } = useTranslation();

    const { committeeOptions, committee } = useCommitteeOptions(this_CMID);
    const { delegates } = useCommitteeRoles(this_CMID);
    const { lastRollCall } = useCommitteeRollCall(this_CMID);
    const { timerState, remainTime, timeLimit } = useCommitteeTimer(this_CMID);

    let timerText = timerState === 0 ?
        (timeLimit > 0 ? timeTools.msToStr(timeLimit) : syncedTime.getCurrentTime().toLocaleTimeString()) :
        (remainTime > 0 ?
            timeTools.msToStr(remainTime) :
            (
                <span
                    className="badge badge-danger"
                    style={{ animation: "blinker 1.5s linear infinite" }}
                >
                    {t("pageGroups.committee.timesUp")}
                </span>
            )
        );

    const { currentProcedure = CommitteeProcedures.None, rightDisplaySelected = rightDisplayMode.None, displaySettings } = committeeOptions || {};
    const { customCenterText, rightDisplay: rightDisplaySetting, fontSize: displayFontSize } = displaySettings || {};


    document.body.style.paddingTop = "2.5vh";

    const rightCol = currentProcedure !== CommitteeProcedures.None ? "col" : "col-8";

    const { name: committeeName, conference } = committee || {};

    const { name: cfName, logoSrc: cfLogo } = conference || {};

    return (
        <div className="container-fluid">

            <div id="aud-display" className="row justify-content-around flex-nowrap">
                {
                    currentProcedure === CommitteeProcedures.RollCall || currentProcedure === CommitteeProcedures.Vote ?
                        <div className="col-8">
                            {currentProcedure === CommitteeProcedures.RollCall ? (
                                <RollCall
                                    committeeRoles={delegates}
                                    lastRollCall={lastRollCall}
                                />
                            ) : null}

                            {currentProcedure === CommitteeProcedures.Vote ? <Vote CMID={this_CMID} committeeRoles={delegates} /> : null}
                        </div> : null
                }

                {currentProcedure === CommitteeProcedures.SpeakersList ? <SpeakersList timerText={timerText} CMID={this_CMID} /> : null}

                {
                    rightDisplaySelected !== rightDisplayMode.None ?
                        <div className={rightCol} style={{ overflow: "hidden" }}>
                            {rightDisplaySelected === rightDisplayMode.Timer ? <TimerDisplay timerText={timerText} /> : null}
                            {rightDisplaySelected === rightDisplayMode.CustomText ? <CustomText customCenterText={customCenterText} displayFontSize={displayFontSize} /> : null}
                            {rightDisplaySelected === rightDisplayMode.ResolutionOrAmendments ?
                                <ResolutionAndAmendment rightDisplaySetting={rightDisplaySetting} displayFontSize={displayFontSize} /> : null
                            }
                        </div> : null
                }

                {
                    currentProcedure === CommitteeProcedures.None && rightDisplaySelected === rightDisplayMode.None ?
                        <div className="col text-center">
                            <div className="row justify-content-center my-5">
                                <div className="col-auto">
                                    <img width="200" height="200" src={cfLogo || munativePic} alt="org-logo" />
                                </div>
                                <div className="col-auto text-left align-self-center">
                                    <h1 className="display-1">{cfName || "Munative"}</h1>
                                </div>
                            </div>

                            {
                                committee ? <h1 className="display-3">{t('committee.audDisplay.hereIs')}{" "}<span className="badge badge-info">{committeeName || "n/a"}</span></h1> : null
                            }
                        </div> : null
                }
            </div>
        </div>
    )
}

function useURLQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function AudDisplayWrapped() {
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const query = useURLQuery();
    const committeeId = query.get("committee");

    return <AudDisplay CMID={committeeId || this_CMID} />
}
