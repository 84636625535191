import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, useHistory, Link, Redirect } from 'react-router-dom'
import { alertNotify } from '../modules/notification'

import { useMutation } from 'react-apollo'
import gql from 'graphql-tag'
import { FRAGMENT_USER_BASIC_INFO } from '../../graphql/fragments'
import { useTranslation } from 'react-i18next'

function useURLQuery() {
    return new URLSearchParams(useLocation().search);
}

const MUTATION_VERIFY_REGISTER_TOKEN = gql`
mutation verifyRegisterToken($token: String!){
    verifyRegisterToken(input: {token: $token}){
        user {
            _id
            ...UserBasicInfo
        }
    }
}
${FRAGMENT_USER_BASIC_INFO}
`

const MUTATION_USER_REGISTER_CONFIRM = gql`
mutation userRegisterConfirm($token: String!, $newPassword: String!, $firstName: String, $lastName: String){
    userRegisterConfirm(input: {
        token: $token
        newPassword: $newPassword
        firstName: $firstName,
        lastName: $lastName
    }){
        user {
            _id
            ...UserBasicInfo
        }
    }
}
${FRAGMENT_USER_BASIC_INFO}
`

function RegisterConfirm({ token }) {
    const { i18n } = useTranslation();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const history = useHistory();

    const [errorMessages, setErrorMessages] = useState([]);

    const [mutateVerifyRegisterToken, { data, loading: mutateVerifyRegisterTokenLoading }] = useMutation(MUTATION_VERIFY_REGISTER_TOKEN);
    const [mutateUserRegisterConfirm, { loading: mutateUserRegisterConfirmLoading }] = useMutation(MUTATION_USER_REGISTER_CONFIRM);

    const user = data && data.verifyRegisterToken && data.verifyRegisterToken.user;

    useEffect(() => {
        if (token) {
            mutateVerifyRegisterToken({
                variables: {
                    token
                }
            }).catch(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    setErrorMessages(graphQLErrors.map((error) => error.message));
                }
            });
        }
    }, [mutateVerifyRegisterToken, token]);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
        }
    }, [user]);

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (password.length <= 0) {
            alertNotify("Invalid Password", "Password is required");
            return;
        }

        if (password !== confirmPassword) {
            alertNotify("Invalid Password", "Password and Confirm Password are different");
            return;
        }

        mutateUserRegisterConfirm({
            variables: {
                token,
                newPassword: password,
                firstName,
                lastName
            }
        }).then(() => {
            history.push("/auth/register_confirm/success");
        }).catch(({ graphQLErrors }) => {
            if (graphQLErrors) {
                setErrorMessages(graphQLErrors.map((error) => error.message));
            }
        });
    }

    return (
        <div className="text-left">
            {
                errorMessages.length > 0 ? <div className="alert alert-warning text-center">
                    {
                        errorMessages.map((v, i) => {
                            return <h5 key={i} className="mb-0">{i18n.t(`registerConfirm.error.${v}`)}</h5>
                        })
                    }
                    <h6 className="mt-2 mb-0">
                        <Link className="badge badge-primary" to="/auth/renew_link">
                            Click here to renew your link
                        </Link>
                    </h6>
                </div> : null
            }
            {
                user ? <>
                    <h4>Welcome {user.firstName}</h4>
                    <h6 className="text-muted">Please confirm your register information</h6>
                    <form className="mt-4" onSubmit={onSubmitForm}>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Email</label>
                                <input type="email" className="form-control" name="username" value={user.email} readOnly />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12 col-lg-6">
                                <label>First Name</label>
                                <input type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="form-group col-12 col-lg-6">
                                <label>Last Name</label>
                                <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12 col-lg-6">
                                <label>Password</label>
                                <input type="password" autoComplete="new-password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="form-group col-12 col-lg-6">
                                <label>Confirm Password</label>
                                <input type="password" autoComplete="new-password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <small id="emailHelp" class="form-text text-muted">Enter your password again.</small>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">
                            Submit
                            {
                                mutateUserRegisterConfirmLoading ? <div className="spinner-border spinner-border-sm ml-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : null
                            }
                        </button>
                    </form>
                </> :
                    mutateVerifyRegisterTokenLoading ? <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> : null
            }
        </div>
    )
}

function UserConfirmRegister() {
    const URLQuery = useURLQuery();
    const token = URLQuery.get("token");

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-12 col-md-8">
                    <div className="card">
                        <div className="card-body text-center">
                            <Switch>
                                <Route path="/auth/register_confirm/success">
                                    <h3 className="mb-4">Register Confirm Success</h3>
                                    <i className="material-icons text-success display-1">check_circle_outline</i>
                                    <h5>Sign in your account to use Munative</h5>
                                </Route>
                                <Route>
                                    <h3 className="mb-4">Register Confirm</h3>
                                    {
                                        token ? <RegisterConfirm token={token} /> : <Redirect to="/" />
                                    }
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserConfirmRegister
