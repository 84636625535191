import React, { useState, useEffect, useMemo } from 'react'

import useResolutionsAndAmendments from '../hooks/useResolutionsAndAmendments'

import useCommitteeVote from '../hooks/useCommitteeVote'
import useCommitteeRoles from '../hooks/useCommitteeRoles'
import useCommitteeRollCall from '../hooks/useCommitteeRollCall'
import useCommitteeOptions from '../hooks/useCommitteeOptions'
import useCommitteeAmendments from '../hooks/useCommitteeAmendments'

import { MDBCollapse } from 'mdbreact'
import ToggleSwitch from '../../../modules/ToggleSwitch'
import { alertNotify, errorNotify, successNotify } from '../../../modules/notification'
import { renderAmendmentDisplayText, renderRoleName, renderResolutionDisplayText } from '../../../modules/util'
import { resolutionStatusCode, amendmentStatusCode, voteStatusCode, ruleOfProcedure } from '../../../../constant'

import { useMutation } from '@apollo/react-hooks'
import { MUTATION_EDIT_VOTE, MUTATION_UPDATE_COMMITTEE_OPTIONS, MUTATION_CREATE_VOTE, MUTATION_CLEAR_VOTE_RESULT, MUTATION_UPDATE_SUBMITTED_VOTE } from '../../../../graphql/mutations'

// import CreateVoteModal from './CreateVoteModal'
import EndVoteModal from './EndVoteModal'
import RollCallStat from '../rollcall/RollCallStat'
import { useTranslation } from 'react-i18next'
import ResolutionAmendmentSelector from './ResolutionAmendmentSelector'

const getTopic = (selected, language) => {
    selected = JSON.parse(selected)
    let type = selected.type, v = selected.v
    if (type === "resolution") {
        return (v.number ? "" : "Resolution: ") + renderResolutionDisplayText(v);
    } else if (type === "amendment") {
        return "Amendment: " + renderAmendmentDisplayText(v, { language });
    } else {
        return "Procedural";
    }
}

const useCurrentVoteInputFields = (currentVoteId, { voteFor, voteAgainst, voteAbstain }) => {
    const { t } = useTranslation()
    const [forInput, setForInput] = useState(0);
    const [againstInput, setAgainstInput] = useState(0);
    const [abstainInput, setAbstainInput] = useState(0);

    const [editVote] = useMutation(MUTATION_EDIT_VOTE, {
        variables: {
            VID: currentVoteId
        }
    })

    useEffect(() => {
        setForInput(voteFor);
    }, [voteFor]);

    useEffect(() => {
        setAgainstInput(voteAgainst);
    }, [voteAgainst]);

    useEffect(() => {
        setAbstainInput(voteAbstain);
    }, [voteAbstain]);

    const handleInput = (e, setFunc) => {
        if (e.target.value === "" || isNaN(e.target.value)) {
            setFunc(0);
        } else {
            setFunc(parseInt(e.target.value));
        }
    }

    const handleSave = () => {
        let newResult = {};

        for (let i = 0; i < forInput; i++) {
            newResult["F" + i] = voteStatusCode.VoteFor;
        }

        for (let i = 0; i < againstInput; i++) {
            newResult["AG" + i] = voteStatusCode.VoteAgainst;
        }

        for (let i = 0; i < abstainInput; i++) {
            newResult["AB" + i] = voteStatusCode.VoteAbstain;
        }

        editVote({
            variables: {
                result: newResult
            }
        }).then(() => {
            successNotify(t('notifications.vote.success.voteResultSaved'));
        })
    }

    return {
        forInput, againstInput, abstainInput,
        handleForInput: (e) => handleInput(e, setForInput),
        handleAgainstInput: (e) => handleInput(e, setAgainstInput),
        handleAbstainInput: (e) => handleInput(e, setAbstainInput),
        handleSave
    };
}

function Vote({ CMID, committeeOptions }) {

    const { t, i18n } = useTranslation();
    const { delegates } = useCommitteeRoles(CMID);

    const { committee } = useCommitteeOptions(CMID);
    const cmType = useMemo(() => (committee || {}).type, [committee]);

    // rollcall
    const { lastRollCall, loading: rollcallLoading } = useCommitteeRollCall(CMID);

    // vote modal
    // const [showCreateVoteModal, setShowCreateVoteModal] = useState(false);
    // const toggleCreateVoteModal = () => setShowCreateVoteModal(!showCreateVoteModal);

    const [showEndVoteModal, setShowEndVoteModal] = useState(false);
    const toggleEndVoteModal = () => setShowEndVoteModal(!showEndVoteModal);

    // amendments and resolutions
    const { cmAmendments } = useCommitteeAmendments(CMID);
    let { amendments, resolutions, loading: useResolutionAndAmendmentLoading } = useResolutionsAndAmendments(CMID, committeeOptions.currentResolutionId);
    amendments = (cmType === ruleOfProcedure.UNAUSA ? cmAmendments : amendments);

    resolutions = resolutions.filter((v) => {
        return v.resolutionStatus === resolutionStatusCode.Pending;
    });
    amendments = amendments.filter((v) => {
        return v.amendmentStatus === amendmentStatusCode.Pending;
    });

    // current vote
    const { currentVote, loading: useCmVoteLoading, voteStat, currentVoteId } = useCommitteeVote(CMID);
    const { forInput, againstInput, abstainInput, handleForInput, handleAgainstInput, handleAbstainInput, handleSave } = useCurrentVoteInputFields(currentVoteId, voteStat);

    // others

    const [topicSelected, setTopicSelected] = useState(JSON.stringify({ type: "none" }));
    const [useAutoMode, setUseAutoMode] = useState(false);
    const [noAbstain, setNoAbstain] = useState(false);

    const [updateCommittee, { loading: updateCommitteeLoading }] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID
        }
    });

    const [clearVoteResult] = useMutation(MUTATION_CLEAR_VOTE_RESULT, {
        variables: {
            VID: currentVoteId
        }
    })

    const [updateSubmittedVote] = useMutation(MUTATION_UPDATE_SUBMITTED_VOTE);


    const loading = useResolutionAndAmendmentLoading || useCmVoteLoading || updateCommitteeLoading;

    const [addVoteMutate, { loading: addVoteMutateLoading }] = useMutation(MUTATION_CREATE_VOTE, {
        variables: {
            CMID
        },
        context: {
            debounceKey: `addVote`,
            debounceTimeout: 500
        }
    });

    const setCurrentVote = (voteId) => {
        return updateCommittee({
            variables: {
                options: {
                    vote: {
                        currentVoteId: voteId,
                        showVoteResult: false
                    }
                }
            }
        });
    }

    const [topic, setTopic] = useState("");

    useEffect(() => {
        setTopic(getTopic(topicSelected, i18n.language));
        // eslint-disable-next-line
    }, [topicSelected]);

    const handleCreateVote = () => {
        if (topicSelected === JSON.stringify({ type: "none" })) {
            alertNotify(t('notifications.vote.alert.selectATopicFirst'));
            return
        }

        let link = undefined;
        let selected = JSON.parse(topicSelected)
        if (selected.type === "amendment" || selected.type === "resolution") {
            link = {
                type: selected.type,
                targetId: selected.v._id
            };
        }

        return addVoteMutate({
            variables: {
                startTime: new Date(),
                result: {},
                topic,
                mode: useAutoMode ? "Auto" : "Manual",
                link,
                noAbstain: !!noAbstain
            }
        }).then((result) => {
            setTopic("");
            return Promise.resolve(result);
        }).then(({ data }) => {
            const { createVote } = data || {};
            if (createVote) {
                return setCurrentVote(createVote.vote._id);
            }
            return Promise.resolve();
        })
            .then(() => {
                successNotify(t('notifications.vote.success.voteCreatedAndStarted'));
            })
            .catch((err) => {
                console.error(err);
                errorNotify(t('notifications.vote.error.whileCreatingTheVote'), (typeof err) !== "object" ? err : JSON.stringify(err));
            });
    }

    const renderCreateVotePart = () => {
        return (
            <>
                <div className="mb-3">
                    <div className="row my-2">
                        <div className="col-12 col-xl-7 pr-1">
                            <p>{t('committee.chairDash.vote.topic')}</p>
                            <ResolutionAmendmentSelector
                                className="form-control"
                                resolutions={resolutions}
                                amendments={amendments}
                                cmType={cmType}
                                value={topicSelected}
                                onChange={(e) => setTopicSelected(e.target.value)}>
                                <option value={JSON.stringify({ type: "other" })}>
                                    {t('committee.chairDash.vote.other')}
                                </option>
                            </ResolutionAmendmentSelector>
                        </div>
                        <div className="col-12 col-xl-5 pl-1">
                            <label> {t('committee.chairDash.vote.voteMode')} </label>
                            <ToggleSwitch name="voteMode" onLabel={t('committee.chairDash.vote.auto')} offLabel={t('committee.chairDash.vote.manual')} value={useAutoMode} onChange={(v) => setUseAutoMode(v)} />
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col align-self-center">
                            <label className="mr-1">{t('committee.chairDash.vote.canVoteAbstain')}</label>
                            <ToggleSwitch name="voteMode" onLabel={t('committee.chairDash.vote.yes')} offLabel={t('committee.chairDash.vote.no')} value={!noAbstain} onChange={(v) => setNoAbstain(!v)} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <button className="btn btn-sm btn-success" disabled={topicSelected === JSON.stringify({ type: "none" }) || addVoteMutateLoading}
                            onClick={handleCreateVote}> {t('committee.chairDash.vote.create')} </button>
                    </div>
                </div>
            </>
        )
    }

    const shouldShowVoteResult = !!(committeeOptions && committeeOptions.vote && committeeOptions.vote.showVoteResult);

    const renderVoteStatPart = () => {
        return (
            <div>
                <hr />
                <h5> {t('committee.chairDash.vote.currentVoteStat')} </h5>
                <div className="row">
                    <div className="col">
                        <fieldset disabled={currentVote.mode === "Auto"}>
                            <div className="row text-center">
                                <div className="col">
                                    <label><span className="badge badge-success"> {t('committee.chairDash.vote.for')} </span></label>
                                    <input className="form-control" type="number" placeholder="0" value={forInput || ""} onChange={handleForInput} />
                                </div>
                                <div className="col">
                                    <label><span className="badge badge-danger"> {t('committee.chairDash.vote.against')} </span></label>
                                    <input className="form-control" type="number" min={0} placeholder="0" value={againstInput || ""} onChange={handleAgainstInput} />
                                </div>
                                <div className="col">
                                    <label><span className="badge badge-warning"> {t('committee.chairDash.vote.abstain')} </span></label>
                                    <input className="form-control" type="number" disabled={currentVote.noAbstain} min={0} placeholder="0" value={abstainInput || ""} onChange={handleAbstainInput} />
                                </div>
                                <div className="col" style={{ alignSelf: "flex-end" }}>
                                    <button className="btn btn-sm btn-primary" onClick={handleSave}> {t('committee.chairDash.vote.save')} </button>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {currentVote.mode !== "Auto" ? <p className="my-2"> {t('committee.chairDash.vote.sentences.enterTheNumbersInTheTopField')} </p> : null}

                <hr />
                <h5> {t('committee.chairDash.vote.lastRollCall')} </h5>

                <MDBCollapse isOpen={true}>
                    {
                        !rollcallLoading ?
                            lastRollCall ? <RollCallStat lastRollCall={lastRollCall} /> : <p><span className="badge badge-warning"> {t('committee.chairDash.vote.noLastRollCall')} </span></p> :
                            <div className="text-center">
                                <div className="spinner-border text-dark">
                                    <span className="sr-only"> {t('loading')} </span>
                                </div>
                            </div>
                    }
                </MDBCollapse>
                <hr />

                <button className={"btn btn-sm btn-" + (shouldShowVoteResult ? "success" : "blue-grey")}
                    onClick={() => {
                        updateCommittee({
                            variables: {
                                options: {
                                    vote: {
                                        showVoteResult: !shouldShowVoteResult
                                    }
                                }
                            }
                        })
                    }}
                >
                    {
                        committeeOptions.showVoteResult ? t('committee.chairDash.vote.hideVoteResult') : t('committee.chairDash.vote.showVoteResult')
                    }
                </button>
                <button className="btn btn-sm btn-warning" onClick={() => setShowEndVoteModal(true)}> {t('committee.chairDash.vote.end')} </button>
            </div>
        )
    }

    return (
        <>
            <div className={"card " + (loading ? "loading" : "")} style={{ minHeight: "200px" }}>
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-auto">
                                <h5 className="card-title mb-0"> {t('committee.chairDash.vote.vote')} </h5>
                            </div>
                            <div className="col">
                                {
                                    currentVote ?
                                        <p className="mb-0">
                                            <span className="badge badge-info mx-1">{currentVote.topic}</span>
                                            {currentVote.noAbstain ? <span className="badge badge-warning mx-1">No Abstain</span> : null}
                                        </p> :
                                        <p className="text-muted"> {t('committee.chairDash.vote.sentences.selectATopicToVote')} </p>
                                }

                            </div>
                        </div>

                        {
                            currentVote ?
                                renderVoteStatPart()
                                :
                                renderCreateVotePart()
                        }
                    </div>
                    {/* <CreateVoteModal show={showCreateVoteModal} toggle={toggleCreateVoteModal} topicSelected={topicSelected} useAutoMode={useAutoMode} noAbstain={noAbstain} CMID={CMID} /> */}
                    <EndVoteModal
                        show={showEndVoteModal}
                        toggle={toggleEndVoteModal}
                        CMID={CMID}
                        currentVote={currentVote}
                        voteStat={voteStat}
                        onEndVote={() => setTopicSelected(JSON.stringify({ type: "none" }))}
                        lastRollCall={lastRollCall}
                    />
                </div>
            </div>

            <div className={`card ${loading ? "loading" : ""} ${currentVote && currentVote.mode === "Auto" ? "" : "d-none"}`}>
                <div className="card-body">
                    <div className="container-fluid">
                        <div className="row">
                            <h4>{t('committee.chairDash.vote.autoVoteResult')}</h4>
                            <div className='ml-4'>
                                <button className="btn btn-sm btn-danger" onClick={
                                    () => {
                                        // eslint-disable-next-line no-restricted-globals
                                        if (confirm("This action will clear all the current vote result.")) {
                                            clearVoteResult()
                                        }
                                    }
                                }> {t('committee.chairDash.vote.clearAllVote')} </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {
                        delegates.map((role) => {
                            if (lastRollCall && lastRollCall.attendee && !lastRollCall.attendee[role._id]) return null

                            const roleResult = (currentVote && currentVote.result && currentVote.result[role._id]);

                            const color = {
                                0: "success",
                                1: "danger",
                                2: "warning"
                            }

                            const textColor = {
                                0: "white",
                                1: "white",
                                2: "dark"
                            }

                            return (
                                <div className="row my-1" key={role._id}>
                                    <div className="col-8">
                                        <h5>{renderRoleName(role)}</h5>
                                    </div>
                                    <div className="col">
                                        <select
                                            className={`custom-select custom-select-sm bg-${color[roleResult] || "white"} text-${textColor[roleResult] || "dark"}`}
                                            value={roleResult >= 0 ? roleResult : "-1"}
                                            onChange={(e) => {
                                                updateSubmittedVote({
                                                    variables: {
                                                        VID: currentVoteId,
                                                        RID: role._id,
                                                        chosenOption: parseInt(e.target.value),
                                                    }
                                                });
                                            }}
                                        >
                                            <option className="bg-white text-dark" value="-1">Pending</option>
                                            <option className="bg-white text-dark" value="0">For</option>
                                            <option className="bg-white text-dark" value="1">Against</option>
                                            <option className="bg-white text-dark" value="2">Abstain</option>
                                        </select>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Vote
