import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import * as jwt_decode from "jwt-decode"
import { roleField, roleCode } from '../../constant'

export default class Home extends Component {

    state = {
        me: localStorage.getItem(roleField) ? jwt_decode(localStorage.getItem(roleField)) : null,
    }

    render() {
        const { me } = this.state;

        const handleLandingPage = () => {
            if (me) {
                switch (me.role) {
                    case roleCode.Delegate:
                    case roleCode.Admin:
                    case roleCode.Chair:
                        return <Redirect to="/committee" />
                    case roleCode.SystemAdmin:
                        return <Redirect to="/admin" />
                    default:
                        break;
                }
            }

            return null;
        }

        return (
            <div className="container text-center">
                <h1 className="display-3">Welcome to Munative</h1>
                <h4>An app that digitizes your MUN experience</h4>
                <h4 className="mt-5"><strong>{me ? "" : "Login and start using Munative"}</strong></h4>
                {
                    handleLandingPage()
                }
            </div>
        )
    }
}
