import React, { useEffect, useState } from 'react'
import * as jwt_decode from 'jwt-decode'

import { tokenField, roleField } from '../../constant'
import { alertNotify, successNotify } from '../modules/notification'

import { MUTATION_UPDATE_PASSWORD, MUTATION_VERIFY_RESET_PASSWORD_TOKEN } from '../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'

function UpdatePassword() {
    const history = useHistory();

    const [verifyResetPasswordTokenMutation] = useMutation(MUTATION_VERIFY_RESET_PASSWORD_TOKEN);

    const [user, setUser] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const [token, setToken] = useState(null);

    useEffect(() => {
        let params = (new URL(window.location)).searchParams;

        if (params.get('token')) {
            const token = params.get('token');
            verifyResetPasswordTokenMutation({
                variables: {
                    token,
                }
            }).then(({ data: { verifyResetPasswordToken } }) => {
                setUser(verifyResetPasswordToken.user);
                setToken(token);
            }).catch((err) => {
                if (err.graphQLErrors) {
                    setErrorMessages(err.graphQLErrors.map(({ message }) => message));
                }
            });
        } else if (localStorage.getItem(tokenField)) {
            setUser(jwt_decode(localStorage.getItem(tokenField)));
        } else {
            history.push('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [mutateUpdatePassword] = useMutation(MUTATION_UPDATE_PASSWORD);

    return (
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-12 col-md-5">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3>Update Password</h3>
                            {
                                errorMessages && errorMessages.length > 0 ?
                                    <>
                                        {
                                            errorMessages.map((msg, i) => {
                                                return <Fragment key={i}>
                                                    <h4><span className="badge badge-danger">{msg}</span></h4>
                                                    {
                                                        msg === 'invalid_token' ? <p>
                                                            Your have a invalid token, please try again or contact Munative team for assistant.
                                                        </p> : null
                                                    }
                                                </Fragment>
                                            })
                                        }

                                        <Link to="/" className="btn btn-primary">Go to Home page</Link>
                                    </>
                                    :
                                    user ? <>
                                        <p className="card-text">Update password of <span className="text-dark">{user.firstName} {user.lastName}</span>.</p>
                                        <hr />

                                        <form onSubmit={(e) => {
                                            e.preventDefault();

                                            if (newPassword.trim() === "") {
                                                alertNotify("Please enter your new password.");
                                                return;
                                            }

                                            if (newPassword !== confirmNewPassword) {
                                                alertNotify("Please confirm you have same password in the input fields.");
                                                return;
                                            }

                                            mutateUpdatePassword({
                                                variables: {
                                                    USID: user._id,
                                                    password: newPassword,
                                                    token
                                                }
                                            }).then(() => {
                                                successNotify("Password updated", "You have update your password successfully. Please use new password to login again.");
                                                localStorage.removeItem(tokenField);
                                                localStorage.removeItem(roleField);

                                                setTimeout(() => window.location.href = '/', 2000);
                                            })
                                        }}>
                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input className="form-control" value={newPassword} type="password" placeholder="New Password" id="newPassword" onChange={e => setNewPassword(e.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <label>New Password Again</label>
                                                <input className="form-control" value={confirmNewPassword} type="password" placeholder="New Password Again" id="confirmNewPassword" onChange={e => setConfirmNewPassword(e.target.value)} required />
                                            </div>

                                            <button className="btn btn-primary btn-block mt-4">Submit</button>
                                        </form>
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdatePassword
