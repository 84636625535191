import React, { useState, useMemo, useEffect } from 'react'
import * as jwt_decode from 'jwt-decode'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import update from 'immutability-helper'

import CountryFlags from '../../layout/countryFlags'
import './chairdash/loading.css'
import NoteSendingCard from './NoteSendingCard'
import { roleField, roleCode, munativePic } from '../../../constant'
import { renderRoleName, getDelegateMeta } from '../../modules/util'
import { QUERY_FETCH_ROLE_NOTES_READ } from '../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_NOTE } from '../../../graphql/subscriptions'

import useCommitteeRoles from './hooks/useCommitteeRoles'
import { useTranslation } from 'react-i18next'
import useCommitteeVote from './hooks/useCommitteeVote'
import useCommitteeOptions from './hooks/useCommitteeOptions'

const useUnreads = (me = {}) => {
    const { data, loading, subscribeToMore, updateQuery } = useQuery(QUERY_FETCH_ROLE_NOTES_READ, {
        variables: {
            RID: me._id
        },
        fetchPolicy: "cache-and-network",
        skip: !me
    });

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_UPDATE_NOTE,
            variables: {
                RID: me._id
            }, updateQuery: (prev, { subscriptionData: { data } }) => {
                if (data && data.noteUpdated) {
                    const { noteUpdated } = data;

                    const edges = (prev && prev.role && prev.role.props && prev.role.props.notes && prev.role.props.notes.edges) || [];

                    const index = edges.findIndex(({ node: { _id } }) => noteUpdated.node._id === _id);

                    let updatedQuery = {};

                    if (index === -1) {
                        updatedQuery = update(prev, {
                            role: {
                                props: {
                                    notes: {
                                        edges: {
                                            $unshift: [
                                                noteUpdated
                                            ]
                                        }
                                    }
                                }
                            }
                        });
                    } else {
                        updatedQuery = update(prev, {
                            role: {
                                props: {
                                    notes: {
                                        edges: {
                                            [index]: {
                                                $set: noteUpdated
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }

                    return updatedQuery;
                }
                return prev;
            }
        })
    }, [me, subscribeToMore]);

    const unreadCount = useMemo(() => {
        const count = {};

        const notes = (data && data.role && data.role.props && data.role.props.notes && data.role.props.notes.edges) || [];

        notes.filter(({ node: { readTime } }) => !readTime).forEach(({ node: { sender: { _id } } }) => {
            if (_id !== me._id)
                count[_id] = (count[_id] || 0) + 1;
        });

        return count;
    }, [data, me]);

    useEffect(() => {
        updateQuery((prev) => {
            if (prev) {
                const edges = (prev && prev.role && prev.role.props && prev.role.props.notes && prev.role.props.notes.edges) || [];

                const newEdges = edges.filter(({ node: { readTime } }) => !readTime);

                return update(prev, {
                    role: {
                        props: {
                            notes: {
                                edges: {
                                    $set: newEdges
                                }
                            }
                        }
                    }
                });
            }
        })
    }, [data, updateQuery]);

    return { loading, unreadCount };
}

function NoteSending() {
    const { t } = useTranslation();

    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const { unreadCount } = useUnreads(me);
    const { currentVote } = useCommitteeVote(this_CMID);

    const { id: currentChatId } = useParams();
    const history = useHistory();

    const { roles, chairs, delegates, admins, loading: cmRolesLoading } = useCommitteeRoles(this_CMID);
    const { committeeOptions } = useCommitteeOptions(this_CMID);

    const handleNoteRoleHeadClick = (id) => {
        history.push('/committee/note/' + id);
    }

    const renderNoteRoleHead = (role, unreadNumber) => {
        const representative = (role && role.role === roleCode.Delegate && role.detail.representative) || {};

        const { logoSrc, countryCode } = getDelegateMeta(representative);

        return (
            <div className="row">
                <div className="col-3">
                    <CountryFlags
                        src={logoSrc}
                        country={countryCode}
                        fallback={munativePic}
                        className="rounded float-left"
                        width={30} height={30} alt={renderRoleName(role)} />
                </div>
                <div className="col">
                    <span className="align-middle">{renderRoleName(role)}</span>
                </div>
                <div className="col-auto">
                    <span className="badge badge-primary">{unreadNumber ? unreadNumber : null}</span>
                </div>
            </div>
        )
    }

    const [searchText, setSearchText] = useState("");

    return (
        <div className="container-fluid px-lg-5" style={{
            position: 'fixed',
            top: '90px',
            height: 'calc(100% - 190px)'
        }}>
            <div className="row justify-content-md-center h-100">

                {/* Committee roles list */}
                <div className={`col-12 col-md-5 col-lg-3 d-md-block h-100 ${currentChatId ? "d-none" : ""}`}>
                    <div className={`card h-100 ${cmRolesLoading ? "loading" : ""}`}>
                        <div className="card-body h-100" style={{ overflowY: "auto" }}>
                            <h5 className="card-title">{t('committee.noteSending.noteList')}</h5>
                            <input type="text" className="form-control" placeholder={t('committee.noteSending.search')} onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                            <hr />
                            {
                                cmRolesLoading ?
                                    null :
                                    <div className="my-3">
                                        {
                                            unreadCount && Object.keys(unreadCount).length > 0 ? <><h5 className="my-2">{t('committee.noteSending.unread')}</h5>
                                                <ul className="list-group mt-3">
                                                    {
                                                        chairs.filter((v) => {
                                                            return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                        }).filter((v) => v._id !== me._id).filter(({ _id }) => unreadCount[_id]).map((data, index) => {
                                                            const { _id } = data;
                                                            return (
                                                                <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                                    key={index}
                                                                    onClick={() => handleNoteRoleHeadClick(_id)}>
                                                                    {renderNoteRoleHead(data, unreadCount[_id])}
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        admins.filter((v) => {
                                                            return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                        }).filter((v) => v._id !== me._id).filter(({ _id }) => unreadCount[_id]).map((data, index) => {
                                                            const { _id } = data;
                                                            return (
                                                                <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                                    key={index}
                                                                    onClick={() => handleNoteRoleHeadClick(_id)}>
                                                                    {renderNoteRoleHead(data, unreadCount[_id])}
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        delegates.filter((v) => {
                                                            return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                        }).filter((v) => v._id !== me._id).filter(({ _id }) => unreadCount[_id]).map((data, index) => {
                                                            const { _id } = data;
                                                            return (
                                                                <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                                    key={index}
                                                                    onClick={() => handleNoteRoleHeadClick(_id)}>
                                                                    {renderNoteRoleHead(data, unreadCount[_id])}
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul></> : null
                                        }
                                        <h5 className="my-2">{t('committee.noteSending.chairAdmin')}</h5>
                                        <ul className="list-group mt-3">
                                            {
                                                chairs.filter((v) => {
                                                    return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                }).filter((v) => v._id !== me._id).filter(({ _id }) => !unreadCount[_id]).map((data, index) => {
                                                    const { _id } = data;
                                                    return (
                                                        <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                            key={index}
                                                            onClick={() => handleNoteRoleHeadClick(_id)}>
                                                            {renderNoteRoleHead(data, unreadCount[_id])}
                                                        </li>
                                                    );
                                                })
                                            }
                                            {
                                                admins.filter((v) => {
                                                    return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                }).filter((v) => v._id !== me._id).filter(({ _id }) => !unreadCount[_id]).map((data, index) => {
                                                    const { _id } = data;
                                                    return (
                                                        <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                            key={index}
                                                            onClick={() => handleNoteRoleHeadClick(_id)}>
                                                            {renderNoteRoleHead(data, unreadCount[_id])}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        <h5 className="my-2">{t('committee.noteSending.delegates')}</h5>
                                        <ul className="list-group mt-3">
                                            {
                                                delegates.filter((v) => {
                                                    return searchText.trim() === "" || renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1;
                                                }).filter((v) => v._id !== me._id).filter(({ _id }) => !unreadCount[_id]).map((data, index) => {
                                                    const { _id } = data;
                                                    return (
                                                        <li className={"list-group-item list-group-item-action " + (currentChatId === _id ? "active" : "")}
                                                            key={index}
                                                            onClick={() => handleNoteRoleHeadClick(_id)}>
                                                            {renderNoteRoleHead(data, unreadCount[_id])}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Note sending card */}
                {committeeOptions && committeeOptions.disableNoteSending ?
                    <div className={`col-12 col-md-7 col-lg-9 d-md-block h-100 text-center ${!currentChatId ? "d-none" : ""}`}>
                        <h1 className="mt-5">
                            <span className="badge badge-pill badge-warning">Note sending is disabled in this committee.</span>
                        </h1>
                    </div>
                    : <div className={`col-12 col-md-7 col-lg-9 d-md-block h-100 ${!currentChatId ? "d-none" : ""}`}>
                        <NoteSendingCard isVoting={!!currentVote} CMID={this_CMID} me={me} currentChatRole={roles ? roles.find((v) => v._id === currentChatId) : null} />
                    </div>}
            </div >
        </div >
    )
}

export default NoteSending
