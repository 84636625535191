import React, { useState, useEffect } from 'react'

function ToggleSwitch({ onLabel = "On", offLabel = "Off", color = "primary", value = false, onChange }) {

    const [isOn, setIsOn] = useState(value);

    useEffect(() => {
        setIsOn(value);
    }, [value]);

    return (
        <div className="btn-group btn-group-toggle">
            <label className={"btn btn-sm btn-" + (!isOn ? "outline-" : "") + color}>
                <input type="radio" checked={isOn} onChange={() => {
                    setIsOn(true);
                    onChange(true);
                }} /> {onLabel}
            </label>
            <label className={"btn btn-sm btn-" + (isOn ? "outline-" : "") + color}>
                <input type="radio" checked={!isOn} onChange={() => {
                    setIsOn(false);
                    onChange(false);
                }} /> {offLabel}
            </label>
        </div>
    )
}

export default ToggleSwitch
