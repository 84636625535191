import React, { Fragment } from 'react'
import { attendStatusCode } from '../../../../constant'
import { useTranslation } from 'react-i18next'

const calculate = (lastRollCall) => {
    let lastRollCallStat = {
        [attendStatusCode.Absent]: 0,
        [attendStatusCode.Present]: 0,
        [attendStatusCode.PresentAndVoting]: 0
    }
    let lastRollCallTotalNum = 0;

    if (lastRollCall && lastRollCall.attendee) {
        Object.keys(lastRollCall.attendee).forEach((key) => {
            lastRollCallStat[lastRollCall.attendee[key]]++;
            lastRollCallTotalNum++;
        });
    }

    const calcStat = {
        half: lastRollCallTotalNum > 0 ? Math.floor((lastRollCallStat[attendStatusCode.Present] + lastRollCallStat[attendStatusCode.PresentAndVoting]) / 2) + 1 : 0,
        twoThird: lastRollCallTotalNum > 0 ? Math.floor((lastRollCallStat[attendStatusCode.Present] + lastRollCallStat[attendStatusCode.PresentAndVoting]) / 3 * 2) + 1 : 0
    }

    return { lastRollCallStat, lastRollCallTotalNum, calcStat };
}

export const rollStatCalculate = calculate;

export default function RollCallStat(props) {
    let { lastRollCall } = props;

    const { t } = useTranslation()
    const { lastRollCallStat, lastRollCallTotalNum, calcStat } = calculate(lastRollCall);

    return (
        <Fragment>
            <div className="row">
                <div className="col"><h5> {t('committee.chairDash.vote.absent')} </h5></div>
                <div className="col">
                    <h5>
                        <span className="badge badge-warning">
                            {lastRollCallStat[attendStatusCode.Absent]} ({Math.floor(lastRollCallStat[attendStatusCode.Absent] / lastRollCallTotalNum * 100)}%)
                        </span>
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col"><h5> {t('committee.chairDash.vote.present')} </h5></div>
                <div className="col">
                    <h5>
                        <span className="badge badge-info">
                            {lastRollCallStat[attendStatusCode.Present]} ({Math.floor(lastRollCallStat[attendStatusCode.Present] / lastRollCallTotalNum * 100)}%)
                        </span>
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col"><h5> {t('committee.chairDash.vote.presentAndVoting')} </h5></div>
                <div className="col">
                    <h5>
                        <span className="badge badge-success">
                            {lastRollCallStat[attendStatusCode.PresentAndVoting]} ({Math.floor(lastRollCallStat[attendStatusCode.PresentAndVoting] / lastRollCallTotalNum * 100)}%)
                        </span>
                    </h5>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col"><h5> {t('committee.rollCall.haldStat')} </h5></div>
                <div className="col">
                    <h5>
                        <span className="badge badge-dark">
                            {lastRollCallTotalNum > 0 ? calcStat.half : "n/a"}
                        </span>
                    </h5>
                </div>
            </div>
            <div className="row">
                <div className="col"><h5> {t('committee.rollCall.twoThirdStat')} </h5></div>
                <div className="col">
                    <h5>
                        <span className="badge badge-dark">
                            {lastRollCallTotalNum > 0 ? calcStat.twoThird : "n/a"}
                        </span>
                    </h5>
                </div>
            </div>
            <p className="text-muted mb-0">
                {new Date(lastRollCall.time).toLocaleString()}
            </p>
        </Fragment>
    )
}
