import React, { useState } from 'react'
import { useMutation } from 'react-apollo';
import { alertNotify, errorNotify, successNotify } from '../modules/notification';

import { MUTATION_USER_RESET_PASSWORD } from '../../graphql/mutations'

function ResetPassword() {

    const [email, setEmail] = useState('');

    const [userResetPasswordMutation, { loading: resetPasswordMutationLoading }] = useMutation(MUTATION_USER_RESET_PASSWORD);

    return (
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-12 col-md-5">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3>Reset password</h3>
                            <p className="text-muted">Enter your email to request password reset</p>
                            <hr />
                            <form onSubmit={(e) => {
                                e.preventDefault();

                                if (email.trim() === "") {
                                    alertNotify("Please enter your email.");
                                    return;
                                }

                                userResetPasswordMutation({
                                    variables: {
                                        email,
                                    }
                                }).then(() => {
                                    successNotify('A password reset link had send to your email, use it to reset your password.');
                                }).catch((err) => {
                                    if (err.graphQLErrors) {
                                        err.graphQLErrors.forEach((error) => {
                                            errorNotify(error.message);
                                        });
                                    }
                                });
                            }}>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input className="form-control" value={email} type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} required />
                                </div>

                                <button className="btn btn-primary btn-block mt-4" disabled={resetPasswordMutationLoading}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
