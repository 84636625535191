import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import Links from './Links'
import { useTranslation } from 'react-i18next'

import { MDBNavbar, MDBNavbarBrand, MDBNavbarToggler } from 'mdbreact'
import { getSocketStatus, getPingStatus } from '../../../index'

import { munativePic } from '../../../constant'

function NavBar(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [keeper, setKeeper] = useState(0);
    const wrapperRef = useRef(null);
    const { t } = useTranslation();

    const handleClickOutside = (event) => {
        const matchClassName = !event.target.className || !event.target.className.match || (event.target.className.match("nav-link") || event.target.className.match("close-nav"));
        if (matchClassName || (wrapperRef && !ReactDOM.findDOMNode(wrapperRef.current).contains(event.target))) {
            setIsOpen(false);
        }
    }

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        setInterval(() => {
            setKeeper(Math.random());
        }, 1000);

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }

    }, [])

    let wsText = "";
    let wsColor = "";
    switch (getSocketStatus()) {
        case -1:
        case -2:
            wsText = t('navBar.connecting');
            wsColor = "warning";
            break;
        case -3:
            wsText = t('navBar.disconnected');
            wsColor = "danger";
            break;
        default:
            const ping = getPingStatus();
            if (ping >= 500) { 
                wsText = `${t('navBar.slowConnection')} ${ping.toFixed(0)}ms`;
                wsColor = "danger";
            }
    }

    return (
        <MDBNavbar color="white" className="fixed-top" light expand="lg" ref={wrapperRef}>
            <span className="d-none">{keeper}</span>
            <MDBNavbarBrand>
                <Link to='/' className="text-dark">
                    <img src={munativePic} width="30" height="30" alt="" className="rounded mr-2" />
                    <strong>Munative</strong>
                </Link>
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={toggleCollapse} />
            <Links links={props.links} navOnlyLinks={props.navOnlyLinks} open={isOpen} statusText={wsText} statusColor={wsColor} />
        </MDBNavbar>
    )
}

export default NavBar