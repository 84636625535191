import React from 'react'

import { munativePic } from '../../../constant'

function Home() {
    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-2 text-center">
                                    <img width="100px" height="100px" src={munativePic} className="rounded" alt="" />
                                </div>
                                <div className="col-12 col-md">
                                    <h2 className="card-title">Munative</h2>
                                    <p>Description of your organization.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div className="card child-no-margin">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col">
                                    <h3 className="card-title">Conferences</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <h5>2020 CMS MUN Workshop <span className="badge badge-success">Ongoing</span></h5>
                                    <p className="text-muted">{new Date().toLocaleString()} - {new Date().toLocaleString()}</p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col">
                                    <h5>2020 CMS MUN <span className="badge badge-warning">Next</span></h5>
                                    <p className="text-muted">{new Date().toLocaleString()} - {new Date().toLocaleString()}</p>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="row">
                                <div className="col">
                                    <h5>conf.name</h5>
                                    <p className="text-muted">{new Date().toLocaleString()} - {new Date().toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">About</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ut nibh sed nibh viverra interdum ac vitae arcu. Nam dolor ante, ultricies id sollicitudin ac, molestie ut dui. Ut id dolor auctor, commodo metus quis, vehicula arcu.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
