import { useMemo } from 'react'
import { useQuery } from 'react-apollo'
import { QUERY_FETCH_COMMITTEE } from '../../../../graphql/queries'

function useCommittee(CMID) {
    const { data, loading } =
        useQuery(QUERY_FETCH_COMMITTEE, {
            variables: {
                CMID
            },
            fetchPolicy: "cache-and-network",
            skip: !CMID
        });


    const { committee } = useMemo(() => ({ ...(data || {}) }), [data]);


    return { loading, committee};
}

export default useCommittee