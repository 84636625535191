import { useQuery } from '@apollo/react-hooks'
import { QUERY_FETCH_COMMITTEE } from  '../../../../graphql/queries'


const useCommitteeParentConference = (CMID) => {
    const { data, loading, refetch } = useQuery(QUERY_FETCH_COMMITTEE, {
        variables: {
            CMID
        },
        fetchPolicy: "cache-and-network",
        skip: !CMID
    });

    const committee = (data && data.committee) || {};
    const conference = (data && data.committee && data.committee.conference) || {};

    return {committee, conference, loading, refetch}

}

export default useCommitteeParentConference