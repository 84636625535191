import React, { useMemo } from 'react'
import { renderRoleName } from '../../../modules/util'
import { useTranslation } from 'react-i18next'

function RollCall({ committeeRoles, lastRollCall }) {

    const attendee = useMemo(() => lastRollCall && lastRollCall.attendee ? lastRollCall.attendee : {}, [lastRollCall]);

    const { t } = useTranslation();

    const lists = useMemo(() => {
        let absentList = [];
        let presentList = [];
        let presentAndVotingList = [];
        let noDataList = [];

        if (committeeRoles) {
            committeeRoles.forEach((v) => {
                const attendStatus = attendee[v._id] || attendee[v._id] === 0 ? attendee[v._id] : null;

                if (attendStatus === 0) {
                    absentList.push(v);
                } else if (attendStatus === 1) {
                    presentList.push(v);
                } else if (attendStatus === 2) {
                    presentAndVotingList.push(v);
                } else {
                    noDataList.push(v);
                }

            });
        }

        return {
            absentList,
            presentList,
            presentAndVotingList,
            noDataList
        }
    }, [committeeRoles, attendee]);


    return (
        <div className="card">
            <div className="card-body text-center">
                <h1 className="card-title">{t("committee.display.rollCall.rollCall")}</h1>
                <hr />
                <div className="row justify-content-center">
                    {
                        lists.noDataList.map((v, i) => {
                            return (
                                <div className="col-auto" key={i}>
                                    <h6>
                                        <span className="text-muted">{renderRoleName(v)}</span>
                                    </h6>
                                </div>
                            )

                        })
                    }
                </div>
                <div className="row justify-content-center">
                    <div className="col-4">
                        <h3><span className="badge badge-warning">{t("committee.display.rollCall.absent")}</span></h3>
                        {
                            lists.absentList.map((v, i) => {
                                return <div key={i}>
                                    <h4>
                                        <span >{renderRoleName(v)}</span>
                                    </h4>
                                </div>
                            })
                        }
                    </div>
                    <div className="col-4">
                        <h3><span className="badge badge-info">{t("committee.display.rollCall.present")}</span></h3>
                        {
                            lists.presentList.map((v, i) => {
                                return <div key={i}>
                                    <h4>
                                        <span >{renderRoleName(v)}</span>
                                    </h4>
                                </div>
                            })
                        }
                    </div>
                    <div className="col-4">
                        <h3><span className="badge badge-success">{t("committee.display.rollCall.presentAndVoting")}</span></h3>
                        {
                            lists.presentAndVotingList.map((v, i) => {
                                return <div key={i}>
                                    <h4>
                                        <span >{renderRoleName(v)}</span>
                                    </h4>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RollCall
