import React from 'react'
import { FacebookProvider, Like } from 'react-facebook'
import { SocialIcon } from 'react-social-icons';

function Feedback() {
    return (
        <div className="container text-center">
            <div className="row my-2">
                <div className="col-12">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSefH4ycC9y8p3gOElkUNexw4FO-JxRsVd_h6JRsqhxAwCMuig/viewform?embedded=true"
                        style={{ height: "calc(100vh - 350px)", margin: 0, width: "100%", border: 0 }} width="100%" title="munativeFeedbackForm">Loading…</iframe>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <div className="my-2">
                        <h4>Please give us a like! <span className="text-nowrap">請幫我們按讚！</span></h4>
                    </div>
                    <FacebookProvider appId="1917950648494471">
                        <Like href="http://www.facebook.com/munative" width="200" size="small" />
                    </FacebookProvider>
                </div>
            </div>
            <div className="row mt-2 mb-3">
                <div className="col-12">
                    <div className="mx-3 d-inline">
                        <SocialIcon url="https://www.facebook.com/munative" />
                    </div>
                    <div className="mx-3 d-inline">
                        <SocialIcon url="https://www.instagram.com/munativeteam/" />
                    </div>
                    <div className="mx-3 d-inline">
                        <SocialIcon url="https://www.linkedin.com/company/munative" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback;