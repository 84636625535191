/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import * as jwt_decode from "jwt-decode"
import FlipMove from "react-flip-move"

import { roleField, munativePic, attendStatusCode } from "../../../constant"
import { renderRoleName } from "../../modules/util"
import CountryFlags from "../../layout/countryFlags"
import "./chairdash/loading.css"

import useSpeakersList from "./hooks/useSpeakersList"
import useCommitteeRoles from "./hooks/useCommitteeRoles"
import useCommitteeRollCall from "./hooks/useCommitteeRollCall"
import { useTranslation } from "react-i18next"

const renderNoteRoleHead = (role) => {
  const countryCode =
    role && role.detail.representative
      ? role.detail.representative.code
        ? role.detail.representative.code
        : role.detail.representative
      : ""

  return (
    <div className="row">
      <div className="col-12 col-lg-3 col-xl-2 mr-xl-0">
        <CountryFlags
          country={countryCode}
          fallback={munativePic}
          className="rounded float-left"
          width={30}
          height={30}
          alt={renderRoleName(role)}
        />
      </div>
      <div className="col">
        <span className="align-middle">{renderRoleName(role)}</span>
      </div>
    </div>
  )
}

function SpeakersList() {
  const { t } = useTranslation()
  const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), [])
  const this_CMID = me.roleTarget
  const [index, setIndex] = useState(0)

  const [timePerSpeakerInput, setTimePerSpeakerInput] = useState("")

  let {
    loading,
    timePerSpeaker,
    setSpeakersListSetting,
    allSpeakerLists,
    loading: speakersListLoading,
    setSpeakersList,
    currentListName,
    setCurrentList,
  } = useSpeakersList(this_CMID)

  useEffect(() => {
    setTimePerSpeakerInput(timePerSpeaker || 0)
    setIndex(currentListName === "secondary" ? 1 : 0)
  }, [timePerSpeaker, currentListName])

  const handleSettingSaveOnClick = () => {
    let tps =
      isNaN(timePerSpeakerInput) && timePerSpeakerInput.trim() !== ""
        ? 0
        : parseInt(timePerSpeakerInput)

    setSpeakersListSetting({
      timePerSpeaker: tps,
    })
  }

  return (
    <div className="container-fluid">
      <div className=" justify-content-center">
        <div className="row mb-3">
          <div className="col-auto">
            <Link className="btn btn-dark btn-sm mb-3" to="/committee/chair">
              {t("committee.speakerList.goBack")}
            </Link>
          </div>

          {/* time per speaker */}
          <div className="col">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {t("committee.speakerList.timePerSpeaker")}
                </span>
              </div>
              <input
                type="number"
                id="perSpeaker"
                className="form-control"
                placeholder="0"
                onChange={(e) => setTimePerSpeakerInput(e.target.value)}
                value={timePerSpeakerInput}
                disabled={loading}
              />
              <div className="input-group-append">
                <span className="input-group-text">{t("committee.speakerList.sec")}</span>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="col-auto">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleSettingSaveOnClick}
              disabled={loading}
            >
              {t("committee.speakerList.saveTimer")}
            </button>
          </div>

          <div className="col-auto">
            <b>{t("committee.chairDash.speakerList.setCurrent")}</b>
            <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
              <button
                type="button"
                disabled={loading}
                className={
                  "btn btn-sm " +
                  (!loading && currentListName === "default" ? "btn-success" : "btn-blue-grey")
                }
                onClick={() => {
                  if (currentListName !== "default") {
                    setCurrentList("default")
                    setIndex(0)
                  }
                }}
              >
                {t("committee.chairDash.speakerList.primary")}
              </button>
              <button
                type="button"
                disabled={loading}
                className={
                  "btn btn-sm " +
                  (!loading && currentListName === "secondary" ? "btn-warning" : "btn-blue-grey")
                }
                onClick={() => {
                  if (currentListName !== "secondary") {
                    setCurrentList("secondary")
                    setIndex(1)
                  }
                }}
              >
                {t("committee.chairDash.speakerList.secondary")}
              </button>
            </div>
          </div>
        </div>

        <ul className="nav nav-tabs mx-5" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className={index === 0 ? "nav-link active" : "nav-link "}
              id="primary-tab"
              data-toggle="tab"
              role="tab"
              onClick={() => setIndex(0)}
              style={!loading && currentListName === "default" ? { fontWeight: "bold" } : {}}
            >
              {t("committee.chairDash.speakerList.primary")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={index === 1 ? "nav-link active" : "nav-link "}
              id="secondary-tab"
              data-toggle="tab"
              role="tab"
              onClick={() => setIndex(1)}
              style={!loading && currentListName === "secondary" ? { fontWeight: "bold" } : {}}
            >
              {t("committee.chairDash.speakerList.secondary")}
            </a>
          </li>
        </ul>

        <div
          className="tab-content mx-5"
          id="myTabContent"
          style={{
            background: "white",
            borderLeft: "solid 1px #dee2e6",
            borderRight: "solid 1px #dee2e6",
            borderBottom: "solid 1px #dee2e6",
            borderRadius: "2px",
          }}
        >
          <div
            className={"tab-pane fade py-5 " + (index === 0 ? "show active" : "")}
            id="home"
            role="tabpanel"
          >
            <PrimaryList
              speakersList={(allSpeakerLists && allSpeakerLists.default) || []}
              setSpeakersList={setSpeakersList}
              loading={speakersListLoading}
              current={currentListName === "default"}
            />
          </div>
          <div
            className={"tab-pane fade py-5 " + (index === 1 ? "show active" : "")}
            id="profile"
            role="tabpanel"
          >
            <SecondaryList
              speakersList={(allSpeakerLists && allSpeakerLists.secondary) || []}
              setSpeakersList={setSpeakersList}
              loading={speakersListLoading}
              current={currentListName === "secondary"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpeakersList

function PrimaryList({ speakersList, loading: speakersListLoading, setSpeakersList, current }) {
  const { t } = useTranslation()

  const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), [])
  const this_CMID = me.roleTarget

  speakersList = speakersList || []

  const handleBtnSpeakersList = (speakerId, add) => {
    let newSpeakersList = speakersList
    const index = speakersList.findIndex((v) => v === speakerId)

    if (index !== -1) {
      newSpeakersList.splice(index, 1)
    }

    if (add) {
      newSpeakersList.push(speakerId)
    }

    setSpeakersList(newSpeakersList, "default")
  }

  const handleMoveSelectedList = (index, direction) => {
    let newSpeakerList = speakersList
    let temp = newSpeakerList[index + direction]
    newSpeakerList[index + direction] = newSpeakerList[index]
    newSpeakerList[index] = temp

    setSpeakersList(newSpeakerList, "default")
  }

  const handleClearSpeakersList = () => {
    setSpeakersList([], "default")
  }

  // committee roles, committee roll call
  const { roles, delegates, loading: cmRolesLoading } = useCommitteeRoles(this_CMID)
  const { lastRollCall, loading: cmRollCallLoading } = useCommitteeRollCall(this_CMID)

  const [searchText, setSearchText] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const searchRef = useRef(null)
  const controlRef = useRef(null)

  const availableRoles = roles
    ? (() => {
        let availableRoles = delegates

        if (searchText.trim().length > 0) {
          availableRoles = availableRoles.filter(
            (v) => renderRoleName(v).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          )
        }

        if (lastRollCall && lastRollCall.attendee) {
          const { attendee } = lastRollCall
          availableRoles = availableRoles.filter((v) => attendee[v._id] !== attendStatusCode.Absent)
        }

        if (speakersList) {
          availableRoles = availableRoles.map((v) => {
            return {
              ...v,
              repeat: speakersList.findIndex((speakerId) => v._id === speakerId) !== -1,
            }
          })
        }

        return availableRoles
      })()
    : []

  // render UI

  const renderRolesList = (roles, isSelectedList) => {
    if (isSelectedList) {
      return (
        <ul className="list-group">
          <FlipMove>
            {roles.map((v, i) => {
              return (
                <li className="list-group-item" key={v._id}>
                  <div className="row">
                    <div className="col">{renderNoteRoleHead(v)}</div>
                    <div className="col-auto" style={{ alignSelf: "center" }}>
                      {i !== 0 ? (
                        <span
                          id="arrorBtn"
                          className="p-1 mr-1"
                          onClick={() => handleMoveSelectedList(i, -1)}
                        >
                          <i className="material-icons">keyboard_arrow_up</i>
                        </span>
                      ) : null}
                      {i !== roles.length - 1 ? (
                        <span
                          id="arrorBtn"
                          className="p-1 mr-1"
                          onClick={() => handleMoveSelectedList(i, 1)}
                        >
                          <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                      ) : null}
                      <span
                        id="arrorBtn"
                        className="p-1 ml-2"
                        onClick={() => handleBtnSpeakersList(v._id, false)}
                      >
                        <i className="material-icons">keyboard_arrow_right</i>
                      </span>
                    </div>
                  </div>
                </li>
              )
            })}
          </FlipMove>
        </ul>
      )
    } else {
      return (
        <ul className="list-group">
          <FlipMove>
            {roles.map((v, i) => {
              return (
                <li
                  className="list-group-item"
                  key={v._id}
                  style={{ backgroundColor: selectedIndex === i ? "#ccf0ff" : "white" }}
                >
                  <div className="row" style={v.repeat ? { opacity: ".5" } : {}}>
                    <div className="col-auto" style={{ alignSelf: "center" }}>
                      <span
                        id="arrorBtn"
                        className="p-1"
                        onClick={() => handleBtnSpeakersList(v._id, true)}
                      >
                        <i className="material-icons">keyboard_arrow_left</i>
                      </span>
                    </div>
                    <div className="col">{renderNoteRoleHead(v)}</div>
                  </div>
                </li>
              )
            })}
          </FlipMove>
        </ul>
      )
    }
  }

  return (
    <div className="col m-auto col-lg-8">
      <div className="row">
        <div className="col-6">
          <div className={"card " + (speakersListLoading ? " loading" : "")}>
            <div className="card-body">
              <div className="row">
                <div className="col-auto" style={{ alignSelf: "center" }}>
                  <h5 className="card-title mb-0">
                    {t("committee.chairDash.speakerList.primary") +
                      "-" +
                      t("committee.speakerList.selected")}
                  </h5>
                </div>
                <div className="col">
                  <button
                    className="btn btn-danger btn-sm float-right"
                    onClick={handleClearSpeakersList}
                    disabled={speakersList.length === 0}
                  >
                    {t("committee.chairDash.speakerList.reset")}
                    {/* {t("committee.speakerList.resetList")} */}
                  </button>
                </div>
              </div>

              <hr />
              {speakersList
                ? renderRolesList(
                    speakersList.map((id) => roles.find((v) => v._id === id)).filter((v) => !!v),
                    true
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-auto" style={{ alignSelf: "center" }}>
                  <h5 className="card-title mb-0">{t("committee.speakerList.available")}</h5>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("committee.speakerList.search")}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        e.preventDefault()
                        controlRef.current.focus()
                        setSelectedIndex(0)
                      }
                    }}
                    value={searchText}
                    ref={searchRef}
                  />

                  <input
                    style={{ opacity: "0", pointerEvents: "none", position: "absolute" }}
                    value={selectedIndex}
                    onBlur={(e) => {
                      setSelectedIndex(-1)
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault()
                      if (e.key === "ArrowDown" && selectedIndex < availableRoles.length - 1) {
                        setSelectedIndex(selectedIndex + 1)
                        console.log(availableRoles[selectedIndex])
                      } else if (e.key === "ArrowUp") {
                        if (selectedIndex === 0) {
                          searchRef.current.focus()
                          setSelectedIndex(-1)
                        } else {
                          setSelectedIndex(selectedIndex - 1)
                        }
                      } else if (e.key === "Enter" && !availableRoles[selectedIndex].repeat) {
                        handleBtnSpeakersList(availableRoles[selectedIndex]._id, true)
                      } else {
                        searchRef.current.focus()
                        setSelectedIndex(-1)
                      }
                    }}
                    ref={controlRef}
                  />
                </div>
              </div>
              <hr />
              {cmRolesLoading || cmRollCallLoading ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                </div>
              ) : availableRoles ? (
                renderRolesList(availableRoles)
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SecondaryList({ speakersList, loading: speakersListLoading, setSpeakersList }) {
  const { t } = useTranslation()

  const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), [])
  const this_CMID = me.roleTarget

  speakersList = speakersList || []

  const handleBtnSpeakersList = (speakerId, add) => {
    let newSpeakersList = speakersList
    const index = speakersList.findIndex((v) => v === speakerId)

    if (index !== -1) {
      newSpeakersList.splice(index, 1)
    }

    if (add) {
      newSpeakersList.push(speakerId)
    }

    setSpeakersList(newSpeakersList, "secondary")
  }

  const handleMoveSelectedList = (index, direction) => {
    let newSpeakerList = speakersList
    let temp = newSpeakerList[index + direction]
    newSpeakerList[index + direction] = newSpeakerList[index]
    newSpeakerList[index] = temp

    setSpeakersList(newSpeakerList, "secondary")
  }

  const handleClearSpeakersList = () => {
    setSpeakersList([], "secondary")
  }

  // committee roles, committee roll call
  const { roles, delegates, loading: cmRolesLoading } = useCommitteeRoles(this_CMID)
  const { lastRollCall, loading: cmRollCallLoading } = useCommitteeRollCall(this_CMID)

  const [searchText, setSearchText] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const searchRef = useRef(null)
  const controlRef = useRef(null)

  const availableRoles = roles
    ? (() => {
        let availableRoles = delegates

        if (searchText.trim().length > 0) {
          availableRoles = availableRoles.filter(
            (v) => renderRoleName(v).toLowerCase().search(searchText.toLowerCase()) !== -1
          )
        }

        if (lastRollCall && lastRollCall.attendee) {
          const { attendee } = lastRollCall
          availableRoles = availableRoles.filter((v) => attendee[v._id] !== attendStatusCode.Absent)
        }

        if (speakersList) {
          availableRoles = availableRoles.map((v) => {
            return {
              ...v,
              repeat: speakersList.findIndex((speakerId) => v._id === speakerId) !== -1,
            }
          })
        }

        return availableRoles
      })()
    : []

  // render UI

  const renderRolesList = (roles, isSelectedList) => {
    if (isSelectedList) {
      return (
        <ul className="list-group">
          <FlipMove>
            {roles.map((v, i) => {
              return (
                <li className="list-group-item" key={v._id}>
                  <div className="row">
                    <div className="col">{renderNoteRoleHead(v)}</div>
                    <div className="col-auto" style={{ alignSelf: "center" }}>
                      {i !== 0 ? (
                        <span
                          id="arrorBtn"
                          className="p-1 mr-1"
                          onClick={() => handleMoveSelectedList(i, -1)}
                        >
                          <i className="material-icons">keyboard_arrow_up</i>
                        </span>
                      ) : null}
                      {i !== roles.length - 1 ? (
                        <span
                          id="arrorBtn"
                          className="p-1 mr-1"
                          onClick={() => handleMoveSelectedList(i, 1)}
                        >
                          <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                      ) : null}
                      <span
                        id="arrorBtn"
                        className="p-1 ml-2"
                        onClick={() => handleBtnSpeakersList(v._id, false)}
                      >
                        <i className="material-icons">keyboard_arrow_right</i>
                      </span>
                    </div>
                  </div>
                </li>
              )
            })}
          </FlipMove>
        </ul>
      )
    } else {
      return (
        <ul className="list-group">
          <FlipMove>
            {roles.map((v, i) => {
              return (
                <li
                  className="list-group-item"
                  key={v._id}
                  style={{ backgroundColor: selectedIndex === i ? "#ccf0ff" : "white" }}
                >
                  <div className="row" style={v.repeat ? { opacity: ".5" } : {}}>
                    <div className="col-auto" style={{ alignSelf: "center" }}>
                      <span
                        id="arrorBtn"
                        className="p-1"
                        onClick={() => handleBtnSpeakersList(v._id, true)}
                      >
                        <i className="material-icons">keyboard_arrow_left</i>
                      </span>
                    </div>
                    <div className="col">{renderNoteRoleHead(v)}</div>
                  </div>
                </li>
              )
            })}
          </FlipMove>
        </ul>
      )
    }
  }

  return (
    <div className="col m-auto col-lg-8">
      <div className="row">
        <div className="col-6">
          <div className={"card " + (speakersListLoading ? " loading" : "")}>
            <div className="card-body">
              <div className="row">
                <div className="col-auto" style={{ alignSelf: "center" }}>
                  <h5 className="card-title mb-0">
                    {t("committee.chairDash.speakerList.secondary") +
                      "-" +
                      t("committee.speakerList.selected")}
                  </h5>
                </div>
                <div className="col">
                  <button
                    className="btn btn-danger btn-sm float-right"
                    onClick={handleClearSpeakersList}
                    disabled={speakersList.length === 0}
                  >
                    {t("committee.chairDash.speakerList.reset")}
                    {/* {t("committee.speakerList.resetList")} */}
                  </button>
                </div>
              </div>

              <hr />
              {speakersList
                ? renderRolesList(
                    speakersList.map((id) => roles.find((v) => v._id === id)).filter((v) => !!v),
                    true
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-auto" style={{ alignSelf: "center" }}>
                  <h5 className="card-title mb-0">{t("committee.speakerList.available")}</h5>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("committee.speakerList.search")}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        e.preventDefault()
                        controlRef.current.focus()
                        setSelectedIndex(0)
                      }
                    }}
                    value={searchText}
                    ref={searchRef}
                  />

                  <input
                    style={{ opacity: "0", pointerEvents: "none", position: "absolute" }}
                    value={selectedIndex}
                    onBlur={(e) => {
                      setSelectedIndex(-1)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" && selectedIndex < availableRoles.length - 1) {
                        setSelectedIndex(selectedIndex + 1)
                        console.log(availableRoles[selectedIndex])
                      }

                      if (e.key === "ArrowUp") {
                        if (selectedIndex === 0) {
                          searchRef.current.focus()
                          setSelectedIndex(-1)
                        } else {
                          setSelectedIndex(selectedIndex - 1)
                        }
                      }

                      if (e.key === "Enter" && !availableRoles[selectedIndex].repeat) {
                        handleBtnSpeakersList(availableRoles[selectedIndex]._id, true)
                      }
                      e.preventDefault()
                    }}
                    ref={controlRef}
                  />
                </div>
              </div>
              <hr />
              {cmRolesLoading || cmRollCallLoading ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">{t("loading")}</span>
                  </div>
                </div>
              ) : availableRoles ? (
                renderRolesList(availableRoles)
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
