import React, { useState, useEffect } from 'react'
import { MUTATION_LOGIN, MUTATION_LOGIN_CODE_LOGIN } from '../../graphql/mutations'
import { errorNotify } from '../modules/notification'
import { tokenField, roleField } from '../../constant'
import { useLocation, useHistory, Switch, Route, Redirect, Link } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginCode, setLoginCode] = useState("");
    const [login] = useMutation(MUTATION_LOGIN)
    const [loginCodeLogin] = useMutation(MUTATION_LOGIN_CODE_LOGIN)
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const query = useQuery();
    const loginCodePrefill = query.get("code");

    useEffect(() => {
        if (loginCodePrefill) {
            setLoginCode(loginCodePrefill);
        }
    }, [loginCodePrefill]);

    const handleSubmitEmailAndPassword = (event) => {
        event.preventDefault();

        login({
            variables: {
                email: email,
                password: password
            }
        }).then(
            data => {
                localStorage.clear();
                localStorage.setItem(tokenField, data.data.userLogin.token);
                window.location.href = "/auth/roles";
            }
        ).catch(
            res => {
                if (res.graphQLErrors) {
                    res.graphQLErrors.map(error => errorNotify("Error", error.message))
                } else {
                    errorNotify("Error", "System is currently unavailable.")
                }
            }
        );
    }

    const handleSubmitCode = (event) => {
        event.preventDefault();

        loginCodeLogin({
            variables: {
                loginCode: loginCode
            }
        }).then(
            data => {
                localStorage.clear();
                localStorage.setItem(tokenField, data.data.loginCodeLogin.userToken);
                localStorage.setItem(roleField, data.data.loginCodeLogin.token);
                window.location.href = "/";
            }
        ).catch(
            res => {
                if (res.graphQLErrors) {
                    res.graphQLErrors.map(error => errorNotify("Error", error.message))
                } else {
                    errorNotify("Error", "System is currently unavailable.")
                }
            }
        );
    }

    return (
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-12 col-md-4">
                    <div className="card">
                        <div className="card-body text-center">
                            {
                                location.pathname !== "/auth/signin/select" ?
                                    <div className="text-left">
                                        <span className="backBtn"><i className="material-icons" onClick={() => { history.push("/auth/signin") }}>navigate_before</i></span>
                                    </div>
                                    : null
                            }
                            <div className="mb-3">
                                <span className="text-info"><i className="material-icons">lock</i></span>
                                <h3>{t('login.login')}</h3>
                            </div>
                            <Switch>
                                {/* Redirect to select page if there's no login method selected */}
                                <Redirect from="/auth/signin/" exact to="/auth/signin/select" />
                                <Route path="/auth/signin/select">
                                    <h4>{t('login.selectOneToLogin')}</h4>

                                    <div className="mt-3">
                                        <div className="row">
                                            <div className="col">
                                                <Link className="btn btn-primary" to="/auth/signin/code">{t('login.loginCode')}</Link>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Link className="btn btn-success" to="/auth/signin/email_password">{t('login.emailAndPassword')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Route>
                                <Route path="/auth/signin/code">
                                    <h5>{t('login.withLoginCode')}</h5>
                                    <div className="mt-4">
                                        <form onSubmit={handleSubmitCode}>
                                            <div className="form-group">
                                                <input className="form-control" value={loginCode} type="text" placeholder={t('login.loginCode')} id="loginCode" onChange={e => setLoginCode(e.target.value)} />
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <button className="btn btn-primary">{t('login.login')}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Route>
                                <Route path="/auth/signin/email_password">
                                    <h5>{t('login.withEmailAndPassword')}</h5>

                                    <div className="mt-4">
                                        <form onSubmit={handleSubmitEmailAndPassword}>
                                            <div className="form-group">
                                                <input className="form-control" value={email} type="email" placeholder={t('login.email')} id="email" onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" value={password} type="password" placeholder={t('login.password')} id="password" onChange={e => setPassword(e.target.value)} />
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <button className="btn btn-primary">{t('login.login')}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Route>
                            </Switch>

                            <p className="mt-3 mb-2 text-muted">{t('login.forgetPassword')} <Link to="/auth/reset_password">{t('login.reset')}</Link></p>
                            <p className="mb-0 text-muted">{t('login.dontHaveAnAccount')} <Link to="/auth/signup">{t('login.signup')}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;