import Home from './component/pages/Home'
import Login from './component/pages/Login'
import RoleSelect from './component/pages/RoleSelect'
import SystemAdmin from './component/pages/SystemAdmin'
import UpdatePassword from './component/pages/UpdatePassword'
import UserConfirmRegister from './component/pages/UserConfirmRegister'

import Committee, { committeeRoutes } from './component/pageGroups/Committee'
import Organization from './component/pageGroups/Organization'

import Director from './component/pages/Director'
import Director_Delegates from './component/pages/Director_Delegates'

import { roleCode } from './constant'
import RenewConfirmLink from './component/pages/RenewConfirmLink'
import ResetPassword from './component/pages/ResetPassword'
import AudDisplayWrapped from './component/pages/committee/AudDisplay'

// Nav only links will only shown on navbar. Will not used in PageRouter.
const navOnly = [
    {
        prefix: '/committee',
        routes: [...committeeRoutes]
    }
]

const route = [
    {
        path: '/',
        exact: true,
        component: Home,
        name: 'Home',
        hideOnNav: true
    },
    {
        path: '/committee',
        component: Committee,
        name: 'Committee',
        hideOnNav: true,
        permission: [
            roleCode.Admin,
            roleCode.Chair,
            roleCode.Delegate
        ]
    },
    {
        path: '/organization',
        component: Organization,
        name: 'Organization',
        hideOnNav: true
    },
    {
        path: '/director/conf',
        component: Director,
        name: 'Director',
        permission: [
            roleCode.Director
        ]
    },
    {
        path: '/director/delegates',
        component: Director_Delegates,
        name: 'Delegates',
        permission: [
            roleCode.Director
        ]
    },
    {
        path: '/director/audience',
        component: AudDisplayWrapped,
        name: 'AudDisplay',
        permission: [
            roleCode.Director
        ],
        hideOnNav: true,
    },
    {
        path: '/admin',
        component: SystemAdmin,
        name: 'System Admin',
        permission: [
            roleCode.SystemAdmin
        ]
    },
    // auth
    {
        path: '/auth/signin',
        component: Login,
        name: 'Login',
        permission: [
            roleCode.NoAuth
        ],
        redirect: {
            [roleCode.NoRole]: '/auth/roles',
            default: '/'
        },
        hideOnNav: true
    },
    {
        path: '/auth/roles',
        exact: true,
        component: RoleSelect,
        name: 'Role Select',
        denyPermission: [
            roleCode.NoAuth
        ],
        hideOnNav: true
    },
    {
        path: '/auth/updatePassword',
        exact: true,
        component: UpdatePassword,
        hideOnNav: true,
    },
    {
        path: '/auth/register_confirm',
        component: UserConfirmRegister,
        name: 'UserConfirmRegister',
        permission: [
            roleCode.NoAuth
        ],
        redirect: {
            [roleCode.NoRole]: '/auth/roles',
            default: '/'
        },
        hideOnNav: true
    },
    {
        path: '/auth/renew_link',
        component: RenewConfirmLink,
        name: 'RenewConfirmLink',
        permission: [
            roleCode.NoAuth
        ],
        redirect: {
            [roleCode.NoRole]: '/auth/roles',
            default: '/'
        },
        hideOnNav: true
    },
    {
        path: '/auth/reset_password',
        component: ResetPassword,
        name: 'Reset Password',
        permission: [
            roleCode.NoAuth
        ],
        redirect: {
            [roleCode.NoRole]: '/auth/roles',
            default: '/'
        },
        hideOnNav: true
    }
];


export { navOnly };

export default route;