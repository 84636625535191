import React from 'react'

import { renderItemStatusCode } from '../modules/util'

export default function itemStatusBadge(status) {
    const badgeColors = {
        Inactive: "warning",
        Active: "success",
        Deleted: "dark",
        Suspended: "danger",
        Archived: "dark"
    }

    if ((typeof status) === "number") {
        status = renderItemStatusCode(status);
    }

    return <span className={"badge badge-" + badgeColors[status]}>{status}</span>
}
