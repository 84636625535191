import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

function Timer({ committeeTimer, isSpeakersListMode = false }) {

    const { t } = useTranslation()
    const { remainTime, loading, timeLimit, msToHMSTime, setTimeLimit, timerState, toggleStart, reset } = committeeTimer;

    const [h, setH] = useState(0);
    const [m, setM] = useState(0);
    const [s, setS] = useState(0);

    // eslint-disable-next-line
    const timeLimitInHMS = useMemo(() => msToHMSTime(timeLimit || 0), [timeLimit]);

    useEffect(() => {
        setH(timeLimitInHMS.h);
        setM(timeLimitInHMS.m);
        setS(timeLimitInHMS.s);
    }, [timeLimitInHMS]);

    const onTimerFormSubmit = (e) => {
        e.preventDefault();

        setTimeLimit({ h, m, s });
    }

    const handleOnChange = (e, setFunc) => {
        let number = isNaN(e.target.value) || e.target.value === "" ? "0" : e.target.value;
        setFunc(parseInt(number));
    }

    return (
        <div className={"card" + (loading ? " loading" : "")}>
            <div className={"card-body " + (isSpeakersListMode ? "disabled " : "")} >
                <h5 className="card-title">
                    {t('committee.chairDash.timer.timer')} <span className={"badge badge-danger " + (remainTime < 0 ? "" : "d-none ")} style={{ animation: "blinker 1.5s linear infinite" }}> {t('committee.chairDash.timer.timesUp')} </span>
                </h5>
                <form onSubmit={onTimerFormSubmit}>
                    <fieldset disabled={timerState === 2}>
                        <div className="row text-center">
                            <div className="col">
                                <input className="form-control" type="number" id="hour" min={0}
                                    placeholder="0" onChange={e => handleOnChange(e, setH)} value={h || ""} />
                                <label> {t('committee.chairDash.timer.hour')} </label>
                            </div>
                            <div className="col">
                                <input className="form-control" type="number" id="min" max={60} min={0}
                                    placeholder="0" onChange={e => handleOnChange(e, setM)} value={m || ""} />
                                <label> {t('committee.chairDash.timer.minute')} </label>
                            </div>
                            <div className="col">
                                <input className="form-control" type="number" id="sec" max={60} min={0}
                                    placeholder="0" onChange={e => handleOnChange(e, setS)} value={s || ""} />
                                <label> {t('committee.chairDash.timer.second')} </label>
                            </div>
                        </div>
                    </fieldset>
                    <button disabled={timerState === 2} className="btn btn-sm btn-primary" type="submit"> {t('committee.chairDash.timer.set')} </button>
                    <button type="button"
                        disabled={remainTime <= 0}
                        className={"btn btn-sm btn-" + (timerState === 0 ? "success" : (timerState === 2 ? "warning" : "primary"))}
                        onClick={() => toggleStart()}
                    >
                        {timerState === 2 ? t('committee.chairDash.timer.pause') : timerState === 1 ? t('committee.chairDash.timer.resume') : t('committee.chairDash.timer.start')}
                    </button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => reset(timerState === 0 ? 0 : timeLimit)}> {t('committee.chairDash.timer.reset')} </button>
                </form>
            </div>
        </div>
    )
}

export default Timer
