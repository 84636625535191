import React, { Component } from 'react'

export class Dashboard extends Component {
    render() {
        return (
            <div className="container-fluid">
               <h4>Dashboard</h4>
            </div>
        )
    }
}

export default Dashboard
