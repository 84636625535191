import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { renderRoleName } from '../../../modules/util'
import { attendStatusCode } from '../../../../constant'

import { useMutation } from 'react-apollo'
import { MUTATION_SUBMIT_ROLLCALL } from '../../../../graphql/mutations'
import { useTranslation } from 'react-i18next'

const RenderSingleRole = ({ RCID, role, attendStatus, cardMode }) => {
    const { t } = useTranslation();
    const [mutateLoading, setMutateLoading] = useState(null);

    const handleClick = (attendeeId, status) => {
        setMutateLoading(status);
        mutateSubmitRollCall({
            variables: {
                attendeeId,
                attendeeStatus: status
            }
        }).then(() => {
            setMutateLoading(null);
        });
    }

    const [mutateSubmitRollCall] = useMutation(MUTATION_SUBMIT_ROLLCALL, {
        variables: {
            RCID
        }
    });

    let color;
    if (attendStatus === attendStatusCode.Absent) {
        color = "warning";
    }
    else if (attendStatus === attendStatusCode.Present) {
        color = "info";
    } else if (attendStatus === attendStatusCode.PresentAndVoting) {
        color = "success";
    } else {
        color = "dark";
    }

    let attendStatusText = "";

    if (attendStatus === attendStatusCode.Absent) {
        attendStatusText = t('committee.editRollCall.absent');
    } else if (attendStatus === attendStatusCode.Present) {
        attendStatusText = t('committee.editRollCall.present');
    } else if (attendStatus === attendStatusCode.PresentAndVoting) {
        attendStatusText = t('committee.editRollCall.presentVote');
    }

    return (
        <div className={"rounded border bg-white border-" + color}>
            <div className="row p-2">
                <div className="col-3" style={{ alignSelf: "center" }}>
                    <h3 className="m-0 ml-3">
                        {renderRoleName(role)}
                    </h3>
                </div>
                <div className="col-3" style={{ alignSelf: "center" }}>
                    <h2><span className={"ml-1 badge badge-" + color}>
                        {attendStatusText}
                    </span></h2>
                </div>
                <div className="col-6 text-right">
                    <button className={"btn btn-warning" + (cardMode ? " btn-sm" : "")} disabled={mutateLoading} onClick={() => handleClick(role._id, "Absent")}>
                        {mutateLoading === "Absent" ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                        {t('committee.editRollCall.absent')}
                    </button>
                    <button className={"btn btn-info" + (cardMode ? " btn-sm" : "")} disabled={mutateLoading} onClick={() => handleClick(role._id, "Present")}>
                        {mutateLoading === "Present" ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                        {t('committee.editRollCall.present')}
                    </button>
                    <button className={"btn btn-success" + (cardMode ? " btn-sm" : "")} disabled={mutateLoading} onClick={() => handleClick(role._id, "PresentAndVoting")}>
                        {mutateLoading === "PresentAndVoting" ? <span className="spinner-border spinner-border-sm mr-1"></span> : null}
                        {t('committee.editRollCall.presentVote')}
                    </button>
                </div>
            </div>
        </div>
    )
}

function EditRollcall({ committeeRoles, rollCallDocs, committeeRolesLoading, editId, cardMode = false }) {

    const { id: urlId } = useParams();
    const id = editId || urlId;

    const history = useHistory();

    const { t } = useTranslation();

    const rollCall = rollCallDocs ? rollCallDocs.find((v) => v._id === id) : null;

    return (
        <div>
            <div className={"row p-2" + (cardMode ? " d-none" : "")}>
                <div className="col-auto">
                    <button className="btn btn-dark btn-sm" onClick={() => history.push("/committee/attendance")}>
                        {t('committee.editRollCall.goBack')}
                    </button>
                </div>
                <div className="col" style={{ alignSelf: "center" }}>
                    <h2>{t('committee.editRollCall.committee')}: <span className="badge badge-primary">{rollCall && rollCall.committee ? rollCall.committee.name : "n/a"}</span></h2>
                </div>
            </div>
            {
                !committeeRolesLoading ?
                    committeeRoles && committeeRoles.length > 0 ? committeeRoles.map((val, i) => {
                        return (
                            <div className="row my-3" key={i}>
                                <div className="col">
                                    {<RenderSingleRole RCID={id} role={val} cardMode={cardMode}
                                        attendStatus={rollCall && rollCall.attendee ? rollCall.attendee[val._id] : null} />}
                                </div>
                            </div>
                        )
                    }) :
                        <div className="text-center mt-5">
                            <h1>{t('committee.editRollCall.cantRollCall')}</h1>
                            <h2><span className="badge badge-primary">{t('committee.editRollCall.noRoles')}</span></h2>
                        </div> :
                    <div className="text-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">{t('loading')}</span>
                        </div>
                    </div>
            }
        </div>
    )
}

export default EditRollcall
