import React, { Component } from 'react'

export class onLoadRunner extends Component {

    componentDidMount() {
        if (this.props.runOnLoad) {
            this.props.runOnLoad();
        }
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}

export default onLoadRunner
