import React, { useMemo, useEffect } from "react"
import FootStatusBar from "../modules/footStatusBar"
import PageRouter from "../../PageRouter"

import * as jwt_decode from "jwt-decode"

import useCommitteeOptions from '../pages/committee/hooks/useCommitteeOptions'
import useSpeakersList from '../pages/committee/hooks/useSpeakersList'
import useCommitteeTimer from '../pages/committee/hooks/useCommitteeTimer'
import useCommitteeRoles from '../pages/committee/hooks/useCommitteeRoles'

import Home from "../pages/committee/Home"
import ChairDash from "../pages/committee/chairdash"
import NoteSending from "../pages/committee/NoteSending"
import NoteApprove from "../pages/committee/NoteApprove"
import Amendment from "../pages/committee/Amendment"
import Resolution from "../pages/committee/Resolution"
import RollCall from "../pages/committee/RollCall"
import AudDisplay from "../pages/committee/AudDisplay"
import SpeakersList from "../pages/committee/SpeakersList"
import Announcement from "../pages/committee/Announcement"
import TallySheet from "../pages/committee/TallySheet"
import ChairResolution from "../pages/committee/ChairResolution"
import Feedback from "../pages/committee/Feedback"

import VoteModal from '../pages/committee/VoteModal'

import { roleCode, roleField, CommitteeProcedures } from "../../constant"
import { renderCurrentCmProcedureName, timeTools, renderRoleName } from "../modules/util"

import { useSubscription } from '@apollo/react-hooks'
import {
    SUBSCRIPTION_ANNOUNCEMENT_POSTED,
    SUBSCRIPTION_RECEIVED_NOTE,
    SUBSCRIPTION_RECEIVED_PENDING_NOTE,
    SUBSCRIPTION_CREATE_RESOLUTION,
    SUBSCRIPTION_UPDATE_RESOLUTION,
} from '../../graphql/subscriptions'
import {
    infoNotify,
    successNotify,
    errorNotify
} from "../modules/notification"

import { useTranslation } from 'react-i18next'
import useSyncedTime from '../pages/committee/hooks/useSyncedTime'
import useCommitteeAmendments from "../pages/committee/hooks/useCommitteeAmendments"

const useCommitteeNotifications = (CMID, me, committeeOptions, disableNotification) => {

    const { t } = useTranslation();

    // announcement posted
    const { data: announcementData } = useSubscription(SUBSCRIPTION_ANNOUNCEMENT_POSTED, {
        variables: {
            target: "committee",
            targetID: CMID
        },
        fetchPolicy: "network-only",
        skip: disableNotification
    });

    useEffect(() => {
        if (announcementData && announcementData.announcementPosted) {
            infoNotify(
                t('pageGroups.committee.newAnnouncement'),
                t('pageGroups.committee.sentences.youHaveANewAnnouncement') + "<br />" + t('pageGroups.committee.click') + "<a href='/committee/announcement'> " + t('pageGroups.committee.here') + "</a>" + t('pageGroups.committee.toReadTheAnnouncement'),
                true
            );
        }
        // eslint-disable-next-line
    }, [announcementData]);

    // received note
    const { data: receivedNoteData } = useSubscription(SUBSCRIPTION_RECEIVED_NOTE, {
        variables: {
            RID: me._id
        },
        fetchPolicy: "network-only",
        skip: disableNotification
    });

    useEffect(() => {
        if (receivedNoteData && receivedNoteData.noteReceived) {
            const newNote = receivedNoteData.noteReceived.node;

            let trimmedContent = newNote.content.split(" ").slice(0, 12);
            if (newNote.content.length > 12) {
                trimmedContent = trimmedContent.join(" ") + "... ";
            } else {
                trimmedContent = trimmedContent.join(" ");
            }

            if (newNote.receiver._id === me._id && !newNote.readTime) {
                infoNotify(
                    t('pageGroups.committee.newNote'),
                    t('pageGroups.committee.from') + ": <strong>" +
                    renderRoleName(newNote.sender) +
                    "</strong><br />" +
                    trimmedContent +
                    "</br><a href='/committee/note/" +
                    newNote.sender._id +
                    "'class='alert-link'>" + t('pageGroups.committee.view') + "</a>",
                    true
                );
            } else if (newNote.sender._id === me._id) {
                if (newNote.blocked) {
                    errorNotify(
                        t('pageGroups.committee.sentences.yourNoteIsNotPassed'),
                        t('pageGroups.committee.to') + ": <strong>" +
                        renderRoleName(newNote.receiver) +
                        "</strong> <br />" + t('pageGroups.committee.content') + ": " +
                        trimmedContent,
                        true
                    );
                } else if (newNote.reviewer) {
                    successNotify(
                        t('pageGroups.committee.sentences.yourNoteIsPassed'),
                        t('pageGroups.committee.to') + ": <strong>" +
                        renderRoleName(newNote.receiver) +
                        "</strong> <br />" + t('pageGroups.committee.content') + ": " +
                        trimmedContent,
                        true
                    );
                }
            }
        }
        // eslint-disable-next-line
    }, [receivedNoteData, me]);

    // received pending note
    const { data: receivedPendingNoteData } = useSubscription(SUBSCRIPTION_RECEIVED_PENDING_NOTE, {
        variables: {
            CMID
        },
        fetchPolicy: "network-only",
        skip: me.role === roleCode.Delegate || disableNotification
    });

    useEffect(() => {
        if (receivedPendingNoteData && receivedPendingNoteData.pendingNoteReceived) {
            infoNotify(
                t('pageGroups.committee.info'),
                t('pageGroups.committee.sentences.youHaveANewPendingNote') + " <br /> <a href='/committee/approve' class='alert-link'>" + t('pageGroups.committee.sentences.clickHereToApprovePage') + "</a>",
                true
            );
        }
        // eslint-disable-next-line
    }, [receivedPendingNoteData]);

    // create resolution
    const { data: createResolutionData } = useSubscription(SUBSCRIPTION_CREATE_RESOLUTION, {
        variables: {
            CMID
        },
        fetchPolicy: "network-only",
        skip: me.role !== roleCode.Chair || disableNotification
    });

    useEffect(() => {
        if (createResolutionData && createResolutionData.resolutionCreated) {
            infoNotify(
                t('pageGroups.committee.newResolution'),
                t('pageGroups.committee.sentences.thereIsANewResolutionSubmitted') + " <br /> " + t('pageGroups.committee.click') + " <a href='/committee/chair/resolution'> " + t('pageGroups.committee.here') + "</a> " + t('pageGroups.committee.toReadTheResolution'),
                true
            );
        }
        // eslint-disable-next-line
    }, [createResolutionData]);

    // update resolution
    const { data: updateResolutionData } = useSubscription(SUBSCRIPTION_UPDATE_RESOLUTION, {
        variables: {
            CMID
        },
        fetchPolicy: "network-only",
        skip: disableNotification
    });

    useEffect(() => {
        if (updateResolutionData && updateResolutionData.resolutionUpdated) {
            if (updateResolutionData.resolutionUpdated.isPublic === true && me.role === roleCode.Delegate) {
                infoNotify(
                    t('pageGroups.committee.resolutionUpdated'),
                    "[" + updateResolutionData.resolutionUpdated.topic + "] <br />" +
                    t('pageGroups.committee.resolutionPublic') + "<br /> " + t('pageGroups.committee.click') + " <a href='/committee/resolution'> " + t('pageGroups.committee.here') + "</a> " + t('pageGroups.committee.toReadTheResolution'),
                    true
                );
            }
        }
        // eslint-disable-next-line
    }, [updateResolutionData]);

    useCommitteeAmendments(CMID, (amendment) => {
        if (me.role === roleCode.Chair && !disableNotification) {
            infoNotify(
                t('pageGroups.committee.newAmendment'),
                t('pageGroups.committee.sentences.thereIsANewAmendmentSubmitted') + " <br />" + t('pageGroups.committee.click') + "<a href='/committee/amendment'> " + t('pageGroups.committee.here') + "</a> " + t('pageGroups.committee.toReadTheAmendment'),
                true
            );
        }
    });
}

function CommitteeFoot({ committeeOptions, CMID, loading: parentLoading }) {
    const { t } = useTranslation();

    const syncedTime = useSyncedTime();

    const { roles } = useCommitteeRoles(CMID);

    // timer
    const { timeLimit, remainTime, timerState, loading: cmTimerLoading } = useCommitteeTimer(CMID);

    // TODO: skip repeat query
    const { speakersList, currentSpeakerId, currentSpeakerEnd, speakerListYield } = useSpeakersList(CMID);

    const timerProp = {
        animated: timerState === 1,
        text: timerState === 0 ?
            (timeLimit > 0 ? timeTools.msToStr(timeLimit) : syncedTime.getCurrentTime().toLocaleTimeString()) :
            (remainTime > 0 ? timeTools.msToStr(remainTime) : (
                <span
                    className="badge badge-danger"
                    style={{ animation: "blinker 1.5s linear infinite" }}
                >
                    {t("pageGroups.committee.timesUp")}
                </span>
            )),
        percent: timerState === 0 ? 0 : (
            remainTime > 0
                ? 100 - Math.floor((remainTime / timeLimit) * 10000) / 100
                : 100
        ),
        color: timerState === 1 ? "warning" : (timerState === 2 && remainTime <= 0 ? "danger" : "info")
    };

    // foot bar text
    const { currentProcedure = CommitteeProcedures.None, displaySettings } = committeeOptions || {};

    const speakersListRoles = useMemo(() => {
        return (speakersList || []).map((id) => roles.find((v) => v._id === id)).filter((v) => !!v);
    }, [speakersList, roles]);

    let footTitle = "n/a";
    let leftText = "n/a";

    const speakerRole = speakersListRoles && speakersListRoles.find(({ _id }) => _id === currentSpeakerId);

    if (currentProcedure) {
        footTitle = (displaySettings && displaySettings.footBar && displaySettings.footBar.title) || renderCurrentCmProcedureName(currentProcedure);

        const speakerName = speakerRole
            ? renderRoleName(speakerRole)
            : null;

        leftText = (displaySettings && displaySettings.footBar && displaySettings.footBar.leftTitle) || "";

        if (currentProcedure === CommitteeProcedures.SpeakersList && speakerName) {
            leftText = (
                <>
                    {speakerName}
                    {
                        currentSpeakerEnd && speakerListYield ? <>
                            {" "}<span className="badge badge-info">
                                {t("pageGroups.committee.yieldTo")}{" "}{speakerListYield}
                            </span>
                        </> : null
                    }
                </>
            );
        }
    }

    const loading = cmTimerLoading || parentLoading;

    return (
        <>
            <FootStatusBar
                title={footTitle}
                leftText={leftText}
                timer={timerProp.text}
                progress={timerProp.percent}
                progressProp={{
                    className: "bg-" + timerProp.color,
                    striped: true,
                    animated: timerProp.animated
                }}
                timerInUse={timerState !== 0 || (timeLimit)}
                haveSpeaker={currentProcedure === CommitteeProcedures.SpeakersList && speakerRole}
                loading={loading}
            />
        </>
    );
}

const displayPath = "/committee/display";
function Committee() {
    const me = useMemo(() => {
        if (localStorage.getItem(roleField)) {
            return jwt_decode(localStorage.getItem(roleField));
        }
        return null;
    }, []);

    const { i18n } = useTranslation();

    const this_CMID = me ? me.roleTarget : null;

    // committeeOptions
    const { committeeOptions, committee, loading: cmOptionsLoading } = useCommitteeOptions(this_CMID);

    useEffect(() => {
        if (committee && committee.language) {
            i18n.changeLanguage(committee.language);
            window.localStorage.setItem('i18nextLng', committee.language);
        }
        // eslint-disable-next-line
    }, [committee]);

    useEffect(() => {
        if (committeeOptions.language) {
            i18n.changeLanguage(committeeOptions.language);
        }
        // eslint-disable-next-line
    }, [committeeOptions.language]);

    // notifications
    useCommitteeNotifications(this_CMID, me, committeeOptions, window.location.pathname === displayPath);

    return (
        <>
            <div style={{ marginBottom: "80px" }}>
                <PageRouter routes={routes} prefix="/committee" />
            </div>

            <VoteModal CMID={this_CMID} me={me} />

            <CommitteeFoot committeeOptions={committeeOptions} CMID={this_CMID} loading={cmOptionsLoading} />
        </>
    )
}

export default Committee

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        name: "Home",
        hideOnNav: true
    },
    {
        path: "/chair",
        exact: true,
        component: ChairDash,
        name: {
            "en": "Dashboard",
            "zh": "主面板",
            "zh_TransMUN": "主面板"
        },
        permission: [roleCode.Chair]
    },
    {
        path: "/announcement",
        component: Announcement,
        name: {
            "en": "Announcement",
            "zh": "公吿",
            "zh_TransMUN": "公告"
        },
        permission: [roleCode.Admin, roleCode.Chair, roleCode.Delegate]
    },
    {
        path: "/speakersList",
        exact: true,
        component: SpeakersList,
        name: {
            "en": "SpeakersList",
            "zh": "發言人名單",
            "zh_TransMUN": "發言人名單"
        },
        hideOnNav: true
    },
    {
        path: ["/note/:id", "/note"],
        link: "/note",
        component: NoteSending,
        name: {
            "en": "Note Sending",
            "zh": "紙條傳遞",
            "zh_TransMUN": "意向條"
        },
        permission: [roleCode.Delegate, roleCode.Chair, roleCode.Admin],
        viewBadge: true
    },
    {
        path: "/approve",
        exact: true,
        component: NoteApprove,
        name: {
            "en": "Note Approve",
            "zh": "紙條審核",
            "zh_TransMUN": "意向條審核"
        },
        permission: [roleCode.Admin, roleCode.Chair]
    },
    {
        path: "/resolution",
        exact: true,
        component: Resolution,
        name: {
            "en": "Resolution",
            "en_TWMAS": "Chairman’s Statement",
            "zh": "決議案",
            "zh_TransMUN": "草案"
        },
        permission: [roleCode.Delegate]
    },
    {
        path: "/chair/resolution",
        exact: true,
        component: ChairResolution,
        name: {
            "en": "Resolution",
            "en_TWMAS": "Chairman’s Statement",
            "zh": "決議案",
            "zh_TransMUN": "草案"
        },
        permission: [roleCode.Chair]
    },
    {
        path: "/amendment",
        exact: true,
        component: Amendment,
        name: {
            "en": "Amendment",
            "zh": "修正案",
            "zh_TransMUN": "修正案"
        },
        permission: [roleCode.Delegate, roleCode.Chair]
    },
    {
        path: "/attendance",
        component: RollCall,
        name: {
            "en": "Roll Call",
            "zh": "點名",
            "zh_TransMUN": "點名"
        },
        permission: [roleCode.Chair]
    },
    {
        path: "/tallySheet",
        component: TallySheet,
        name: {
            "en": "Tally Sheet",
            "zh": "記錄表",
            "zh_TransMUN": "記錄表"
        },
        hideOnNav: false,
        permission: [roleCode.Chair]
    },
    {
        path: "/feedback",
        component: Feedback,
        name: {
            "en": "Feedback",
            "zh": "系統回饋",
            "zh_TransMUN": "系統回饋"
        },
        hideOnNav: false,
        permission: [roleCode.Chair, roleCode.Delegate, roleCode.Admin]
    },
    {
        path: "/display",
        component: AudDisplay,
        name: "Audience Display",
        permission: [roleCode.Chair, roleCode.Admin],
        openInNew: true,
        hideOnNav: true
    },
];

export { routes as committeeRoutes };