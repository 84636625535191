import React from 'react'
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { MUTATION_EDIT_AMENDMENT, MUTATION_EDIT_RESOLUTION } from '../../../../graphql/mutations'

function ResolutionAmendmentPrivateIndicator({ pendingAmendments = [], pendingResolutions = [], rightDisplaySetting = {} }) {
    const { t } = useTranslation();

    const { type, targetId } = rightDisplaySetting;

    const v = (type === "resolution" ? pendingResolutions : pendingAmendments).find(({ _id }) => {
        return _id === targetId;
    });

    const notPublic = v && !v.isPublic;

    const [mutateEditResolution, { loading: editResoLoading }] = useMutation(MUTATION_EDIT_RESOLUTION);
    const [editAmendment, { loading: editAmLoading }] = useMutation(MUTATION_EDIT_AMENDMENT);

    const makePublic = () => {
        if (type === "resolution") {
            mutateEditResolution({
                variables: {
                    RESOID: targetId,
                    isPublic: true
                }
            })
        } else {
            editAmendment({
                variables: {
                    AID: targetId,
                    isPublic: true,
                },
            });
        }
    };

    if (v) {
        return <div className="text-center my-3">
            <h5>
                <span className={`badge badge-${notPublic ? "warning" : "success"}`}>
                    {notPublic ? t('committee.chairDash.displaySetting.notPublic') : t('committee.chairDash.displaySetting.isReady')}
                </span>
            </h5>

            {notPublic ?
                <button
                    className="btn btn-sm btn-primary"
                    onClick={makePublic}
                    disabled={editResoLoading || editAmLoading}
                >Click here to make it public</button>
                : null}
        </div>
    } else {
        return null;
    }
}

export default ResolutionAmendmentPrivateIndicator
