import React, { useState, useEffect } from "react"

import { Link } from "react-router-dom"
import { MDBModalHeader, MDBModal, MDBModalBody } from "mdbreact"

import { useQuery, useMutation } from "react-apollo"

import { renderRoleName } from "../../../modules/util"
import { roleCode, attendStatusCode } from "../../../../constant"
import { QUERY_FETCH_COMMITTEE_ROLES } from "../../../../graphql/queries"
import { MUTATION_UPDATE_COMMITTEE_OPTIONS } from "../../../../graphql/mutations"
import useSpeakersList, { speakerStatusCodes } from "../hooks/useSpeakersList"
import useCommitteeRollCall from "../hooks/useCommitteeRollCall"
import { useTranslation } from "react-i18next"

import "./loading.css"

export function generateOptions(type) {
    let table = []
    table.push(
        <option hidden id={"clause-1"} key={-1} value={-1}>
            -
    </option>
    )
    for (let i = 0; i < 15; i++) {
        table.push(
            <option id={"clause" + i} key={i} value={i}>
                {i + 1}
            </option>
        )
    }
    return table
}

function RenderSpeakersList({
    speakersList = [],
    currentSpeakerId = null,
    currentSpeakerStatus,
    deActiveColor,
}) {
    const { t } = useTranslation()
    return (
        <ol>
            {speakersList.map((v) => {
                return (
                    <li key={v._id}>
                        {<span onClick={() => { }}>{renderRoleName(v)}</span>}
                        {v._id === currentSpeakerId ? (
                            <>
                                <span className="badge badge-pill badge-success ml-1">
                                    {" "}
                                    {t("committee.chairDash.speakerList.current")}{" "}
                                </span>
                                {currentSpeakerStatus === speakerStatusCodes.End ? (
                                    <span className={"badge badge-pill ml-1 " + deActiveColor}>
                                        {" "}
                                        {t("committee.chairDash.speakerList.end")}{" "}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                    </li>
                )
            })}
        </ol>
    )
}

function SpeakersList({
    CMID,
    deActiveColor = "blue-grey",
    isSpeakersListMode = false,
    isTHIMUN = false,
    committeeOptions,
}) {
    const { t } = useTranslation()

    const {
        loading,
        currentSpeakersList,
        currentListName,
        timePerSpeaker,
        currentSpeakerId,
        currentSpeakerStatus,
        nextSpeaker,
        prevSpeaker,
        reset,
        start,
        end,
        remainTimeHMS,
        setSpeakerYield,
        speakerListYield,
        setTHIMUNSpeaker,
        setSpeakersListSetting,
        showSpeakersList,
    } = useSpeakersList(CMID)

    const { lastRollCall, loading: cmRollCallLoading } = useCommitteeRollCall(
        CMID
    )

    const { currentClause, isSC } = committeeOptions

    const [yieldModalOpen, setYieldModalOpen] = useState(false)

    const toggleYieldSelector = () => setYieldModalOpen(!yieldModalOpen)

    const yieldButtonClick = (e) => {
        setYieldModalOpen(false)
        setSpeakerYield(e.target.value)
    }

    const { data: committeeRoleDate, loading: committeeRoleLoading } = useQuery(
        QUERY_FETCH_COMMITTEE_ROLES,
        {
            variables: {
                CMID,
            },
            fetchPolicy: "cache-and-network",
        }
    )

    let roles = (() => {
        const { committee } = committeeRoleDate || {}
        const { roles } = committee || {}
        return roles ? roles : []
    })().filter((v) => {
        return v.role === roleCode.Delegate
    })

    if (lastRollCall && lastRollCall.attendee) {
        const { attendee } = lastRollCall
        roles = roles.filter((v) => attendee[v._id] !== attendStatusCode.Absent)
    }

    const [tpsInput, setTpsInput] = useState(0)

    useEffect(() => {
        if (timePerSpeaker || timePerSpeaker === 0) {
            setTpsInput(timePerSpeaker)
        }
    }, [timePerSpeaker])

    const [updateCommittee, { loading: updateCommitteeLoading }] = useMutation(
        MUTATION_UPDATE_COMMITTEE_OPTIONS,
        {
            variables: {
                CMID,
            },
        }
    )

    const setShowSpeakersList = (show) => {
        updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        show: show,
                    },
                },
            },
        })
    }

    const setOnUsingCurrentClause = (using) => {
        updateCommittee({
            variables: {
                options: {
                    currentClause: {
                        onUse: using,
                    },
                },
            },
        })
    }

    const handleClausing = (e) => {
        e.preventDefault()
        updateCommittee({
            variables: {
                options: {
                    currentClause: {
                        clause: e.target.value,
                    },
                },
            },
        })
    }

    return (
        <>
            <div
                className={
                    "card" +
                    (loading || committeeRoleLoading || cmRollCallLoading
                        ? " loading"
                        : "")
                }
            >
                <div className="card-body">
                    <h5 className="card-title">
                        {loading || isTHIMUN
                            ? t("committee.chairDash.speakerList.speakersList")
                            : currentListName === "default"
                                ? t("committee.chairDash.speakerList.primarySpeakersList")
                                : t("committee.chairDash.speakerList.secondarySpeakersList")}
                        {/* {t("committee.chairDash.speakerList.speakersList")} */}
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                        <div className="row">
                            <div className="col-6" style={{ alignSelf: "center" }}>
                                {t("committee.chairDash.speakerList.timePerSpeaker")}
                            </div>
                            {!isTHIMUN ? (
                                <div className="col-6">
                                    <span className="badge badge-dark badge-pill">
                                        {timePerSpeaker || 0}{" "}
                                        {t("committee.chairDash.speakerList.second")}{" "}
                                    </span>
                                </div>
                            ) : (
                                <div className="col-12 col-xl-6 align-self-center">
                                    <div className="input-group input-group-sm">
                                        <input
                                            className="form-control form-control-sm"
                                            type="number"
                                            min={0}
                                            placeholder="0"
                                            value={tpsInput || ""}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setTpsInput(parseInt(e.target.value || "0"))
                                                }
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                {" "}
                                                {t("committee.chairDash.speakerList.second")}{" "}
                                            </span>
                                            <button
                                                className="btn btn-sm btn-primary d-inline"
                                                onClick={() => {
                                                    setSpeakersListSetting({ timePerSpeaker: tpsInput })
                                                }}
                                            >
                                                {t("committee.chairDash.speakerList.set")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!isTHIMUN ? (
                            <div className="row">
                                <div className="col-6">
                                    {" "}
                                    {t("committee.chairDash.speakerList.numberOfSpeaker")}{" "}
                                </div>
                                <div className="col-6">
                                    <span className="badge badge-dark badge-pill">
                                        {currentSpeakersList ? currentSpeakersList.length : 0}
                                    </span>
                                </div>
                            </div>
                        ) : null}
                    </h6>
                    <hr />
                    {!isTHIMUN ? (
                        // UNUSA
                        <div style={{ maxHeight: "40vh", overflowY: "auto" }}>
                            {currentSpeakersList && currentSpeakersList.length > 0 ? (
                                <RenderSpeakersList
                                    speakersList={(currentSpeakersList || [])
                                        .map((id) => roles.find((v) => v._id === id))
                                        .filter((v) => !!v)}
                                    currentSpeakerStatus={currentSpeakerStatus}
                                    currentSpeakerId={currentSpeakerId}
                                    deActiveColor={deActiveColor}
                                />
                            ) : (
                                <p className="badge badge-pill badge-primary mb-0">
                                    {" "}
                                    {t(
                                        "committee.chairDash.speakerList.sentences.speakersListIsEmpty"
                                    )}{" "}
                                </p>
                            )}

                            <p className="text-muted">
                                {" "}
                                {t("committee.chairDash.speakerList.editSpeakersList")}
                                <Link to="/committee/speakersList">
                                    {" "}
                                    {t("committee.chairDash.speakerList.here")}{" "}
                                </Link>
                            </p>
                        </div>
                    ) : (
                        // THIMUN
                        <div>
                            <div className="form-group">
                                <label>
                                    {t("committee.chairDash.speakerList.selectSpeakerHere")}{" "}
                                </label>
                                <select
                                    disabled={
                                        currentSpeakerStatus === speakerStatusCodes.Speaking
                                    }
                                    className="form-control"
                                    value={
                                        currentSpeakersList && currentSpeakerId !== null
                                            ? currentSpeakerId
                                            : "empty"
                                    }
                                    onChange={(e) => {
                                        const index = roles.findIndex((v) => {
                                            return v._id === e.target.value
                                        })

                                        if (index === -1) {
                                            setTHIMUNSpeaker(null)
                                        } else {
                                            setTHIMUNSpeaker(roles[index]._id)
                                        }
                                    }}
                                >
                                    <option value="empty">
                                        {" "}
                                        {t("committee.chairDash.speakerList.empty")}{" "}
                                    </option>
                                    {roles
                                        .sort(function (a, b) {
                                            var nameA = renderRoleName(a)
                                            var nameB = renderRoleName(b)
                                            if (nameA < nameB) return -1
                                            if (nameA > nameB) return 1
                                            return 0
                                        })
                                        .map((v, i) => {
                                            return (
                                                <option key={i} value={v._id}>
                                                    {renderRoleName(v)}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>
                        </div>
                    )}

                    {speakerListYield ? (
                        <p className="my-1">
                            <strong>
                                {t("committee.chairDash.speakerList.currentlyYieldTo")}{" "}
                                <span className="badge badge-pill badge-info">
                                    {speakerListYield}
                                </span>
                            </strong>
                        </p>
                    ) : null}
                    <hr />

                    <div className={!isSpeakersListMode ? "rounded disabled" : ""}>
                        <button
                            className="btn btn-sm btn-success"
                            disabled={
                                currentSpeakerStatus !== speakerStatusCodes.Waiting &&
                                currentSpeakerStatus !== speakerStatusCodes.End
                            }
                            onClick={start}
                        >
                            {" "}
                            {currentSpeakerStatus === speakerStatusCodes.Waiting ||
                                currentSpeakerStatus === speakerStatusCodes.Empty
                                ? t("committee.chairDash.speakerList.start")
                                : t("committee.chairDash.speakerList.resume")}{" "}
                        </button>
                        <button
                            className="btn btn-sm btn-warning"
                            disabled={currentSpeakerStatus !== speakerStatusCodes.Speaking}
                            onClick={end}
                        >
                            {" "}
                            {t("committee.chairDash.speakerList.end")}{" "}
                        </button>
                        {currentListName === "default" ? (
                            <button
                                className="btn btn-sm btn-warning"
                                disabled={currentSpeakerStatus !== speakerStatusCodes.End}
                                onClick={() => setYieldModalOpen(true)}
                            >
                                {" "}
                                {t("committee.chairDash.speakerList.yield")}{" "}
                            </button>
                        ) : null}

                        {!isTHIMUN ? (
                            <>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={nextSpeaker}
                                >
                                    {" "}
                                    {t("committee.chairDash.speakerList.next")}{" "}
                                </button>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={prevSpeaker}
                                >
                                    {" "}
                                    {t("committee.chairDash.speakerList.prev")}{" "}
                                </button>
                            </>
                        ) : null}
                        <button className="btn btn-sm btn-danger" onClick={reset}>
                            {" "}
                            {t("committee.chairDash.speakerList.reset")}{" "}
                        </button>
                    </div>

                    <MDBModal isOpen={yieldModalOpen} toggle={toggleYieldSelector}>
                        <MDBModalHeader toggle={toggleYieldSelector}>
                            {t("committee.chairDash.speakerList.yieldSelector")}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <h4>
                                {t("committee.chairDash.speakerList.country")}:{" "}
                                <span className="badge badge-dark mr-2">
                                    {roles && currentSpeakerId !== null
                                        ? renderRoleName(
                                            roles.find(({ _id }) => _id === currentSpeakerId)
                                        )
                                        : "n/a"}
                                </span>
                            </h4>
                            <h4>
                                {t("committee.chairDash.speakerList.remainTime")}:{" "}
                                <span className="badge badge-dark">
                                    {remainTimeHMS.h * 60 + remainTimeHMS.m}{" "}
                                    {t("committee.chairDash.speakerList.minute")}{" "}
                                    {remainTimeHMS.s}{" "}
                                    {t("committee.chairDash.speakerList.second")}
                                </span>
                            </h4>

                            <div className="text-center">
                                <div className="btn-group" role="group">
                                    <button
                                        className="btn btn-sm btn-primary"
                                        value="Chair"
                                        onClick={yieldButtonClick}
                                    >
                                        {t("roles.chair")}
                                    </button>
                                    <button
                                        className="btn btn-sm btn-info"
                                        value="Delegate"
                                        onClick={yieldButtonClick}
                                    >
                                        {t("roles.delegate")}
                                    </button>
                                    {!isTHIMUN ?
                                        <>
                                            <button
                                                className="btn btn-sm btn-warning"
                                                value="Questions"
                                                onClick={yieldButtonClick}
                                            >
                                                {t("committee.chairDash.speakerList.questions")}
                                            </button>
                                            <button
                                                className="btn btn-sm btn-warning"
                                                value="Comments"
                                                onClick={yieldButtonClick}
                                            >
                                                {t("committee.chairDash.speakerList.comments")}
                                            </button>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                        </MDBModalBody>
                    </MDBModal>
                </div>
            </div>
            {!isTHIMUN ? (
                <div className={"card" + (updateCommitteeLoading ? " loading" : "")}>
                    <div className="card-body">
                        <h5 className="card-title">
                            {t("committee.chairDash.speakerList.setting")}
                        </h5>

                        <h6>{t("committee.chairDash.speakerList.showList")}</h6>
                        <div className="btn-group">
                            <button
                                className={
                                    "btn btn-sm btn-" +
                                    (showSpeakersList ? "success" : deActiveColor)
                                }
                                onClick={() => setShowSpeakersList(true)}
                            >
                                {t("committee.chairDash.speakerList.show")}
                            </button>
                            <button
                                className={
                                    "btn btn-sm btn-" +
                                    (!showSpeakersList ? "danger" : deActiveColor)
                                }
                                onClick={() => setShowSpeakersList(false)}
                            >
                                {t("committee.chairDash.speakerList.hide")}
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {isSC ? (
                <div className={"card" + (updateCommitteeLoading ? " loading" : "")}>
                    <div className="card-body">
                        <h5 className="card-title">Discussing clause by clause</h5>
                        <div className="btn-group">
                            <button
                                type="button"
                                className={
                                    "btn btn-sm btn-" +
                                    (currentClause && currentClause.onUse
                                        ? "success"
                                        : deActiveColor)
                                }
                                onClick={() => setOnUsingCurrentClause(true)}
                            >
                                {" "}
                                On
                            </button>
                            <button
                                type="button"
                                className={
                                    "btn btn-sm btn-" +
                                    (currentClause && currentClause.onUse
                                        ? deActiveColor
                                        : "danger")
                                }
                                onClick={() => setOnUsingCurrentClause(false)}
                            >
                                {" "}
                                Off
                            </button>
                        </div>
                        {currentClause && currentClause.onUse ? (
                            <form onChange={handleClausing}>
                                <div className="form-row">
                                    <div className="form-group col">
                                        <label>clause</label>
                                        <select
                                            value={currentClause.clause ? currentClause.clause : "-1"}
                                            className="form-control"
                                        >
                                            {generateOptions()}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default SpeakersList
