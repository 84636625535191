import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import * as jwt_decode from "jwt-decode"
import { roleField, roleCode } from '../../../constant'
import { renderRoleName } from '../../modules/util'

import useCommitteeOptions from './hooks/useCommitteeOptions'
import { useTranslation } from 'react-i18next'

function Home() {
    const { t } = useTranslation();
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    const { committee, loading: cmOptionsLoading } = useCommitteeOptions(this_CMID)

    const loading = cmOptionsLoading;
    return (
        <div className="container">
            {
                me.role === roleCode.Chair ? <Redirect to="/committee/chair" /> : null
            }
            {
                !loading ?
                    <div className="mt-5">
                        <h3>{t('committee.home.currLogin')} <span className="badge badge-primary">{renderRoleName(me)}</span></h3>
                        <h4>{t('committee.home.welcome')}: <span className="badge badge-info">{committee ? committee.name : "n/a"}</span></h4>
                    </div> :
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">{t('loading')}</span>
                        </div>
                    </div>
            }
            {
                me.role === roleCode.Admin ?
                    <div className="text-center">
                        <p>
                            <a className="btn btn-primary btn-lg" href="/committee/display" target="_blank">{t('committee.home.openProjection')}</a>
                        </p>
                    </div> : null
            }
        </div>
    )
}

export default Home
