import React, { useMemo } from 'react'

import { attendStatusCode } from '../../../../constant'
import { renderRoleName } from '../../../modules/util'

import useCommitteeOptions from '../hooks/useCommitteeOptions'
import useCommitteeRollCall from '../hooks/useCommitteeRollCall'
import useCommitteeVote from '../hooks/useCommitteeVote'
import { useTranslation } from 'react-i18next'

function Vote({ CMID, committeeRoles }) {

    const { t } = useTranslation();

    const { committeeOptions, loading: cmLoading } = useCommitteeOptions(CMID);

    const { lastRollCall, loading: cmRollCallLoading } = useCommitteeRollCall(CMID);

    const { currentVote, loading: cmVoteLoading, voteStat } = useCommitteeVote(CMID);

    const getPresentAndVoting = (attendee) => {
        return Object.keys(attendee).filter((k) => {
            const v = attendee[k];
            return v === attendStatusCode.PresentAndVoting;
        })
    }

    const loading = cmVoteLoading || cmRollCallLoading || cmLoading;

    const cmRolesMap = useMemo(() => {
        let rMap = {};
        if (committeeRoles) {
            for (let i = 0; i < committeeRoles.length; i++) {
                const v = committeeRoles[i];
                rMap[v._id] = v;
            }
        }
        return rMap;
    }, [committeeRoles]);

    return (
        <div className="card">
            <div className="card-body text-center">
                {
                    !loading ?
                        <>
                            <h1 className="card-title">{t('committee.display.vote.vote')}</h1>
                            <h2><span className="badge badge-primary">{currentVote ? currentVote.topic : ""}</span></h2>
                            <hr />
                            {
                                currentVote ?
                                    <>
                                        {
                                            committeeOptions.vote && committeeOptions.vote.showVoteResult ?
                                                <>
                                                    <div className="row">
                                                        <div className="col"><h1>{t('committee.display.vote.for')}</h1></div>
                                                        <div className="col"><h1><span className="badge badge-success">
                                                            {voteStat.voteFor}
                                                        </span></h1></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col"><h1>{t('committee.display.vote.against')}</h1></div>
                                                        <div className="col"><h1><span className="badge badge-danger">
                                                            {voteStat.voteAgainst}
                                                        </span></h1></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col"><h1>{t('committee.display.vote.abstain')}</h1></div>
                                                        <div className="col"><h1><span className="badge badge-warning">
                                                            {voteStat.voteAbstain}
                                                        </span></h1></div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <h1 className="display-1"><span className="badge badge-dark rainbow">{t('committee.display.vote.sentences.votingInProcess')}</span></h1>
                                                </>
                                        }
                                        <hr />
                                        {
                                            currentVote.noAbstain ?
                                                <h2>{t('committee.display.vote.sentences.noOneCanAbstain')}</h2> :
                                                lastRollCall ?
                                                    <>
                                                        <h2>{t('committee.display.vote.sentences.cannotVoteAbstain')}</h2>
                                                        {
                                                            getPresentAndVoting(lastRollCall.attendee || {}).map((v, i) => {
                                                                return cmRolesMap[v] ?
                                                                    <span className="badge badge-dark mx-3">< h3 > {renderRoleName(cmRolesMap[v])}</h3></span>
                                                                    : null;

                                                            })
                                                        }
                                                    </> : null
                                        }
                                    </>
                                    :
                                    <>
                                        <h1 className="display-1">{t('committee.display.vote.noOngoingVote')}</h1>
                                    </>
                            }
                        </>
                        :
                        <div className="spinner-grow text-dark">
                            <span className="sr-only">{t('loading')}</span>
                        </div>
                }
            </div>
        </div>
    )
}

export default Vote
