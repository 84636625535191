import { useState, useEffect } from "react"

export default function useAmendmentSortMode() {
  const [sortMode, setSortMode] = useState("time")

  useEffect(() => {
    let mode = localStorage.getItem("sortMode")
    if (!mode) {
      mode = "time"
      localStorage.setItem("sortMode", mode)
    } else {
      setSortMode(mode)
    }
  }, [])

  function handleSetSortMode(value) {
    localStorage.setItem("sortMode", value)
    setSortMode(value)
  }

  return [sortMode, handleSetSortMode]
}
