import React, { useState, useEffect } from 'react'

import useCommitteeVote from './hooks/useCommitteeVote'
import useCommitteeRollCall from './hooks/useCommitteeRollCall'

import { roleCode, attendStatusCode, voteStatusCode } from '../../../constant'

import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact'
import { successNotify, errorNotify, alertNotify } from '../../modules/notification'

import { MUTATION_SUBMIT_VOTE } from '../../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

function VoteModal({ CMID, me }) {

    const { t } = useTranslation()
    const [selected, setSelected] = useState(null);

    const { currentVote, currentVoteId, loading: cmVoteLoaging } = useCommitteeVote(CMID);

    const { lastRollCall, loading: rollCallLoading } = useCommitteeRollCall(CMID);

    const [mutateSubmitVote, { loading: mutateSubmitVoteLoading }] = useMutation(MUTATION_SUBMIT_VOTE, {
        variables: {
            VID: currentVoteId,
            chosenOption: selected
        }
    })

    useEffect(() => {
        if (currentVote && currentVote.result && (currentVote.result[me._id] || currentVote.result[me._id] === 0)) {
            setSelected(currentVote.result[me._id]);
        }
        else if (!currentVote) {
            setSelected(null);
        }
    }, [currentVote, me._id]);

    const loading = cmVoteLoaging || rollCallLoading;

    const attendStatus = lastRollCall && lastRollCall.attendee && lastRollCall.attendee[me._id];

    const voteColor = {
        [voteStatusCode.VoteFor]: "success",
        [voteStatusCode.VoteAgainst]: "danger",
        [voteStatusCode.VoteAbstain]: "warning"
    }

    const getVotedOption = (v) => {
        const key = Object.keys(voteStatusCode).find((k) => voteStatusCode[k] === v);

        if (key) {
            return t(`committee.chairDash.vote.${key.substr(4).toLowerCase()}`)
        }

        return "n/a";
    }
    return (
        <div>
            {
                currentVote && me.role === roleCode.Delegate ?

                    <MDBModal size="lg" isOpen={!!currentVote && currentVote.mode === "Auto"} toggle={() => { }}>
                        <MDBModalHeader toggle={() => { }}>
                            <strong className="text-center"> {t('committee.chairDash.vote.topic')} <span className="badge badge-info">{currentVote.topic}</span></strong>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            {
                                !loading ? <>
                                    {
                                        !currentVote.result || (!currentVote.result[me._id] && currentVote.result[me._id] !== 0) ?
                                            <>
                                                {attendStatus !== attendStatusCode.Absent ?
                                                    <>
                                                        <button className={"btn " + (selected === voteStatusCode.VoteFor ? "btn-success" : "btn-outline-success")} onClick={() => setSelected(voteStatusCode.VoteFor)}>{t('committee.chairDash.vote.for')}</button>
                                                        <button className={"btn " + (selected === voteStatusCode.VoteAgainst ? "btn-danger" : "btn-outline-danger")} onClick={() => setSelected(voteStatusCode.VoteAgainst)}>{t('committee.chairDash.vote.against')}</button>
                                                        {
                                                            attendStatus !== attendStatusCode.PresentAndVoting && !currentVote.noAbstain ?
                                                                <button className={"btn " + (selected === voteStatusCode.VoteAbstain ? "btn-warning" : "btn-outline-warning")} onClick={() => setSelected(voteStatusCode.VoteAbstain)}>{t('committee.chairDash.vote.abstain')}</button>
                                                                : null
                                                        }
                                                    </> :
                                                    <>
                                                        <h1><span className="badge badge-primary rainbow">{t('committee.chairDash.vote.votingInProcess')}</span></h1>
                                                        <h2>You can't vote since you are absent.</h2>
                                                    </>
                                                }
                                            </>
                                            : <>
                                                <h1><span className="badge badge-primary rainbow">{t('committee.chairDash.vote.votingInProcess')}</span></h1>
                                                <h4 className="mb-0 mt-3">
                                                    {t('committee.chairDash.vote.sentences.youHadVoted')}
                                                    <span className={`ml-1 text-${voteColor[currentVote.result[me._id]]}`} style={{ verticalAlign: "" }}>
                                                        {getVotedOption(currentVote.result[me._id])}
                                                    </span>
                                                </h4>
                                            </>
                                    }
                                </> :
                                    <div className="spinner-border text-dark">
                                        <span className="sr-only">{t('loading')}</span>
                                    </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            {
                                (!currentVote.result || (!currentVote.result[me._id] && currentVote.result[me._id] !== 0)) && attendStatus !== attendStatusCode.Absent ?

                                    <button className="btn btn-primary btn-sm" disabled={mutateSubmitVoteLoading} onClick={() => {
                                        if (selected !== null) {
                                            mutateSubmitVote().then(() => {
                                                successNotify(t('notifications.vote.success.voteSubmitted'));
                                                setSelected(null);
                                            }).catch((err) => {
                                                errorNotify(t('notifications.vote.error.whileSubmiitingVote'), JSON.stringify(err));
                                            });
                                        } else {
                                            alertNotify("Chose a option before submit.");
                                        }
                                    }}>{t('committee.chairDash.vote.submit')} </button>
                                    : null
                            }
                        </MDBModalFooter>
                    </MDBModal>

                    : null
            }

        </div>
    )
}

export default VoteModal
