import { alert, error, success, info, notice, defaultModules, defaults, Stack } from '@pnotify/core'
import '@pnotify/core/dist/PNotify.css'
import '@pnotify/core/dist/BrightTheme.css'

import * as PNotifyMobile from '@pnotify/mobile'
import '@pnotify/mobile/dist/PNotifyMobile.css'

defaultModules.set(PNotifyMobile, {});

defaults.delay = 3000;
defaults.stack = new Stack({
    dir1: 'down',
    dir2: 'left',
    firstpos1: 25,
    firstpos2: 25,
    spacing1: 36,
    spacing2: 36,
    push: 'bottom',
    context: document.body,
    maxOpen: 3,
    modal: false
});

const htmlRegex = /(<([^>]+)>)/ig;

// eslint-disable-next-line
function getNonHTMLText(text) {
    return (text || "").replace('<br />', '\n').replace('<br/>', '\n').replace(htmlRegex, '');
}

export function errorNotify(title, text, isHtml) {
    error({
        title: title,
        text: text || "",
        textTrusted: isHtml
    });
}

export function defaultNotify(title, text, isHtml) {
    notice({
        title: title,
        text: text || "",
        textTrusted: isHtml
    });
}

export function successNotify(title, text, isHtml) {
    success({
        title: title,
        text: text || "",
        textTrusted: isHtml
    });
}

export function infoNotify(title, text, isHtml) {
    info({
        title: title,
        text: text || "",
        textTrusted: isHtml
    });
}

export function alertNotify(title, text, isHtml) {
    alert({
        title: title,
        text: text || "",
        textTrusted: isHtml
    });
}