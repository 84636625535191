import { useMemo, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { QUERY_FETCH_COMMITTEE_RESOLUTIONS } from '../../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_RESOLUTION, SUBSCRIPTION_CREATE_RESOLUTION, SUBSCRIPTION_DELETE_RESOLUTION } from '../../../../graphql/subscriptions'

function useCommitteeResolutions(CMID, skipResolutions) {
    const { data, loading, subscribeToMore } =
        useQuery(QUERY_FETCH_COMMITTEE_RESOLUTIONS, {
            variables: {
                CMID
            },
            fetchPolicy: "cache-and-network",
            skip: !CMID || skipResolutions
        });


    useEffect(() => {
        const handleUpdateQuery = (prev, resolution) => {
            let resolutions = prev.committee && prev.committee.resolutions ? [...prev.committee.resolutions] : [];

            let index = resolutions.findIndex((v) => {
                return v._id === resolution._id;
            });

            if (index === -1) {
                resolutions.push(resolution);
            } else {
                resolutions[index] = {
                    ...resolutions[index],
                    ...resolution
                };
            }

            return {
                ...prev,
                committee: {
                    ...prev.committee,
                    resolutions
                }
            };
        }

        const unsubs = [];

        if (CMID && !skipResolutions) {
            const unsubUpdateReso = subscribeToMore({
                document: SUBSCRIPTION_UPDATE_RESOLUTION,
                variables: {
                    CMID
                },
                updateQuery: (prev, { subscriptionData: { data } }) => {
                    if (data && data.resolutionUpdated) {
                        const { resolutionUpdated } = data;

                        return handleUpdateQuery(prev, resolutionUpdated);
                    }
                    return prev;
                }
            });

            const unsubCreateReso = subscribeToMore({
                document: SUBSCRIPTION_CREATE_RESOLUTION,
                variables: {
                    CMID
                },
                updateQuery: (prev, { subscriptionData: { data } }) => {
                    if (data && data.resolutionCreated) {
                        const { resolutionCreated } = data;

                        return handleUpdateQuery(prev, resolutionCreated);
                    }
                    return prev;
                }
            });

            const unsubDeleteReso = subscribeToMore({
                document: SUBSCRIPTION_DELETE_RESOLUTION,
                variables: {
                    CMID
                },
                updateQuery: (prev, { subscriptionData: { data } }) => {
                    if (data && data.resolutionDeleted) {
                        const { resolutionDeleted } = data;

                        let resolutions = prev.committee && prev.committee.resolutions ? [...prev.committee.resolutions] : [];

                        let index = resolutions.findIndex((v) => {
                            return v._id === resolutionDeleted._id;
                        });

                        if (index !== -1) {
                            resolutions.splice(index, 1);
                        }

                        return {
                            ...prev,
                            committee: {
                                ...prev.committee,
                                resolutions
                            }
                        };

                    }
                    return prev;
                }
            });

            unsubs.push(unsubCreateReso);
            unsubs.push(unsubUpdateReso);
            unsubs.push(unsubDeleteReso);
        }

        return () => {
            unsubs.forEach((f) => {
                f();
            })
        }
    }, [CMID, subscribeToMore, skipResolutions]);

    const { committee } = useMemo(() => data || {}, [data]);
    const resolutions = useMemo(() => (committee ? committee.resolutions : []) || [], [committee]);

    const sortedResolution = useMemo(() => {
        let sorted = resolutions || [];
        sorted = sorted.sort((a, b) => {
            if (a.number && b.number) {
                return a.number > b.number ? 1 : (a.number === b.number ? 0 : -1);
            } else if (a.number) {
                return -1;
            } else if (b.number) {
                return 1;
            } else {
                return 0;
            }
        })

        return sorted;
    }, [resolutions]);

    const publicResolutions = useMemo(() => {
        let publicReso = sortedResolution || [];

        publicReso = publicReso.filter((resolution) => {
            return resolution.isPublic === true;
        });

        return publicReso;
    }, [sortedResolution]);

    return { resolutions: sortedResolution, publicResolutions, loading, committee };
}

export default useCommitteeResolutions
