import gql from 'graphql-tag'
import { FRAGMENT_USER_BASIC_INFO } from './fragments'

export const MUTATION_LOGIN = gql`
  mutation loginMutation($email: String!, $password: String!) {
    userLogin(input: {email: $email, password: $password}) {
      token
    }
  }
`

export const MUTATION_ROLE_LOGIN = gql`
  mutation roleLoginMutation($RID: ID!) {
    roleLogin(input: {RID: $RID}) {
      token
    }
  }
`
export const MUTATION_LOGIN_CODE_LOGIN = gql`
  mutation loginCodeLoginMutation($loginCode: String!) {
    loginCodeLogin(input: {loginCode: $loginCode}) {
      token
      userToken
    }
  }
`

export const MUTATION_CREATE_RESOLUTION = gql`
  mutation createResolutionMutation($CMID: ID!, $submitters: [ID]!, $topic: String!, $googleDriveURL: String!, $number: String, $isPublic: Boolean) {    
    createResolution(input: {CMID: $CMID, submitters: $submitters, topic: $topic, googleDriveURL: $googleDriveURL, number: $number, isPublic: $isPublic}) {
      resolution {
        _id
      }
    }
  }
`

export const MUTATION_CREATE_AMENDMENT = gql`
  mutation createAmendmentMutation($RESOID: ID!, $submitter: ID!, $clause: Int!, $subClause: Int!, $subSubClause: Int!, $content: String!, $type: String!) {    
    createAmendment(input: {RESOID: $RESOID, submitter: $submitter, clause: $clause, subClause: $subClause, subSubClause: $subSubClause, content: $content, type: $type}) {
      amendment {
        _id
      }
    }
  }
`

export const MUTATION_CREATE_SECONDARY_AMENDMENT = gql`
  mutation createSecondaryAmendmentMutation($AID: ID!, $submitter: ID!, $content: String!, $type: String!) {    
    createSecondaryAmendment(input: {AID: $AID, submitter: $submitter, content: $content, type: $type}) {
      amendment {
        _id
      }
    }
  }
`

export const MUTATION_CREATE_ROLLCALL = gql`
  mutation createRollCallMutation($CMID: ID!, $attendee: JSON!) {    
    createRollCall(input: {CMID: $CMID, attendee: $attendee}) {
      rollCall {
        _id
        time
        attendee
        committee {
          _id
          name
        } 
      }
    }
  }
`

export const MUTATION_EDIT_ROLLCALL = gql`
  mutation editRollCallMutation($RCID: ID!, $attendee: JSON) {    
    editRollCall(input: {RCID: $RCID, attendee: $attendee}) {
      rollCall {
        _id
        time
        attendee
        committee {
          _id
          name
        }
      }
    }
  }
`

export const MUTATION_SUBMIT_ROLLCALL = gql`
  mutation submitRollCallMutation($RCID: ID!, $attendeeId: ID!, $attendeeStatus: AttendStatusCode!) {    
    submitRollCall(input: {RCID: $RCID, attendeeId: $attendeeId, attendeeStatus: $attendeeStatus}) {
      rollCall {
        _id
        time
        attendee
        committee{
          _id
          name
        } 
      }
    }
  }
`
export const MUTATION_DELETE_ROLLCALL = gql`
  mutation deleteRollCallMutation($RCID: ID!) {    
    deleteRollCall(input: {RCID: $RCID}) {
      rollCall {
        _id
      }
    }
  }
`

export const MUTATION_DELETE_RESOLUTION = gql`
  mutation deleteResolutionMutation($RESOID: ID!) {    
    deleteResolution(input: {RESOID: $RESOID}) {
      resolution {
        _id
      }
    }
  }
`

export const MUTATION_SENT_NOTE = gql`
  mutation sentNoteMutation($content: String!, $sender: ID!, $receiver: ID!) {
    sendNote(input: {content: $content, senderId: $sender, receiverId: $receiver}) {
      note {
        _id
        reviewer {
          role
          _id
          detail
        }
      }
    }
  }
`

export const MUTATION_REVIEW_NOTE = gql`
  mutation reviewNoteMutation($NID: ID!, $reviewer: ID!, $blocked: Boolean!, $blockedReason: String) {
    reviewNote(input: {NID: $NID, reviewerId: $reviewer, blocked: $blocked, blockedReason: $blockedReason}) {
      note {
        blocked
      }
    }
  }
`
export const MUTATION_EDIT_ROLE = gql`
  mutation editRoleMutation($RID: ID!, $role: RoleType, $status: ItemStatusCode, $roleTarget: ID, $detail: JSON) {    
    editRole(input: {RID: $RID,role: $role, status: $status, roleTarget: $roleTarget, detail: $detail}) {
      role {
        role
      }
    }
  }
`

export const MUTATION_UPDATE_PASSWORD = gql`
  mutation updatePasswordMutation($USID: ID!, $password: String!, $token: String){
    updatePassword(input: {USID: $USID, password: $password, token: $token}) {
      user{
        _id
      }
    }
  }
`

export const MUTATION_VERIFY_RESET_PASSWORD_TOKEN = gql`
  mutation verifyResetPasswordToken($token: String!){
    verifyResetPasswordToken(input: {token: $token}){
        user {
            _id
            ...UserBasicInfo
        }
    }
  }
  ${FRAGMENT_USER_BASIC_INFO}
`

export const MUTATION_USER_RESET_PASSWORD = gql`
  mutation userResetPassword($email: String!){
    userResetPassword(input: {email: $email}) {
      user {
        _id
      }
    }
  }
`

export const MUTATION_EDIT_COMMITTEE = gql`
  mutation editCommitteeMutation($CMID: ID!, $name: String, $type: CommitteeType, $CFID: ID) {    
    editCommittee(input: {CMID: $CMID, name: $name, type: $type, CFID: $CFID}) {
      committee {
        name
        options
      }
    }
  }
`

export const MUTATION_UPDATE_COMMITTEE_OPTIONS = gql`
  mutation updateCommitteeOptionsMutation($CMID: ID!, $options: JSON!) {    
    updateCommitteeOptions(input: {CMID: $CMID, options: $options}) {
      committee {
        name
        options
      }
    }
  }
`

export const MUTATION_EDIT_AMENDMENT = gql`
  mutation editAmendmentMutation($AID: ID!, $RESOID: ID, $submitter: ID, $clause: Int, $subClause: Int, $subSubClause: Int, $content: String, $type: String, $status: AmendmentStatusCode, $isPublic: Boolean) {    
    editAmendment(input: {AID: $AID, RESOID: $RESOID, submitter: $submitter, clause: $clause, subClause: $subClause, subSubClause: $subSubClause, content: $content ,type: $type, status: $status, isPublic: $isPublic}) {
      amendment {
        content
      }
    }
  }
`

export const MUTATION_UPDATE_AMENDMENT_STATUS = gql`
  mutation updateAmendmentStatusMutation($AID: ID!, $status: AmendmentStatusCode!) {    
    updateAmendmentStatus(input: {AID: $AID, status: $status}) {
      amendment {
        content
      }
    }
  }
`

export const MUTATION_RETRIEVE_AMENDMENT = gql`
  mutation retrieveAmendment($AID: ID!) {
    retrieveAmendment(input: {AID: $AID}) {
      amendment {
        _id
      }
    }
  }
`

export const MUTATION_REJECT_AMENDMENT = gql`
  mutation rejectAmendment($AID: ID!, $reason: String) {
    rejectAmendment(input: {AID: $AID, reason: $reason}) {
      amendment {
        _id
      }
    }
  }
`

export const MUTATION_EDIT_RESOLUTION = gql`
  mutation editResolutionMutation($RESOID: ID!, $CMID: ID, $submitters: [ID], $topic: String, $googleDriveURL: String, $status: ResolutionStatusCode, $isPublic: Boolean, $number: String) {    
    editResolution(input: {RESOID: $RESOID,CMID: $CMID, submitters: $submitters, topic: $topic, googleDriveURL: $googleDriveURL, status: $status, number: $number, isPublic: $isPublic}) {
      resolution {
        _id
      }
    }
  }
`

export const MUTATION_UPDATE_NOTE_READ = gql`
  mutation updateNoteReadMutation($NID: ID!) {
    updateNoteRead(input: {NID: $NID}) {
      note {
        _id
        readTime
      }
    }
  }
`
export const MUTATION_POST_ANNOUNCEMENT = gql`
  mutation postAnnouncementMutation($target: String!, $targetID: ID!, $content: String!) {
    postAnnouncement(input: {target: $target, targetID: $targetID, content: $content}) {
      announcement {
        _id
        content
        postTime
      }
    }
  }
`

export const MUTATION_DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncementMutation($ANID: ID!) {
    deleteAnnouncement(input: {ANID: $ANID}) {
      announcement {
        _id
      }
    }
  }
`

export const MUTATION_CREATE_VOTE = gql`
  mutation createVoteMutation($CMID: ID!, $startTime: DateTime!, $endTime: DateTime, $result: JSON!, $topic: String!, $mode: VoteMode!, $link: JSON, $noAbstain: Boolean){
    createVote(input: {CMID: $CMID, startTime: $startTime, endTime: $endTime, result: $result, topic: $topic, mode: $mode, link: $link, noAbstain: $noAbstain}) {
      vote {
        _id 
      }
    }
  }
`

export const MUTATION_EDIT_VOTE = gql`
  mutation editVoteMutation($VID: ID!, $CMID: ID, $startTime: DateTime, $endTime: DateTime, $result: JSON, $topic: String, $mode: VoteMode, $link: JSON, $noAbstain: Boolean){
    editVote(input: {VID: $VID, CMID: $CMID, startTime: $startTime, endTime: $endTime, result: $result, topic: $topic, mode: $mode, link: $link, noAbstain: $noAbstain}){
      vote {
        _id
      }
    }
  }
`

export const MUTATION_END_VOTE = gql`
  mutation endVoteMutation($VID: ID!, $endTime: DateTime){
    endVote(input: {VID: $VID, endTime: $endTime}) {
      vote {
        _id
      }
    }
  }
`

export const MUTATION_SUBMIT_VOTE = gql`
  mutation submitVoteMutation($VID: ID!, $chosenOption: Int!){
    submitVote(input: {VID: $VID, chosenOption: $chosenOption}) {
      vote {
        _id
      }
    }
  }
`

export const MUTATION_ADD_TALLY_FIELD = gql`
  mutation addTallyFieldMutation($CMID: ID!, $field: String!){
    addTallyField(input: {CMID: $CMID, field: $field}) {
      committee {
        options
      }
    }
  }
`

export const MUTATION_DELETE_TALLY_FIELD = gql`
  mutation deleteTallyFieldMutation($CMID: ID!, $FID: String!){
    deleteTallyField(input: {CMID: $CMID, FID: $FID}) {
      committee {
        options
      }
    }
  }
`

export const MUTATION_CLEAR_VOTE_RESULT = gql`
  mutation clearVoteResult($VID: ID!){
    clearVoteResult(input: {VID: $VID}) {
      vote {
        _id
      }
    }
  }
`

export const MUTATION_UPDATE_SUBMITTED_VOTE = gql`
  mutation updateSubmittedVote($VID: ID!, $RID: ID!, $chosenOption: Int!){
    updateSubmittedVote(input: {VID: $VID, RID: $RID, chosenOption: $chosenOption}) {
      vote {
        _id
      }
    }
  }
`