import React, { useState, useMemo, Fragment } from 'react'
import * as jwt_decode from 'jwt-decode'
import FlipMove from 'react-flip-move'
import _ from 'lodash'

import { attendStatusCode, roleField } from '../../../constant'
import { renderRoleName, sortCommitteeRoles } from '../../modules/util'
import { errorNotify } from '../../modules/notification'
import './chairdash/loading.css'

import { MUTATION_EDIT_ROLE, MUTATION_ADD_TALLY_FIELD, MUTATION_DELETE_TALLY_FIELD } from '../../../graphql/mutations'
import { useMutation } from '@apollo/react-hooks'

import useCommitteeRoles from './hooks/useCommitteeRoles'
import useCommitteeOptions from './hooks/useCommitteeOptions'
import { useTranslation } from 'react-i18next'
import useCommitteeRollCall from './hooks/useCommitteeRollCall'

const handleFocus = (event) => event.target.select();

const RenderSingleRole = ({ role, tallyFields = [], edit = false, isAbsent = false, edittingTally, setStatChanged = () => { } }) => {
    const { t } = useTranslation();
    const [mutateEditRole, { loading }] = useMutation(MUTATION_EDIT_ROLE, { context: { debounceKey: `TALLY_${role._id}`, debounceTimeout: 500 } });
    const saveDetail = (RID, newDetail) => {
        return mutateEditRole({
            variables: {
                RID,
                detail: newDetail
            }
        }).catch(
            res => {
                if (res.graphQLErrors) {
                    res.graphQLErrors.map(error => errorNotify("Error", error.message))
                } else {
                    errorNotify(t('error'), t('committee.tallySheet.systemUnavailable'))
                }
            }
        );
    }

    const saveTally = (RID, field, value) => {
        const newRoleDetail = role.detail;

        if (newRoleDetail && newRoleDetail.tally) {
            newRoleDetail.tally[field] = value
        }
        else {
            newRoleDetail.tally = {};
            newRoleDetail.tally[field] = value;
        }

        if (newRoleDetail.tally[field] < 0) newRoleDetail.tally[field] = 0;

        saveDetail(RID, newRoleDetail);
        setStatChanged(true)
    }

    const handleTallyOnChange = (field, e) => {
        const value = e.target.value;

        if (!isNaN(value) && value.trim() !== "") {
            saveTally(role._id, field, parseInt(value));
        }
    }

    const handleDelegateNoteOnChange = (e) => {
        const value = e.target.value;
        let newRoleDetail = role.detail;

        if (newRoleDetail)
            newRoleDetail.delegateNote = value;
        else
            newRoleDetail = { delegateNote: value };

        saveDetail(role._id, newRoleDetail);
    }

    if (role && role.detail) {
        let tally = {
            poi: 0,
            amendment: 0,
            speech: 0
        }

        tally = { ...tally, ...(role.detail.tally ? role.detail.tally : {}) }

        return (
            <div className="rounded border p-2" style={{ transition: ".2s", backgroundColor: isAbsent ? "rgb(173 173 173 / 15%)" : "white" }}>
                <form>
                    <div className="row text-center mx-5">
                        <div className="col-4" style={{ alignSelf: "center" }}>
                            <div className="row">
                                <div className="col-auto">
                                    <div className="d-flex">
                                        <h3 className="m-0 mr-1">
                                            {renderRoleName(role)}
                                        </h3>
                                        <h6 className="m-0" title="Absent !" style={{ cursor: "default", alignSelf: "center", opacity: isAbsent ? 1 : 0, pointerEvents: isAbsent ? "auto" : "none" }}>
                                            <span className="badge badge-warning">!</span>
                                        </h6>
                                    </div>
                                </div>
                                <div className="col" style={{ alignSelf: "center" }}>
                                    <input
                                        className="form-control"
                                        value={role.detail.delegateNote || ""}
                                        onChange={handleDelegateNoteOnChange}
                                        placeholder="Delegate Note"
                                    />
                                </div>
                                <div style={{ alignSelf: "center", marginRight: 40 }}>
                                    {
                                        loading ? <div className="spinner-border text-primary">
                                            <span className="sr-only">{t('loading')}</span>
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                        {tallyFields ?
                            Object.values(tallyFields).map((field, i) => {
                                const fieldKey = Object.keys(tallyFields)[i]

                                return (
                                    <div className="col" key={i}>
                                        <div className="input-group mb-1">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-danger" disabled={!edit || edittingTally} type="button" onClick={() => {
                                                    let newValue = tally[fieldKey] || 0;
                                                    newValue += -1;
                                                    saveTally(role._id, fieldKey, newValue);
                                                }}>-</button>
                                            </div>
                                            <input type="number" className="form-control text-center" disabled={!edit || edittingTally} min={0} onFocus={handleFocus} onChange={(e) => handleTallyOnChange(fieldKey, e)} value={tally[fieldKey] || 0} />
                                            <div className="input-group-append">
                                                <button className="btn btn-success" disabled={!edit || edittingTally} type="button" onClick={() => {
                                                    let newValue = tally[fieldKey] || 0;
                                                    newValue += 1;
                                                    saveTally(role._id, fieldKey, newValue);
                                                }}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null
                        }
                        {edittingTally ? (
                            <div className="col">
                                <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-danger" disabled type="button">-</button>
                                    </div>
                                    <input type="number" className="form-control text-center" disabled />
                                    <div className="input-group-append">
                                        <button className="btn btn-success" disabled type="button">+</button>
                                    </div>
                                </div>
                            </div>
                        )
                            :
                            null
                        }
                    </div>
                </form>
            </div>
        )
    }
}

export function TallySheet({ CMID: this_CMID, isChair = false }) {
    const { t } = useTranslation();

    const [statChanged, setStatChanged] = useState(true)
    const [searchText, setSearchText] = useState("");
    const [sorting, setSorting] = useState({
        field: "representative",
        direction: "asc"
    });
    const [edittingTally, setEdittingTally] = useState(false)
    const [addFieldInput, setAddFieldInput] = useState("")

    const { delegates, loading: cmRolesLoading } = useCommitteeRoles(this_CMID);
    const { rollCalls } = useCommitteeRollCall(this_CMID);
    const lastRollCalls = useMemo(() => (rollCalls && rollCalls[0] && rollCalls[0].attendee) || {}, [rollCalls])

    const [addTallyFieldMutation, { loading: addTallyLoading }] = useMutation(MUTATION_ADD_TALLY_FIELD);
    const [deleteTallyFieldMutation, { loading: deleteTallyFieldLoading }] = useMutation(MUTATION_DELETE_TALLY_FIELD);

    const handleAddTallyField = (e) => {
        e.preventDefault();
        let field = addFieldInput.trim()
        if (field.length === 0) return

        return addTallyFieldMutation({
            variables: {
                CMID: this_CMID,
                field
            }
        })
            .then(() => setAddFieldInput(""))
            .catch(
                res => {
                    if (res.graphQLErrors) {
                        res.graphQLErrors.map(error => errorNotify("Error", error.message))
                    } else {
                        errorNotify(t('error'), t('committee.tallySheet.systemUnavailable'))
                    }
                }
            );
    }

    const handleDeleteTallyField = (fieldKey) => {
        return deleteTallyFieldMutation({
            variables: {
                CMID: this_CMID,
                FID: fieldKey
            }
        }).catch(
            res => {
                if (res.graphQLErrors) {
                    res.graphQLErrors.map(error => errorNotify("Error", error.message))
                } else {
                    errorNotify(t('error'), t('committee.tallySheet.systemUnavailable'))
                }
            }
        );
    }

    const renderSheetTitle = ({ name, key, colClass = "col", count = null, isRepresentative = false }) => {
        const isThisSorting = sorting.field === key;

        const sortingDirection = isThisSorting ? sorting.direction : null;

        let h4Style = {};
        if (sortingDirection) {
            h4Style = {
                ...h4Style,
                marginLeft: "1rem"
            }
        }

        return (
            <div className={colClass}>
                <h4 className="mb-0 hover-clickable" style={h4Style} onClick={() => {
                    if (edittingTally) return
                    let newSorting = { ...sorting };
                    if (isThisSorting) {
                        newSorting.direction = sorting.direction === "asc" ? "desc" : "asc";
                    } else {
                        newSorting.field = key
                        newSorting.direction = "asc";
                    }
                    setSorting(newSorting);
                }}>{name + (sortingDirection ? " " : "")}
                    {" "}
                    {edittingTally && !isRepresentative ?
                        <span className="icon-btn hover-clickable" title="Delete Field" onClick={() => handleDeleteTallyField(key)}>
                            <span className="material-icons">delete</span>
                        </span>
                        :
                        (count || count === 0) ? <span className="badge badge-primary">{count}</span> : null}
                    {sortingDirection === "asc" ?
                        <i className="material-icons" hidden={edittingTally ? true : false}>arrow_drop_down</i> :
                        sortingDirection === "desc" ? <i className="material-icons" hidden={edittingTally ? true : false}>arrow_drop_up</i> : null}
                </h4>
            </div>
        )
    }

    const sortedDelegates = useMemo(() => {
        let sortedDelegates = [...delegates];
        if (sorting) {
            const { field, direction } = sorting;

            sortedDelegates = sortedDelegates.sort((a, b) => {
                return sortCommitteeRoles(a, b) * (field === "representative" && direction === "asc" ? 1 : -1);
            })
                .sort((a, b) => {

                    // don't sort when using name to sort
                    if (field === "representative") return 0;

                    let aValue = a.detail && a.detail.tally && a.detail.tally[field] ? a.detail.tally[field] : null;
                    let bValue = b.detail && b.detail.tally && b.detail.tally[field] ? b.detail.tally[field] : null;

                    return (aValue > bValue ? -1 : aValue < bValue ? 1 : 0) * (direction === "asc" ? 1 : -1);
                });
        }

        return sortedDelegates.filter((v) => !searchText || renderRoleName(v).toLowerCase().search(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLowerCase()) === 0);
    }, [sorting, searchText, delegates]);

    const { committeeOptions } = useCommitteeOptions(this_CMID);
    const { tallyFields } = committeeOptions;

    const tallyFieldStat = useMemo(() => {
        const stat = {};

        for (let index = 0; index < delegates.length; index++) {
            const delegate = delegates[index];

            if (delegate && delegate.detail && delegate.detail.tally) {
                Object.keys(delegate.detail.tally).forEach((k) => {
                    stat[k] = (stat[k] || 0) + delegate.detail.tally[k];
                });
            }
        }

        if (statChanged) setStatChanged(false);

        return stat;
    }, [delegates, statChanged]);

    return (
        <div className="container-fluid">
            <div className="row justify-content-between">
                <div className="col col-md-6 col-lg-2">
                    <input className="form-control" type="text" placeholder={t('committee.tallySheet.search')} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </div>
                <div className="mr-3">
                    {
                        addTallyLoading || deleteTallyFieldLoading ?
                            <div className="spinner-border text-primary mr-3">
                                <span className="sr-only">{t('loading')}</span>
                            </div> : null
                    }
                    <button className={"btn btn-sm btn-" + (edittingTally ? "success" : "warning")} title={edittingTally ? "Done" : "Edit Field"} type="button" onClick={() => setEdittingTally(!edittingTally)}>
                        <i className="material-icons">{edittingTally ? "save" : "edit"}</i>
                    </button>
                </div>
            </div>
            <div className="row my-3">
                <div className="col">
                    <div className="rounded border bg-white p-2">
                        <div className="row text-center mx-5">
                            {renderSheetTitle({ name: "representative", key: "representative", colClass: "col-4", isRepresentative: true })}
                            {_.isEmpty(tallyFields) && !edittingTally ? t('committee.tallySheet.sentences.addByTopRightButton') : null}
                            {
                                tallyFields ?
                                    Object.values(tallyFields).map((field, i) => {
                                        const key = Object.keys(tallyFields)[i]
                                        return <Fragment key={i}>
                                            {renderSheetTitle({ name: field.name, key, count: tallyFieldStat[key] || 0 })}
                                        </Fragment>
                                    })
                                    :
                                    null
                            }
                            {edittingTally ? (
                                <Fragment>
                                    <div className="col">
                                        <form onSubmit={handleAddTallyField}>
                                            <div className="input-group">
                                                <input className="mr-2 col-8" placeholder={t('committee.tallySheet.newField')} value={addFieldInput} autoFocus onChange={(e) => setAddFieldInput(e.target.value)} />
                                                <div className="input-group-append">
                                                    <span className="icon-btn hover-clickable" title="Save">
                                                        <i className="material-icons" onClick={handleAddTallyField}>done</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Fragment>
                            )
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{
                height: 'calc(100vh - 300px)',
                overflow: 'auto'
            }}>
                <div className="col">
                    {
                        !cmRolesLoading ? (
                            <FlipMove>
                                {
                                    sortedDelegates.map((role) => {
                                        return (
                                            <div className="row my-3" key={role._id}>
                                                <div className="col">
                                                    <RenderSingleRole
                                                        role={role}
                                                        tallyFields={tallyFields}
                                                        edit={isChair}
                                                        isAbsent={lastRollCalls[role._id] === attendStatusCode.Absent}
                                                        edittingTally={edittingTally}
                                                        setStatChanged={setStatChanged}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </FlipMove>
                        ) :
                            <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">{t('loading')}</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

function TallySheetWrapper() {
    const me = useMemo(() => jwt_decode(localStorage.getItem(roleField)), []);
    const this_CMID = me.roleTarget;

    return <div className="mx-5">
        <TallySheet CMID={this_CMID} isChair={true} />
    </div>
}

export default TallySheetWrapper
