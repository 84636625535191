import { useQuery, useSubscription } from '@apollo/react-hooks'
import { flattenDeep, merge } from 'lodash'
import { useMemo } from 'react'
import { QUERY_FETCH_COMMITTEE_AMENDMENTS } from '../../../../graphql/queries'
import { SUBSCRIPTION_CM_CREATE_AMENDMENT, SUBSCRIPTION_CM_UPDATE_AMENDMENT } from '../../../../graphql/subscriptions'


const useCommitteeAmendments = (CMID, onAmendmentCreated = () => { }) => {
    const { data, loading, refetch, updateQuery } = useQuery(QUERY_FETCH_COMMITTEE_AMENDMENTS, {
        variables: {
            CMID
        },
        fetchPolicy: "cache-and-network",
        skip: !CMID
    });

    const committee = useMemo(() => (data && data.committee) || {}, [data]);
    const cmResos = useMemo(() => (committee && committee.resolutions) || [], [committee]);

    const cmAmendments = useMemo(() => {
        if (cmResos) {
            return flattenDeep(
                cmResos.map((resolution) => {
                    return resolution.amendments.map((amendment) => {
                        return { ...amendment, reso: resolution };
                    })
                })
            );
        }
    }, [cmResos]);

    const cmAmendmentsTargetMap = useMemo(() => {
        if (cmAmendments) {
            return cmAmendments.reduce((acc, amendment) => {
                acc[amendment._id] = amendment.target;
                return acc;
            }, {});
        }
    }, [cmAmendments]);

    const handleUpdateQuery = (prev, amendment, RESOID) => {
        if (prev && prev.committee && prev.committee.resolutions) {
            let resoIndex = prev.committee.resolutions.findIndex(({ _id }) => _id === RESOID);

            let { amendments } = prev.committee.resolutions[resoIndex];

            let index = amendments.findIndex((v) => {
                return v._id === amendment._id;
            });

            if (index === -1) {
                amendments.push(amendment);
            } else {
                amendments[index] = {
                    ...amendments[index],
                    ...amendment
                };
            }

            const nResos = { ...prev.committee.resolutions };

            nResos[resoIndex].amendments = amendments;

            const updatedData = { ...prev }

            merge(updatedData, {
                committee: {
                    resolutions: nResos
                }
            })

            return updatedData;
        }

        return prev;
    }

    useSubscription(SUBSCRIPTION_CM_CREATE_AMENDMENT, {
        variables: {
            CMID
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const { data: { committeeAmendmentCreated } } = subscriptionData;
            onAmendmentCreated(committeeAmendmentCreated);

            const { target } = committeeAmendmentCreated;
            if (target.__typename === 'Resolution') {
                updateQuery((prev) => {
                    // temporary deep clone, use immutability-helper instead
                    return handleUpdateQuery(JSON.parse(JSON.stringify(prev)), committeeAmendmentCreated, committeeAmendmentCreated.target._id);
                });
            } else {
                const reso = cmAmendmentsTargetMap[target._id];
                if (reso.__typename !== 'Resolution') {
                    console.error('useCommitteeAmendments: Invalid target type');
                    return;
                }
                updateQuery((prev) => {
                    // temporary deep clone, use immutability-helper instead
                    return handleUpdateQuery(JSON.parse(JSON.stringify(prev)), committeeAmendmentCreated, reso._id)
                });
            }
        }
    });

    useSubscription(SUBSCRIPTION_CM_UPDATE_AMENDMENT, {
        variables: {
            CMID
        },
        onSubscriptionData: ({ client, subscriptionData }) => {
            const { data: { committeeAmendmentUpdated } } = subscriptionData;
            const { target } = committeeAmendmentUpdated;
            if (target.__typename === 'Resolution') {
                updateQuery((prev) => {
                    // temporary deep clone, use immutability-helper instead
                    return handleUpdateQuery(JSON.parse(JSON.stringify(prev)), committeeAmendmentUpdated, committeeAmendmentUpdated.target._id)
                });
            } else {
                const reso = cmAmendmentsTargetMap[target._id];
                if (reso.__typename !== 'Resolution') {
                    console.error('useCommitteeAmendments: Invalid target type');
                    return;
                }
                updateQuery((prev) => {
                    // temporary deep clone, use immutability-helper instead
                    return handleUpdateQuery(JSON.parse(JSON.stringify(prev)), committeeAmendmentUpdated, reso._id)
                });
            }
        }
    });

    return { cmAmendments, committee, loading, refetch }
}

export default useCommitteeAmendments