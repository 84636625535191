import { roleCode, CommitteeProcedures, itemStatusCode, rightDisplayMode } from '../../constant'

function findKeyWithValue(obj, value) {
    let i = 0;
    const keys = Object.keys(obj);
    for (; i < keys.length; i++) {
        if (obj[keys[i]] === value) return keys[i];
    }
    return null;
}

export const timeTools = {
    msToTimeObj: (t) => {
        let ms, sec, min, hour, day;
        ms = t % 1000;
        t /= 1000;
        t = Math.floor(t);
        sec = t % 60;
        t /= 60;
        t = Math.floor(t);
        min = t % 60;
        t /= 60;
        t = Math.floor(t);
        hour = t % 24;
        t /= 24;
        t = Math.floor(t);
        day = t;
        return { ms, sec, min, hour, day };
    },
    msToStr: (t, displayMs = false) => {
        const { ms = 0, sec, min, hour, day } = timeTools.msToTimeObj(t);
        return "" + (day ? day + " day, " : "") + (hour ? (hour < 10 ? "0" + hour : hour) : "00") + ":" + (min ? (min < 10 ? "0" + min : min) : "00") + ":" + (sec ? (sec < 10 ? "0" + sec : sec) : "00") + (displayMs ? "." + ms : "");
    }
}

export function renderCurrentCmProcedureName(status) {
    return findKeyWithValue(CommitteeProcedures, status);
}

export function renderRightDisplayModeName(status) {
    return findKeyWithValue(rightDisplayMode, status);
}

export function renderRepresentative(representative, forSorting) {
    return representative.name ?
        ((representative.displayName && !forSorting ? representative.displayName : representative.name) + (representative.party ? ` | ${representative.party}` : "")) :
        ((typeof representative) === "string" ? representative : null);
}

export function sortCommitteeRoles(a, b) {
    return renderRoleName(a, true) >= renderRoleName(b, true) ? (renderRoleName(a, true) === renderRoleName(b, true) ? 0 : 1) : -1;
}

export function deleteUnnecessaryRoleKey(roleObjArray) {
    for (var i = 0; i < roleObjArray.length; i++) {
        delete roleObjArray[i].roleTarget;
        delete roleObjArray[i].roleTargetType;
        delete roleObjArray[i].status;
        if (isNaN(roleObjArray[i].detail)) {
            delete roleObjArray[i].detail.loginCode;
            if (isNaN(roleObjArray[i].detail.tally)) {
                delete roleObjArray[i].detail.tally;
            }
        }
    }
    return roleObjArray;
}

export function renameObjectKey(obj, oldKeyName, newKeyName) {
    if (!!obj[oldKeyName]) {
        obj[newKeyName] = obj[oldKeyName];
        delete obj[oldKeyName];
    }
    return obj;
}

export function renderRoleName(role, forSorting = false) {
    if (!role) return "n/a";

    if (role.role === roleCode.Delegate && role.detail && role.detail.representative) {
        return renderRepresentative(role.detail.representative, forSorting) || "n/a";
    } else if (role.role === roleCode.Chair) {
        return (role.detail ? "Chair " + role.detail.name : null) || "Chair";
    } else if (role.role === roleCode.Director && role.detail) {
        return (role.detail.job ? role.detail.job : "Director") + (role.detail.name ? " " + role.detail.name : "");
    } else if (role.role === roleCode.Admin && role.detail) {
        return (role.detail.job ? role.detail.job : "Admin") + (role.detail.name ? " " + role.detail.name : "");
    }
    else {
        return findKeyWithValue(roleCode, role.role);
    }
}

export function getDelegateMeta(representative) {
    if (representative) {
        return {
            countryCode: typeof representative === "string" ? representative : (representative.code || ""),
            logoSrc: typeof representative === "string" ? null : representative.logo
        }
    }

    return {};
}

export function renderItemStatusCode(statusCode) {
    return findKeyWithValue(itemStatusCode, statusCode);
}

export function truncateText(inputString = "", maxLength = 24) {
    if (inputString.length <= maxLength) return inputString;

    let truncated = inputString.slice(0, maxLength);

    let lastIndex = truncated.lastIndexOf(" ");

    truncated = truncated.slice(0, lastIndex > 0 ? lastIndex : truncated.length);

    return truncated + "...";
}

export function renderAmendmentDisplayType(amendment, language) {
    const typeTranslates = {
        "Add": {
            "en": "Add",
            "zh": "新增"
        },
        "Modify": {
            "en": "Modify",
            "zh": "修正"
        },
        "Strike": {
            "en": "Strike",
            "zh": "刪除"
        }
    }

    return typeTranslates[amendment.type] && typeTranslates[amendment.type][language] ? typeTranslates[amendment.type][language] : amendment.type;
}

export function renderAmendmentDisplayText(amendment, { language = "en", type, showDRName = false } = {}) {
    let subClause = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o"];
    let subSubClause = ["i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix", "x", "xi", "xii", "xiii", "xiv", "xv"];

    const isSecondary = amendment.target && amendment.target.__typename === "Amendment";

    const submitterText = renderRoleName(amendment.submitter);
    const typeText = renderAmendmentDisplayType(amendment, language);
    const resoText = !isSecondary && showDRName ? renderResolutionDisplayText(amendment.target) : "";

    let clauseText = "";
    if (isSecondary) {
        clauseText += amendment.target.clause + 1;

        if (subClause[amendment.target.subClause]) {
            clauseText += " - " + subClause[amendment.target.subClause];
        }

        if (subSubClause[amendment.target.subSubClause]) {
            clauseText += " - " + subSubClause[amendment.target.subSubClause];
        }
    } else {
        clauseText += amendment.clause + 1;

        if (subClause[amendment.subClause]) {
            clauseText += " - " + subClause[amendment.subClause];
        }

        if (subSubClause[amendment.subSubClause]) {
            clauseText += " - " + subSubClause[amendment.subSubClause];
        }
    }

    if (type === "display") {
        return `${resoText.length > 0 ? resoText + " " : ""}${isSecondary ? "(2nd) " : ""}${typeText} ${clauseText}`;
    } else {
        return `${isSecondary ? "(2nd) " : ""}${typeText} ${clauseText}: ${submitterText} ${resoText.length > 0 ? "(" + resoText + ")" : ""}`;
    }
}

export function renderResolutionDisplayText(resolution = {}) {
    if (resolution.number) {
        return `${resolution.number}`;
    }
    else {
        return resolution.topic
    }
}