import React, { useState, useEffect } from 'react'

import { useMutation } from '@apollo/react-hooks'

import { MUTATION_UPDATE_COMMITTEE_OPTIONS } from '../../../../graphql/mutations'
import { CommitteeProcedures, rightDisplayMode } from '../../../../constant'
import { useTranslation } from 'react-i18next'

import { MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from 'mdbreact'

import './loading.css'

function DropDownCustomText({ customText = [], onClick = () => { } }) {

    const { t } = useTranslation();

    return (
        <MDBDropdown>
            <MDBDropdownToggle color="info">
                <MDBIcon icon="caret-down" />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
                {
                    customText.map((v) => {
                        return <MDBDropdownItem key={v} onClick={() => onClick(t(v))}>{t(v)}</MDBDropdownItem>
                    })
                }
            </MDBDropdownMenu>
        </MDBDropdown>
    )
}

const titleOptions = [
    "dr",
    "amendment",
    "opening",
    "lobbying",
    "mainSubmitter"
]

export default function LeftControlPanel({ currentVote, lastRollCallUnfinished, committeeOptions, deActiveColor = "blue-grey", CMID }) {

    const { t } = useTranslation();

    const { currentProcedure = CommitteeProcedures.None, rightDisplaySelected = rightDisplayMode.None, displaySettings } = committeeOptions || {};

    const { footBar } = displaySettings || {};
    const { title: footBarTitle, leftTitle: footBarLeftTitle } = footBar || {};

    useEffect(() => {
        setTitle(footBarTitle || "");
    }, [footBarTitle]);

    useEffect(() => {
        setLeftText(footBarLeftTitle || "");
    }, [footBarLeftTitle]);

    const [updateCommittee, { loading }] = useMutation(MUTATION_UPDATE_COMMITTEE_OPTIONS, {
        variables: {
            CMID
        }
    });


    const handleCommitteeProceduresOnClick = (e) => {
        updateCommittee({
            variables: {
                options: {
                    currentProcedure: parseInt(e.target.value)
                }
            }
        })
    }

    const handleRightDisplayOnClick = (e) => {
        updateCommittee({
            variables: {
                options: {
                    rightDisplaySelected: parseInt(e.target.value)
                }
            }
        })
    }

    const [title, setTitle] = useState("");
    const [leftText, setLeftText] = useState("");

    const handleDisplaySettingSubmit = (e) => {
        e.preventDefault();

        updateCommittee({
            variables: {
                options: {
                    displaySettings: {
                        footBar: {
                            title,
                            leftTitle: leftText
                        }
                    }
                }
            }
        })
    }

    const [customTextIsOpen, setCustomTextIsOpen] = useState(false);

    return (
        <div className={"card" + (loading ? " loading" : "")}>
            <div className="card-body">
                <div className="row">
                    <div className="col col-lg-5">
                        <h5 className="card-title">{t('committee.chairDash.procedureSelector.selectProcedure')}</h5>
                        <div className="btn-group-vertical">
                            <button type="button"
                                disabled={lastRollCallUnfinished || currentVote}
                                className={"btn btn-sm btn-" + (currentProcedure === CommitteeProcedures.None ? "success" : deActiveColor)}
                                value={CommitteeProcedures.None} onClick={handleCommitteeProceduresOnClick}
                            > {t('committee.chairDash.procedureSelector.none')} </button>
                            <button type="button"
                                disabled={currentVote}
                                className={"btn btn-sm btn-" + (currentProcedure === CommitteeProcedures.RollCall ? "success" : deActiveColor)}
                                value={CommitteeProcedures.RollCall} onClick={handleCommitteeProceduresOnClick}
                            > {t('committee.chairDash.procedureSelector.rollcall')} </button>

                            <button type="button"
                                disabled={lastRollCallUnfinished || currentVote}
                                className={"btn btn-sm btn-" + (currentProcedure === CommitteeProcedures.SpeakersList ? "success" : deActiveColor)}
                                value={CommitteeProcedures.SpeakersList} onClick={handleCommitteeProceduresOnClick}
                            > {t('committee.chairDash.procedureSelector.speakerList')} </button>

                            <button type="button"
                                disabled={lastRollCallUnfinished}
                                className={"btn btn-sm btn-" + (currentProcedure === CommitteeProcedures.Vote ? "success" : deActiveColor)}
                                value={CommitteeProcedures.Vote} onClick={handleCommitteeProceduresOnClick}
                            > {t('committee.chairDash.procedureSelector.vote')} </button>
                        </div>
                    </div>
                    <div className="col col-lg-7">
                        <h5 className="card-title">{t('committee.chairDash.procedureSelector.rightDisplay')}</h5>
                        <div className="btn-group-vertical">
                            <button type="button"
                                className={"btn btn-sm btn-" + (rightDisplaySelected === rightDisplayMode.None ? "success" : deActiveColor)}
                                value={rightDisplayMode.None} onClick={handleRightDisplayOnClick}
                            >{t('committee.chairDash.procedureSelector.none')}</button>
                            <button type="button"
                                className={"btn btn-sm btn-" + (rightDisplaySelected === rightDisplayMode.ResolutionOrAmendments ? "success" : deActiveColor)}
                                value={rightDisplayMode.ResolutionOrAmendments} onClick={handleRightDisplayOnClick}
                            >{t('committee.chairDash.procedureSelector.resolutionAndAmendment')}</button>
                            <button type="button"
                                className={"btn btn-sm btn-" + (rightDisplaySelected === rightDisplayMode.Timer ? "success" : deActiveColor)}
                                value={rightDisplayMode.Timer} onClick={handleRightDisplayOnClick}
                            >{t('committee.chairDash.procedureSelector.timerClock')}</button>
                            <button type="button"
                                className={"btn btn-sm btn-" + (rightDisplaySelected === rightDisplayMode.CustomText ? "success" : deActiveColor)}
                                value={rightDisplayMode.CustomText} onClick={handleRightDisplayOnClick}
                            >{t('committee.chairDash.procedureSelector.customText')}</button>
                        </div>
                    </div>
                </div>
                <hr />
                <form onSubmit={handleDisplaySettingSubmit}>
                    <MDBCollapse isOpen={customTextIsOpen}>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="mainTitle"> {t('committee.chairDash.displaySetting.footTitle')} </label>
                                    <div className="input-group">
                                        <input type="text" className={"form-control form-control-sm" + (title !== footBarTitle ? " border-danger" : "")} id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        <div className="input-group-append">
                                            <DropDownCustomText customText={titleOptions.map((v) => `committee.chairDash.customTextTemplates.footTitle.${v}`)} onClick={(value) => { setTitle(value) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="mainTitle"> {t('committee.chairDash.displaySetting.footLeftTitle')} </label>
                                    <input type="text" className={"form-control form-control-sm" + (leftText !== footBarLeftTitle ? " border-danger" : "")} id="leftText" value={leftText} onChange={(e) => setLeftText(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </MDBCollapse>
                    <div className="row">
                        <div className="col">
                            {customTextIsOpen ? <div className="d-inline">
                                <button className="btn btn-primary btn-sm" type="submit"> {t('committee.chairDash.displaySetting.save')} </button>
                                <button className="btn btn-warning btn-sm" type="submit" onClick={() => {
                                    setTitle("");
                                    setLeftText("");
                                }}>{t('committee.chairDash.displaySetting.clear')}</button>
                            </div>
                                : null}
                            <a className="btn btn-primary btn-sm" href="/committee/display" target="_blank"> {t('committee.chairDash.displaySetting.openAudienceDisplay')} </a>
                        </div>
                        <div className="col-auto align-self-center text-right pl-0">
                            <span className="material-icons align-middle" style={{ cursor: "pointer" }} onClick={() => setCustomTextIsOpen(!customTextIsOpen)}>{customTextIsOpen ? "expand_less" : "expand_more"}</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
