import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import * as Sentry from '@sentry/react'
import ReactGA from "react-ga4";
import * as jwt_decode from "jwt-decode"

import NavBar from './component/layout/navbar/NavBar'
import routes, { navOnly } from './routes'
import PageRouter from './PageRouter'
import { roleField, tokenField } from './constant'
import { renderRoleName } from './component/modules/util'
import { QUERY_SYSTEM_PING } from './graphql/queries'
import { SUBSCRIPTION_SYSTEM_NOTIFY } from './graphql/subscriptions'
import OnLoadRunner from './component/modules/onLoadRunner'
import useCommitteeParentConference from './component/pages/committee/hooks/useCommitteeParentConference'
import useCommittee from './component/pages/committee/hooks/useCommittee'
import Drift from './component/modules/Drift'

import TimeSyncContext from './component/modules/TimeSyncContext'


function App() {
    useEffect(() => {
        const trackingId = "G-LRM7NZ1NNR";
        ReactGA.initialize(trackingId);
    }, []);

    const _subscribeSystemNotify = subscribeToMore => {
        subscribeToMore({
            document: SUBSCRIPTION_SYSTEM_NOTIFY,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;

                if (subscriptionData.data.systemNotify) {
                    const { content } = subscriptionData.data.systemNotify;
                    if (content === "reload") {
                        window.location.reload();
                    }
                }
            }
        })
    }


    const user = useMemo(() => localStorage.getItem(tokenField) ? jwt_decode(localStorage.getItem(tokenField)) : null, []);
    const me = useMemo(() => localStorage.getItem(roleField) ? jwt_decode(localStorage.getItem(roleField)) : null, [])
    const { conference, loading: confLoading } = useCommitteeParentConference(me && me.roleTarget);
    const { committee, loading: cmLoading } = useCommittee(me && me.roleTarget);

    const isDisplay = window.location.pathname === '/committee/display' || window.location.pathname === '/director/audience';

    if (localStorage.getItem(roleField) !== null) {
        const me = jwt_decode(localStorage.getItem(roleField));

        ReactGA.set({ userId: me._id, role: me.role });
    }

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page View" });


    let userId = user ? user._id : null;
    let attributes = user ? {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`
    } : null;

    if (me) {

        attributes = {
            ...attributes,
            role: `${me.role}`,
            roleId: `${me._id}`
        }

        if (me.role === "Delegate") {
            userId = me._id;
            attributes.name = renderRoleName(me) + "@" + ((conference && conference.name) || "") + "-" + ((committee && committee.name) || "");
        }

        if (me.roleTargetType === "Committee") {
            attributes["Conference"] = (conference && conference.name) || ""
            attributes["Committee"] = (committee && committee.name) || ""
            attributes["CMID"] = me.roleTarget;
        }
    }

    const [timeDiff, setTimeDiff] = useState(0);

    useEffect(() => {
        fetch("https://time.api.munative.com/").then((v) => v.json()).then((v) => {
            setTimeDiff(new Date() - new Date(v.utc_datetime));
        });
    }, []);

    const shouldNavHide = window.location.pathname === '/director' || isDisplay;

    return (
        <BrowserRouter>
            <Query query={QUERY_SYSTEM_PING} fetchPolicy="network-only"
                // pollInterval={10000}
                context={{
                    isPingQuery: true
                }}>
                {
                    ({ subscribeToMore, error, refetch }) => {
                        if (error && error.networkError && error.networkError.statusCode !== 400) {
                            return JSON.stringify(error);
                        }
                        return <OnLoadRunner runOnLoad={() => { _subscribeSystemNotify(subscribeToMore) }} />
                    }
                }
            </Query>
            <TimeSyncContext.Provider value={timeDiff}>
                <div className="App">
                    {/* Hide navbar on display window */}
                    {!shouldNavHide ?
                        <div className="mb-3"><NavBar links={routes} navOnlyLinks={navOnly} /></div>
                        : null}
                    <PageRouter routes={routes} />
                </div>
            </TimeSyncContext.Provider>
            {
                !isDisplay && !cmLoading && !confLoading ?
                    <Drift
                        appId="y9piuidmfr3f"
                        style={{
                            bottom: "80px",
                            right: ".1px",
                        }}
                        userId={userId}
                        attributes={attributes}
                        eventHandlers={[
                            {
                                event: "ready",
                                function: `() => {
                                    drift.api.hideWelcomeMessage()
                                }`,
                            },
                        ]}
                    />
                    : null
            }
        </BrowserRouter>
    );

}

export default Sentry.withProfiler(App);
