import { useEffect } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { nth } from "lodash"

import useCommitteeTimer from "./useCommitteeTimer"
import { QUERY_FETCH_COMMITTEE } from "../../../../graphql/queries"
import { MUTATION_UPDATE_COMMITTEE_OPTIONS } from "../../../../graphql/mutations"
import { SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS } from "../../../../graphql/subscriptions"

function useSpeakersList(CMID) {
    const [updateCommittee, { loading }] = useMutation(
        MUTATION_UPDATE_COMMITTEE_OPTIONS,
        {
            variables: {
                CMID,
            },
            context: {
                debounceKey: `SPEAKERS_LIST_${CMID}`,
                debounceTimeout: 100,
            },
        }
    )

    const nextSpeaker = () => {
        const currentSpeakerIndex = nonNullSpeakersList.findIndex(
            (v) => v === currentSpeakerId
        )

        let nextIndex = null

        if (currentSpeakerIndex < nonNullSpeakersList.length - 1)
            nextIndex = currentSpeakerIndex + 1
        else if (currentSpeakerIndex === -1) nextIndex = 0

        setCurrentSpeaker(
            nextIndex === null ? null : nth(nonNullSpeakersList, nextIndex)
        )
    }

    const prevSpeaker = () => {
        const currentSpeakerIndex = nonNullSpeakersList.findIndex(
            (v) => v === currentSpeakerId
        )

        let nextIndex = null

        if (currentSpeakerIndex > 0) nextIndex = currentSpeakerIndex - 1
        else if (currentSpeakerIndex === -1) nextIndex = currentSpeakerIndex

        setCurrentSpeaker(
            nextIndex === null ? null : nth(nonNullSpeakersList, nextIndex)
        )
    }

    const setCurrentSpeaker = (currentSpeakerId, listName = currentListName || "default") => {
        updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        currentSpeaker: {
                            [listName]: {
                                id: currentSpeakerId,
                            }
                        },
                        yield: "",
                        end: false,
                    },
                },
            },
        }).then(() => {
            timer.setTimeLimitInMS(
                currentSpeakerId && !isNaN(timePerSpeaker) ? timePerSpeaker * 1000 : 0
            )
        })
    }

    const setSpeakerYield = (yieldTarget) => {
        updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        yield: yieldTarget,
                    },
                },
            },
        })
    }

    const reset = () => {
        setCurrentSpeaker(null)
    }

    const start = () => {
        timer.start().then(() => {
            return updateCommittee({
                variables: {
                    options: {
                        speakersList: {
                            end: false,
                        },
                    },
                },
            })
        })
    }

    const end = () => {
        timer.stop().then(() => {
            return updateCommittee({
                variables: {
                    options: {
                        speakersList: {
                            end: true,
                        },
                    },
                },
            })
        })
    }

    const { data, subscribeToMore, loading: cmLoading } = useQuery(
        QUERY_FETCH_COMMITTEE,
        {
            variables: {
                CMID,
            },
            fetchPolicy: "network-only",
        }
    )

    const committee = data ? data.committee : null

    const { options: cmOptions } = committee || {}

    const {
        timePerSpeaker,
        list,
        currentSpeaker,
        currentListName,
        end: currentSpeakerEnd,
        yield: speakerListYield,
        show: showSpeakersList,
    } = (cmOptions && cmOptions.speakersList) || {};

    const currentSpeakerObj = currentSpeaker && currentSpeaker[(currentListName || "default")];
    const currentSpeakerId = currentSpeakerObj && currentSpeakerObj.id;

    const nonNullSpeakersList = (list && list[currentListName || "default"]) || []

    const timer = useCommitteeTimer(CMID)

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_UPDATE_COMMITTEE_OPTIONS,
            variables: { CMID },
            updateQuery: (prev, { subscriptionData: { data } }) => {
                if (data && data.committeeUpdated) {
                    if (data.committeeUpdated !== prev) {
                        return {
                            ...prev,
                            committee: {
                                ...prev.committee,
                                ...data.committeeUpdated,
                            },
                        }
                    }
                }
                return prev
            },
        })
    }, [subscribeToMore, CMID])

    const currentSpeakerStatus = (() => {
        if (nonNullSpeakersList.findIndex((v) => v === currentSpeakerId) === -1) {
            return speakerStatusCodes.Empty
        } else if (currentSpeakerEnd) {
            return speakerStatusCodes.End
        } else if (timer.timerState === 0) {
            return speakerStatusCodes.Waiting
        } else {
            return speakerStatusCodes.Speaking
        }
    })()

    /**
     * Only use this in THIMUN speaker selector
     * @param {Object} speaker Speaker Object
     */
    const setTHIMUNSpeaker = (speakerId) => {
        if (speakerId) {
            updateCommittee({
                variables: {
                    options: {
                        speakersList: {
                            list: {
                                default: [speakerId],
                            },
                        },
                    },
                },
            }).then(() => {
                setCurrentSpeaker(speakerId)
            })
        } else {
            updateCommittee({
                variables: {
                    options: {
                        speakersList: {
                            list: {
                                default: [],
                            },
                        },
                    },
                },
            }).then(() => {
                setCurrentSpeaker(null)
            })
        }
    }

    const setSpeakersListSetting = ({ timePerSpeaker }) => {
        const newSetting = {}

        if (timePerSpeaker !== undefined) newSetting.timePerSpeaker = timePerSpeaker

        return updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        ...newSetting,
                    },
                },
            },
        }).then(() => setCurrentSpeaker(currentSpeakerId))
    }

    const setCurrentList = (listName) => {
        return updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        currentListName: listName,
                    },
                },
            },
        })
    }

    const setSpeakersList = (
        newList,
        listName = currentListName || "default"
    ) => {
        return updateCommittee({
            variables: {
                options: {
                    speakersList: {
                        list: {
                            [listName]: newList,
                        },
                    },
                },
            },
        })
    }

    const fields = {
        loading: loading || timer.loading || cmLoading,
        timePerSpeaker,
        currentSpeakersList: nonNullSpeakersList,
        allSpeakerLists: list,
        currentSpeakerId,
        currentSpeakerEnd,
        currentSpeakerStatus,
        remainTimeHMS: timer.msToHMSTime(timer.remainTime),
        speakerListYield,
        showSpeakersList,
        currentListName: currentListName || "default",
    }

    const functions = {
        setCurrentSpeaker,
        setSpeakerYield,
        nextSpeaker,
        prevSpeaker,
        reset,
        start,
        end,
        setTHIMUNSpeaker,
        setSpeakersListSetting,
        setSpeakersList,
        setCurrentList,
    }

    return { ...fields, ...functions }
}

export default useSpeakersList

export const speakerStatusCodes = {
    Empty: 0,
    Waiting: 1,
    Speaking: 2,
    End: 3,
}
