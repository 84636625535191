import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-apollo'
import { QUERY_FETCH_COMMITTEE_ROLES } from '../../../../graphql/queries'
import { SUBSCRIPTION_COMMITTEE_ROLES_UPDATE } from '../../../../graphql/subscriptions'
import { roleCode } from '../../../../constant'
import { sortCommitteeRoles } from '../../../modules/util'

function useCommitteeRoles(CMID) {
    const { data, loading, subscribeToMore } =
        useQuery(QUERY_FETCH_COMMITTEE_ROLES, {
            variables: {
                CMID
            },
            fetchPolicy: "cache-and-network"
        });

    useEffect(() => {
        subscribeToMore({
            document: SUBSCRIPTION_COMMITTEE_ROLES_UPDATE,
            variables: { CMID },
            updateQuery: (prev, { subscriptionData: { data } }) => {
                if (data && data.committeeRolesUpdated) {
                    const { committeeRolesUpdated } = data;

                    let newCommitteeRoles = prev && prev.committee && prev.committee.roles ? [...prev.committee.roles] : [];
                    const index = newCommitteeRoles.findIndex((e) => e._id === committeeRolesUpdated._id);
                    if (index !== -1) {
                        newCommitteeRoles[index] = committeeRolesUpdated;
                    }

                    return {
                        ...prev,
                        committee: {
                            ...prev.committee,
                            roles: newCommitteeRoles
                        }
                    }
                }

                return prev;
            }
        })
    }, [CMID, subscribeToMore]);

    const { committee } = useMemo(() => ({ ...(data || {}) }), [data]);
    const roles = useMemo(() => (!loading && committee ? committee.roles : []) || [], [loading, committee]);

    const delegates = useMemo(() => roles ? roles
        .filter((v) => v.role === roleCode.Delegate)
        .sort(sortCommitteeRoles) : [], [roles]);
    const admins = useMemo(() => roles ? roles
        .filter((v) => v.role === roleCode.Admin)
        .sort(sortCommitteeRoles) : [], [roles]);
    const chairs = useMemo(() => roles ? roles
        .filter((v) => v.role === roleCode.Chair)
        .sort(sortCommitteeRoles) : [], [roles]);

    return { roles, loading, committee, delegates, admins, chairs };
}

export default useCommitteeRoles
