import { useContext } from 'react'
import TimeSyncContext from '../../../modules/TimeSyncContext'

function useSyncedTime() {
    const timeDiff = useContext(TimeSyncContext);

    return {
        getCurrentTime: () => {
            return new Date(Date.now() - timeDiff);
        }
    };
}

export default useSyncedTime;