import React, { Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { renderRoleName, getDelegateMeta } from "../../../modules/util"
import { munativePic } from "../../../../constant"
import CountryFlags from "../../../layout/countryFlags"

import useCommitteeRoles from "../hooks/useCommitteeRoles"
import useSpeakerList from "../hooks/useSpeakersList"

const SpeakersListCard = ({
    loading,
    currentListName,
    speakersList,
    currentSpeaker,
}) => {

    const renderSpeakersListRole = (role, index) => {
        const representative =
            (role && role.detail && role.detail.representative) || {}

        const { countryCode, logoSrc } = getDelegateMeta(representative)

        return (
            <div className="row">
                <div
                    className="col-12 col-lg-3 col-xl-2 mr-xl-0"
                    style={{ alignSelf: "center" }}
                >
                    <CountryFlags
                        src={logoSrc}
                        country={countryCode}
                        fallback={munativePic}
                        className="rounded float-left"
                        width={40}
                        height={40}
                        alt={renderRoleName(role)}
                    />
                </div>
                <div className="col-auto" style={{ alignSelf: "center" }}>
                    <h5 className="align-middle mb-0">{index + 1}.</h5>
                </div>
                <div className="col" style={{ alignSelf: "center" }}>
                    <h4 className="align-middle mb-0">{renderRoleName(role)}</h4>
                </div>
            </div>
        )
    }

    const currentSpeakerIndex = (speakersList || []).findIndex(
        ({ _id }) => _id === (currentSpeaker && currentSpeaker._id)
    )

    const { t } = useTranslation()

    return (
        <div className="card">
            <div className="card-body">
                <h1 className="card-title">
                    {loading
                        ? t("committee.chairDash.speakerList.speakersList")
                        : currentListName === "default"
                            ? t("committee.chairDash.speakerList.primarySpeakersList")
                            : t("committee.chairDash.speakerList.secondarySpeakersList")}
                    {/* {t("committee.display.speakersList.speakersList")} */}
                </h1>
                <hr />
                <div
                    style={{
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto",
                    }}
                >
                    <ul className="list-group">
                        {speakersList
                            ? speakersList.map((v, i) => {
                                if (i < currentSpeakerIndex) {
                                    return null
                                }
                                return (
                                    <li
                                        className={
                                            "list-group-item " +
                                            (currentSpeaker === v._id ? "active " : "")
                                        }
                                        key={v._id}
                                    >
                                        {renderSpeakersListRole(v, i)}
                                    </li>
                                )
                            })
                            : null}
                    </ul>
                </div>
            </div>
        </div>
    )
}

function SpeakersList({ timerText, CMID }) {
    const {
        currentSpeakersList: rawSpeakersList,
        currentSpeakerId,
        speakerListYield,
        showSpeakersList,
        currentListName,
        loading,
    } = useSpeakerList(CMID)

    const { roles } = useCommitteeRoles(CMID)

    const speakersList = useMemo(() => {
        return rawSpeakersList
            .map((id) => roles.find((v) => v._id === id))
            .filter((v) => !!v)
    }, [rawSpeakersList, roles])

    const { t } = useTranslation()

    const currentSpeakerRole =
        speakersList && speakersList.find(({ _id }) => _id === currentSpeakerId)
    const representative =
        (currentSpeakerRole &&
            currentSpeakerRole.detail &&
            currentSpeakerRole.detail.representative) ||
        {}

    const { countryCode, logoSrc } = getDelegateMeta(representative)

    return (
        <>
            {showSpeakersList ? (
                <div className="col-4 order-1">
                    <SpeakersListCard
                        loading={loading}
                        currentListName={currentListName}
                        speakersList={speakersList}
                        currentSpeaker={currentSpeakerRole}
                    />
                </div>
            ) : null}

            <div className="col">
                <div
                    className="card"
                    style={{
                        height: "calc(100vh - 130px)",
                        backgroundColor: "#e5e4e1",
                    }}
                >
                    <div className="card-body text-center my-3">
                        {currentSpeakerRole ? (
                            <Fragment>
                                <div className="row justify-content-center">
                                    <div className="col text-center">
                                        <CountryFlags
                                            src={logoSrc}
                                            country={countryCode}
                                            fallback={munativePic}
                                            className="rounded"
                                            width={96}
                                            height={96}
                                            alt={renderRoleName(currentSpeakerRole)}
                                        />
                                    </div>
                                </div>
                                <h1 className="display-1">
                                    {renderRoleName(currentSpeakerRole)}
                                </h1>
                                {speakerListYield &&
                                    speakerListYield !== "" ? (
                                        <h1 className="display-4">
                                            <span className="badge badge-info">
                                                {t("committee.display.speakersList.yieldTo")}{" "}
                                                {speakerListYield}
                                            </span>
                                        </h1>
                                    ) : null}
                                <h1 className="display-2 mt-5">{timerText}</h1>
                            </Fragment>
                        ) : (
                                <h1 className="mt-5 display-2">
                                    {t("committee.display.speakersList.sentences.noCurrentSpeaker")}
                                </h1>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpeakersList
