import { useMemo } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import jwt_decode from 'jwt-decode'
import { QUERY_FETCH_ROLE_UNREAD_NOTES } from '../../../../graphql/queries'
import { SUBSCRIPTION_UPDATE_NOTE } from '../../../../graphql/subscriptions'
import { roleField } from '../../../../constant'

const useViewer = () => {
    const me = useMemo(() => {
        if (localStorage.getItem(roleField)) {
            return jwt_decode(localStorage.getItem(roleField));
        }

        return null;
    }, []);

    const { data, loading, refetch } = useQuery(QUERY_FETCH_ROLE_UNREAD_NOTES, {
        fetchPolicy: "cache-and-network"
    });

    useSubscription(SUBSCRIPTION_UPDATE_NOTE, {
        variables: {
            RID: me && me._id
        },
        skip: !me,
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            if (data.noteUpdated) {
                refetch();
            }
        }
    });

    const unreadNotes = (data && data.viewer && data.viewer.unreadNotes) || [];

    return { unreadNotes, loading };
}

export default useViewer